import React, {useEffect, useState} from 'react';
import axios from "axios";
import {baseURL} from "../../global/global";
import {useDispatch, useSelector} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {setAlert} from "../../store/actions/alert";

const ImageSearch = ({addImage,canvas}) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [checkImage, setCheckImage] = useState('');
    const [imgObj, setImgObj] = useState({
       search_type: 'image',
       keyword: '',
       page: 1
    });

    const [loader, setLoader] = useState(false);
    const [image, setImage] = useState({data: []});
    const [nextStatus, setNextStatus] = useState(false);

    const onInputChange = (e) =>{
        setImgObj({...imgObj, keyword: e.target.value, page: 1});

        setImage({data: []});
    }

    const onImageClick = (canvas,url,index) => {
        setCheckImage(index);

        addImage(canvas,url);
    }

    const searchImage = async () => {
        const options = {
            headers: {
                'Authorization': auth.token
            }
        }
        await axios.post(`${baseURL}search-media`,imgObj,options).then(res=>{
            if(res.data.status === true){
                setImage({...image, data: image.data.concat(res.data.data)});
                setLoader(false);

                setImgObj({...imgObj, page: (imgObj.page+1)})

                if(imgObj.page > 1){
                    setNextStatus(true);
                }

            }else{

                if(imgObj.page === 1){
                    dispatch(setAlert(res.data.message, 'danger'));
                }
                setLoader(false);
            }
        })
    }

    const onImageSearch = (e) =>{
        e.preventDefault();
        setLoader(true);

        searchImage();
    }

    useEffect(()=>{
        return () => {
            setImage({data: []});
        }
    },[])

    return (
        <>
            <form method="post" onSubmit={(e)=>onImageSearch(e)} className="search-img">
                <div className="form-group has-search">
                    <input type="text" className="form-control" onChange={(e)=>onInputChange(e)} placeholder="Search" />
                    <span className="fa fa-search form-control-feedback" />
                    <button type="submit" style={{display: "none"}}></button>
                </div>
            </form>
            <div id="scrollableImage" className="mt-2" style={{ overflowX: "hidden", height: "205px" }}>
                <InfiniteScroll
                    dataLength={image.data.length} //This is important field to render the next data
                    next={(e)=>searchImage()}
                    hasMore={true}
                    scrollableTarget="scrollableImage"
                    className="align-loader"
                    loader={
                        nextStatus === true ? <h6 style={{width: "100%"}} className="m-0 text-center"><i style={{fontSize: "40px", color: "#16d489"}} className="fa fa-spinner fa-spin mr-2"/></h6>:
                            ''
                    }
                >
                    <div className="row">
                {
                    image.data.length > 0 ?
                        image.data.map((item,index)=>{
                            return(
                                <div className="col-md-3" key={index} style={{marginBottom: "10px"}}>
                                    <div className="img-part cursor-pointer">
                                        <img crossOrigin="anonymous" width="100%" src={item.thumb !== '' ? item.thumb : item.image} className="img-fluid" onClick={(e)=>onImageClick(canvas,item.image,index)} alt />
                                        {
                                            checkImage === index ? <span className="checked-icon" style={{
                                                color: "rgb(22 212 137)",
                                                top: "20px",
                                                left: "50px",
                                                position: "absolute"
                                            }}><i className="far fa-check-circle"></i></span> : ''
                                        }
                                    </div>
                                </div>
                            )
                        })
                    : <div className="col-md-12">
                            <p className="text-center align-loader">{loader === true ? <h6 style={{width: "100%"}} className="m-0 text-center">
                                <i style={{fontSize: "40px", color: "#16d489"}} className="fa fa-spinner fa-spin mr-2"/></h6> : 'Search image here...'}
                            </p>
                        </div>
                }
                    </div>
                </InfiniteScroll>

            </div>
        </>
    )
}

export default ImageSearch;
import React from 'react'
import Footer from './SubComponent/Footer'
import Myacpcom from './SubComponent/Myacpcom'
import Myapcom from './SubComponent/Myapcom'
import Navbar from './SubComponent/Navbar'
import {Helmet} from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";
import {NavLink} from "react-router-dom";

function Myaccount() {
    return (
        <>
            <Helmet>
                <title>AgencyReel | My Account </title>
            </Helmet>
          <div className="wrapper-box">
           <Navbar/>
              <BannerLinebar/>
              <section className="sec-1">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-6 col-md-4 d-flex align-items-center">
                              <div className="sec-1-heading newline">
                                  <ul className="list-inline m-0 d-flex">
                                      <li><NavLink to="#">My Account</NavLink></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
           <Myapcom/>
           <Myacpcom/>
           </div>
        </>
    )
}

export default Myaccount

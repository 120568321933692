import React from 'react'

function Notialert(props) {
    return (
        <>
             <div className="success-check d-flex">
                  <div className="noti-img">
                    <img src={props.notiimg} alt />
                  </div>
                  <div className="success-head ml-lg-3">
                    <h5>{props.notihead}</h5>
                    <p>{props.notipara}</p>
                  </div>
                </div>
        </>
    )
}

export default Notialert

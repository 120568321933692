import axios from "axios";
import {baseURL} from "../../global/global";



export const addImageContent = (token) => (dispatch) => {

    axios({
        method: 'POST',
        url : `${baseURL}fetch-thumbnails`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization':token }
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'ADD_IMAGES_CONTENT',payload: res.data.data});
        }

    }).catch(error=>{
        console.log(error)
    })

}


export const addVideoContent = (token,intr) => (dispatch) => {

    axios({
        method: 'POST',
        url : `${baseURL}fetch-videos`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization':token }
    }).then(res =>{
        if(res.data.status === true){
            clearInterval(intr);
            dispatch({type: 'ADD_VIDEOS_CONTENT',payload: res.data.data});
        }

    }).catch(error=>{
        console.log(error)
    })

}
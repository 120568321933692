import React, {useState} from 'react'

function Deshbcontent(props) {

    const [show, setShow] = useState(false)

    const loadSrc = () => {
        let frame = document.getElementById('frameSrc');
        console.log(frame.src,'src');
        if(frame.src !== 'https://player.vimeo.com/video/373325714') {
            frame.src = 'https://player.vimeo.com/video/373325714';
        }
    }

    const handlePlayVideo = async () => {
        await loadSrc();
        setShow(true)
    }
    const handleCloseVideo = () => {
        setShow(false);

        let frame = document.getElementById('frameSrc');
        frame.src = '';

    }


    return (
        <>
            <div className="deshborad-banner-content text-white ">
                <h5>{props.name}</h5>
                <h2>{props.subname !=='AGENCYREEL 2.0' ? props.subname : <h2>AGENCYREEL <span style={{color: "#16d489"}}>2.0</span></h2>}</h2>
                <p>{props.para}</p>
                <div className="watch-now mt-4">
                    <a href="#" className="button" onClick={handlePlayVideo}><span><i
                        className="fas fa-play"/></span> {props.btn}</a>
                </div>
            </div>


            <div className="modal fade show" style={{display: show === true ? "block" : "none"}} id="exampleModal"
                 tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={handleCloseVideo}>
                                <span aria-hidden="true" style={{color: '#000'}}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="embed-responsive embed-responsive-1by1">
                                        <iframe id="frameSrc" className="embed-responsive-item"
                                                src="https://player.vimeo.com/video/373325714" allowFullScreen/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Deshbcontent

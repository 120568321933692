const initialState = {
    schedule: [],
}

export default function (state = initialState, action){

    switch (action.type) {
        case 'ADD_SCHEDULE_CONTENT':
            return {
                ...state,
                schedule: action.payload
            }
        default:
            {
                return state;
            }
    }

}
import React from 'react'
import Footer from './SubComponent/Footer'
import Navbar from './SubComponent/Navbar'
import Teamacm from './SubComponent/Teamacm'
import TeamAddc from './SubComponent/TeamAddc'
import TeamMs from './SubComponent/TeamMs'
import {Helmet} from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";

function TeamMember() {
    return (
        <>
            <Helmet>
                <title>AgencyReel | Account Management </title>
            </Helmet>
        <div className="wrapper-box">
          <Navbar/>
            <BannerLinebar/>
          <TeamAddc/>
          {/*<Teamacm/>  */}
          <TeamMs/>
          </div>
        </>
    )
}

export default TeamMember

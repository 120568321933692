import React from 'react'
import Aclients from './SubComponent/Aclients'

import Navbar from './SubComponent/Navbar'
import {Helmet} from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";


function AClinent() {
    return (
        <>
            <Helmet>
                <title>AgencyReel | Clients </title>
            </Helmet>
         <div className="wrapper-box">
          <Navbar/>
             <BannerLinebar/>
          <Aclients/> 
          {/*<Aclientcard/>*/}
          </div>
        </>
    )
}

export default AClinent

import React, {useState} from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import {useDispatch} from "react-redux";
import {gdprAction} from "../../store/actions/authAction";
import Alert from "../alert/alert";
import {useHistory} from "react-router-dom";

function GDPRContent() {

  const dispatch = useDispatch();
  const history = useHistory();

  const [showDelete, setShowDelete] = useState(false);
  const [msgData ,setMsgData] = useState('You will not be able to recover this file');
  const [button , setButton] = useState('Delete Data');
  const [type, setType] = useState('delete');

  const modalData = [
    {
      message : 'Are you sure you want to delete your data? This action is permanent and cannot be reversed.'
    },
    {
      message : 'Are you sure you want to stop processing your data? Doing so will disable your account and in order to reactivate your'
    },
    {
      message : 'AgencyReel stores your private information. If you wish to download these data, you can do that by clicking Download My Data button.'
    }
  ]

  const onConfirm = () => {
    setButton('Please Wait...');

    if(type == 'delete'){
      dispatch(gdprAction('','delete-account',setShowDelete,history))
    }
    else if(type == 'stop'){
      dispatch(gdprAction('','disable-account',setShowDelete,history));
    }
    else if(type == 'download'){
      dispatch(gdprAction('','download-user-data',setShowDelete,history));
    }

  }

  const onCancel = () => {
    setShowDelete(false);
  }

  const onButtonClick = (e) =>{
    setShowDelete(true);

    if(e === 'delete'){
      setMsgData(modalData[0].message);
      setButton('Delete Data');
      setType('delete');
    }
    else if(e === 'stop'){
      setMsgData(modalData[1].message);
      setButton('Stop Processing');
      setType('stop');
    }
    else if(e === 'download'){
      setMsgData(modalData[2].message);
      setButton('Download');
      setType('download');
    }
  }

    return (
        <>
           <section className="myaccount-sec">
            <div className="container">
              <Alert/>
              <div className="row d-flex justify-content-center">
                <div className="col-md-12 col-lg-12">
                  <div className="personal-heading">
                    <h5>Manage Your Account Data</h5>
                  </div>
                </div>
              </div>
              <div className="row mt-2 d-flex justify-content-center">
                <div className="col-md-12 col-lg-12">
                  <div className="personal-boxs ">
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <p>AgencyReel stores your private information like your name, email. It also stores your uploaded graphics such as images, videos and your
                          music files along with TTS ( Text to speech). If you wish to download these data, you can do that "by clicking" download your data.</p>
                        <div className="update">
                          <button onClick={(e)=>onButtonClick('download')} className="button mb-4" style={{backgroundColor: "#007bff"}}>Download My Data</button>
                        </div>
                      </div>
                    </div>
                    <hr className="m-0" />
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <p className="gdpr-para">If you wish to stop your private data from getting processed on AgencyReel you can click the button to do so. Please note that doing this will disable
                          your account and you will not be able to use AgencyReel further. In order to reactivate your account, you would have to contact our support team at <a href="https://support.vineasx.com/" target="_blank">support@vineasx.com.</a> <span>Please Proceed With Caution.</span></p>
                        <div className="update">
                          <button onClick={(e)=>onButtonClick('stop')} className="button mb-4" style={{backgroundColor: "#ff7406db"}}>Stop Processing My Data</button>
                        </div>
                      </div>
                    </div>
                    <hr className="m-0" />
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <p className="gdpr-para">If you wish to delete all your private data, you can do so by clicking this button. Please note that this will permanently delete all the data of yours from our server. This step is not reversible and you cannot use AgencyReel further. You cannot undo this step from support also. So if you wish to re-use AgencyReel again, then you will have to start all over again from scratch.<span> Please Proceed With Caution.</span></p>
                        <div className="update">
                          <button onClick={(e)=>onButtonClick('delete')} className="button" style={{backgroundColor: "#ff0606db"}}>Delete My Data</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <SweetAlert
              warning
              showCancel
              confirmBtnText={button}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="light"
              title="Are you sure?"
              onConfirm={(e)=>onConfirm(e)}
              onCancel={(e)=>onCancel(e)}
              focusCancelBtn
              show={showDelete}
          >
            {msgData}
          </SweetAlert>

        </>
    )
}

export default GDPRContent

import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Navbar from "./Navbar";
import {Link, NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import BannerLinebar from "./BannerLinebar";

const FindClients = () => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [clientCount, setClientCount] = useState(auth.appClients.length);

    useEffect(()=>{
        setClientCount(auth.appClients.length);

    },[auth.appClients]);

    return (
        <>
            <Helmet>
                <title>AgencyReel | Find Clients </title>
            </Helmet>
            <div className="wrapper-box">
                <Navbar/>
                <BannerLinebar/>

                <section className="sec-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-4 d-flex align-items-center">
                                <div className="sec-1-heading newline">
                                    <ul className="list-inline m-0 d-flex">
                                        <li><NavLink to="#">Find Clients</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6  col-md-8  d-flex justify-content-lg-end">
                                <div className="search-add d-flex align-items-center ">
                                    <div className="add d-flex ml-md-2 ml-lg-0 align-items-center">
                                        {
                                            auth.user.client == "0" ?
                                                <Link to="/add-client" className="button"><span><i className="fas fa-plus-circle mr-2" /></span>  Add Client</Link>
                                                : ''
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="sec-1-find">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mt-3 mb-3">
                                <iframe
                                    src="https://resources.vega6.info/business-finder?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MDYxMjUyMjcsImp0aSI6IldZY3gwQW42cnFHcHdhRUluYlNWTWwiLCJpc3MiOiJodHRwczpcL1wvcmVzb3VyY2VzLnZlZ2E2LmluZm9cLyIsIm5iZiI6MTYwNjEyNTIzNywiZGF0YSI6eyJ1c2VyX2lkIjoiMSIsImFwcF91cmwiOiJodHRwczpcL1wvdjJzZXJ2ZXIudmlkZW9yZWVsLmlvXC8ifX0.fN5JpYG7hFfXgprbTLyGbtbBE-T4-8SG25xtn4ckOkGmwevJEANb2UjngHIaUiAVNNmzVIeLotsVRZb9aB9g_Q"
                                    style={{ width: "100%", height: "90vh" }}
                                >
                                    {" "}
                                </iframe>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        </>
    )
}

export default FindClients;
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "./alert";
import {fetchScheduleContent} from "./schedularAction";


export const addStream = (data,setLoader,setAddedStreamId,setOpenCustomSweet) => (dispatch,getState) => {

    axios({
        method: 'POST',
        url : `${baseURL}content-stream`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token }
    }).then(res =>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message,'success'));
            dispatch({type: 'ADD_CURRENT_STREAM',payload: res.data.date});
            setAddedStreamId(res.data.date[0].id)
            dispatch(fetchAllStream(getState().auth.token, setOpenCustomSweet));

        }else{
            dispatch(setAlert(res.data.message,'danger'));
        }
        setLoader(false);

    }).catch(error=>{
        setLoader(false);
    })

}

export const addUpdateStream = (data,setLoader) => (dispatch,getState) => {

    axios({
        method: 'POST',
        url : `${baseURL}content-stream`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token }
    }).then(res =>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message,'success'));
            dispatch({type: 'ADD_CURRENT_STREAM',payload: res.data.date});
            dispatch(fetchStream(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message,'danger'));
        }
        setLoader(false);

    }).catch(error=>{
        setLoader(false);
    })

}


export const uploadStreamFiles = (data) => (dispatch,getState) => {

    axios({
        method: 'POST',
        url : `${baseURL}stream`,
        data: data,
        headers: {'Authorization': getState().auth.token }
    }).then(res =>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message,'success'));
            // dispatch({type: 'ADD_CURRENT_STREAM',payload: res.data.date});
            // dispatch(fetchStream(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message,'danger'));
        }

    }).catch(error=>{
    })

}

export const addStreamText = (data,setStreamLoader,history) => (dispatch,getState) =>{
    axios({
        method: 'POST',
        url : `${baseURL}text-stream`,
        data: data,
        headers: {'Authorization': getState().auth.token }
    }).then(res =>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message,'success'));
            dispatch(fetchUserStream(getState().auth.token));
            history.push('/streams');
        }else{
            dispatch(setAlert(res.data.message,'danger'));
        }
        setStreamLoader(false);

    }).catch(error=>{
    })
}


export const removeCurrentStream = () => (dispatch) =>{
    dispatch({type: 'REMOVE_CURRENT_STREAM'});
}


export const fetchStream = (token) => (dispatch) =>{

   axios({
        method: 'POST',
        url : `${baseURL}fetch-content-stream`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization': token }
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'ADD_STREAM',payload: res.data.data});
        }
    }).catch(error=>{

    })
}

export const fetchAllStream = (token,setOpenCustomSweet) => (dispatch) =>{

    axios({
        method: 'POST',
        url : `${baseURL}fetch-content-stream`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization': token }
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'ADD_STREAM',payload: res.data.data});
            setOpenCustomSweet(true);
        }
    }).catch(error=>{

    })
}

export const fetchUserStream = (token) => (dispatch) =>{
    axios({
        method: 'POST',
        url: `${baseURL}fetch-stream`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization':token}
    }).then(res=>{
        if(res.data.status === true){
            dispatch({type: 'ADD_USER_STREAM',payload: res.data.data});
        }
    }).catch(error=>{

    })
}

export const updateFileSequence = (data) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url: `${baseURL}stream-sequence`,
        data: JSON.stringify({data:data}),
        headers: {'Content-Type': 'application/json','Authorization':getState().auth.token}
    }).then(res=>{
        console.log(res);
    }).catch(error=>{

    })

}

export const updateStreamAction = (data,setLoader,setShowPopup) => (dispatch,getState) =>{
    axios({
        method: 'POST',
        url: `${baseURL}update-stream`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization':getState().auth.token}
    }).then(res=>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message,'success'));
            dispatch(fetchUserStream(getState().auth.token));
            dispatch(fetchScheduleContent(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message,'danger'));
        }
        setLoader(false);
        setShowPopup(false);
    }).catch(error=>{
        setLoader(false);
        setShowPopup(false);
    })
}

export const deleteUserStream = (data,setShowDelete) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url: `${baseURL}delete-stream`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization':getState().auth.token}
    }).then(res=>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message,'success'));
            dispatch(fetchUserStream(getState().auth.token));
            dispatch(fetchScheduleContent(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message,'danger'));
        }
        setShowDelete(false);
    }).catch(error=>{
        setShowDelete(false);
    })

}


export const deleteStream = (data,setShowDelete,setButton) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url: `${baseURL}delete-content-stream`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization':getState().auth.token}
    }).then(res=>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message,'success'));
            dispatch(fetchStream(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message,'danger'));
        }
        setShowDelete(false);
        setButton('Delete');
    }).catch(error=>{
        setShowDelete(false);
        setButton('Delete');
    })

}
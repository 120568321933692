import React from "react";
import Cstream from './SubComponent/Cstream'
import Cstreamfrom from './SubComponent/Cstreamfrom'
import Cstreamtable from './SubComponent/Cstreamtable'
import Navbar from './SubComponent/Navbar'
import {Helmet} from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";

const EditStream = (props) => {

    return (
        <>
            <Helmet>
                <title>AgencyReel | Content Streams </title>
            </Helmet>
          <div className="wrapper-box">
            <Navbar/>
              <BannerLinebar/>
            <Cstream/>
            <Cstreamfrom id={props}/>
            <Cstreamtable id={props}/>
            </div>
        </>
    )

}

export default EditStream;
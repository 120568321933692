import React, {useState} from 'react'
import SweetAlert from "react-bootstrap-sweetalert";
import {useDispatch} from "react-redux";
import {deleteClientAccount} from "../../store/actions/authAction";
import {Link} from "react-router-dom";
import men from '../../images/dummy-img-3.jpg'

function Accard(props) {

    const dispatch = useDispatch();

    const [showDelete, setShowDelete] = useState(false);
    const [button, setButton] = useState('Delete');
    const [clientID,setClientID] = useState({
        client_id: null
    });

    const onButtonClick = (e,id) => {
        e.preventDefault();
        setShowDelete(true);

        setClientID({client_id: id});
    }

    const onConfirm = (e) => {
        setButton('Please Wait...');

        dispatch(deleteClientAccount(clientID,setShowDelete));
    }

    const onCancel = (e) => {
        setShowDelete(false);
    }

    return (
        <>
           <div className="deshborad-box  clientcard-box image">
             <img src={props.profile} alt  className="w-100"  />
              <div className="overlay">
                <div className="icon">
                  <ul className="list-inline iconcenter d-flex">
                    <li className="text-center">
                      <Link to={`edit-client/${props.id}`}>
                        <span>
                            <img src={props.editimg} alt className="img-fluid" />
                        </span>
                        <h6>{props.edithead}</h6>
                      </Link>
                    </li>
                    <li className="text-center">
                      <a onClick={(e)=>onButtonClick(e, props.id)} href="#">
                        <span style={{color:"#fff" , backgroundColor:"#f05359"}} >  
                            {/* <img src={props.del} alt className="img-fluid" /> */}
                            <i class="far fa-trash-alt"></i>
                        </span>
                        <h6>{props.delhead}</h6>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="project-data">
              <ul className="list-inline m-0 d-flex justify-content-between">
                <li title={props.projname} >{props.projname}</li>
                <li title={props.date} >{props.date}</li>
              </ul>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e)=>onConfirm(e)}
                onCancel={(e)=>onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                You Want to Delete this Account
            </SweetAlert>

        </>
    )
}

export default Accard

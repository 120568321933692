import React, {useEffect} from 'react'
import ImageCreatorHead from './SubComponent/ImageCreatorHead'
import ImageEditor from './SubComponent/ImageEditor'
import Navbar from './SubComponent/Navbar'
import {Helmet} from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";

function ImageCreator({location}) {

    return (
        <>
            <Helmet>
                <title>AgencyReel | Image Creator </title>
            </Helmet>
          <div className="wrapper-box">
            <Navbar/>
              <BannerLinebar/>
            <ImageCreatorHead location={location}/>
            <ImageEditor location={location} />
            </div>
        </>
    )
}

export default ImageCreator

import React from 'react'
import Footer from './SubComponent/Footer'
import Navbar from './SubComponent/Navbar'
import StreamCard from './SubComponent/StreamCard'
import StreamHead from './SubComponent/StreamHead'
import {Helmet} from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";

function Stream() {
    return (
        <>
            <Helmet>
                <title>AgencyReel | Streams </title>
            </Helmet>
        <div className="wrapper-box">
           <Navbar/>
            <BannerLinebar/>
           <StreamHead/>
           {/*<StreamCard/>*/}
           </div>
        </>
    )
}

export default Stream

import React, {useEffect, useState} from "react";
import telegramImage from "../../images/telegram_new.png";
import {saveTelegram} from "../../store/actions/socialAccount";
import {useDispatch, useSelector} from "react-redux";
import Alert from "../alert/alert";
import telegramPdf from "../../images/pdf/AgencyReel-Telegram.pdf";
import {feUrl} from "../../global/global";

const TelegramConnect = ({checkAccount,editClient}) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader,setLoader] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [telegramInput, setTelegramInput] = useState({
        user_id: editClient.id,
        chat_id: null,
        bot_token: null
    })

    const connectTelegram = (e) =>{
        setShowPopup(true);
    }

    const closeConnectModel = () => {
        setShowPopup(false);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(saveTelegram(telegramInput,setShowPopup,setLoader));
    }

    const onInputChange = (e) => {
        setTelegramInput({...telegramInput, [e.target.name]: e.target.value});
    }

    useEffect(()=>{
        setTelegramInput({...telegramInput,user_id: editClient.id});
    },[editClient])

    return(
        <>
            <a className={`${checkAccount === false ? 'hide-button' : '' }`} onClick={(e)=>connectTelegram(e)} href="#">
                <img className={`${checkAccount === false ? 'client-img-opacity' : '' }`} src={telegramImage} alt="telegram" />
            </a>

            <Alert/>
            <div className={`modal ${showPopup ? "fade show d-block" : ""}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <form className="modal-content" style={{padding: "15px 20px"}} method="post" onSubmit={(e)=>onFormSubmit(e)}>
                        <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title text-center">Connect Telegram</h5>
                            <button type="button" className="close" onClick={(e)=>closeConnectModel(e)} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="form-group mt-1">
                                    <label htmlFor="exampleInputEmail1">Chat ID</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                           aria-describedby="emailHelp" placeholder="Enter Channel ID" required
                                           name="chat_id" onChange={(e)=> onInputChange(e)}
                                    />
                                </div>
                                <div className="form-group mt-1">
                                    <label htmlFor="exampleInputEmail1">Bot Token</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                           aria-describedby="emailHelp" placeholder="Enter Bot Token" required
                                           name="bot_token" onChange={(e)=> onInputChange(e)}
                                    />
                                </div>
                                <a href={`${feUrl}article-details?id=9&cid=6`} target="_blank">How to get this information ?</a>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-center ">
                            <button type="submit" disabled={loader}>
                                { loader ? <i className="fa fa-spinner fa-spin mr-2"/> : '' } Connect
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </>
    );
}

export default TelegramConnect;
import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Link, useHistory} from "react-router-dom";
import * as queryString from "query-string";

const Training2banner = ({location}) => {

    const history = useHistory();
    const [category, setCategory] = useState([]);
    const [article, setArticle] = useState([]);
    const [loadArt, setLoadArt] = useState(true);

    const categoryId = queryString.parse(location.search).id;


    const onBackPrev = () => {
        history.goBack()
    }

    const fetchCategory = async () => {
        setLoadArt(true);
        await axios({
            method: "POST",
            url: `https://adminserver.reelapps.io/api/fetch-article-category`,
            data: JSON.stringify({app_id: 35}),
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            if (res.data.status === true) {
                setCategory(res.data.data);
            }
            setLoadArt(false);
        }).catch(error => {
            setLoadArt(false);
        })
    }

    const fetchArticle = async () => {
        setLoadArt(true);
        await axios({
            method: "POST",
            url: `https://adminserver.reelapps.io/api/fetch-article`,
            data: JSON.stringify({app_id: 35}),
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            if (res.data.status === true) {
              setArticle(res.data.data);
            }
            setLoadArt(false);
        }).catch(error => {
            setLoadArt(false);
        })
    }

    useEffect(() => {
        fetchCategory();
        fetchArticle();
    }, [])

    return (
        <>
            <section className="sec-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sec-1-heading">
                                <h5 className="m-0">Video Training</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="videoreel-tutorial-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Link className="rv-anchor" to="help-and-support">
                                Support
                            </Link> /
                            {
                                category.length > 0 ?
                                    category.map(item => item.id === categoryId ? <Link className="rv-anchor" to={"training?id=" + item.id}>{item.name}</Link> : '' )
                                    : ''
                            }
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-4 col-md-12">
                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                                 aria-orientation="vertical">
                                <h5>POPULAR TOPICS</h5>
                                {
                                    category.length > 0 ?
                                        category.map(item => <Link to={"training?id=" + item.id}
                                                                      className={`nav-link ${item.id === categoryId ? 'active' : ''}`}>{item.name} </Link>)
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12 custom-training">
                            <div className="tab-content" id="v-pills-tabContent">
                                <div id="v-pills-home">
                                  {
                                    article.length > 0 ?
                                        article.map((item,index)=> {
                                          if(item.category_id === categoryId){
                                            return item.items.map(d => {
                                              return (
                                                  <div key={d.id} className="art-tpc tpc-wrapper w-100 mb-5">
                                                    <div className="img-wrapper mb-3">
                                                      <img className="img-fluid" src={d.feature_url} alt="Thumbnail" />
                                                    </div>
                                                    <div>
                                                      <Link to={"article-details?id="+categoryId+"&cid="+d.id}  style={{textDecoration:"none"}} >
                                                        <h5 style={{color: "#000000"}} className="tpc-title md-txt article-title-hover" >{d.title}</h5>
                                                      </Link>
                                                      <div className="tpc-meta d-flex">
                                                        <p className="tpc-date mr-3 mb-0"><span className="date ml-2">{d.created}</span></p>
                                                      </div>
                                                    </div>
                                                  </div>
                                              )
                                            })
                                          }
                                        })
                                    : ''
                                  }

                                    {
                                        loadArt === true ?
                                            <div className="text-center">
                                                <p style={{fontSize: "40px"}}><i className="fa fa-spinner fa-spin mr-2"/></p>
                                            </div>
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Training2banner

import React, {useEffect, useState} from 'react'
import Footer from './SubComponent/Footer'
import Navbar from './SubComponent/Navbar'
import UpgradesContent from './SubComponent/UpgradesContent'
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import BannerLinebar from "./SubComponent/BannerLinebar";

function Upgrades() {

    const auth = useSelector(state => state.auth);
    const history = useHistory();

    if(auth.user.membership !== null){
        let memberships = auth.user.membership.split("__");
        if(memberships.length === 1){
            history.push('/dashboard');
        }
    }

    return (
        <>
            <Helmet>
                <title>AgencyReel | Upgrades </title>
            </Helmet>
          <div className="wrapper-box">
            <Navbar/>
              <BannerLinebar/>
            <UpgradesContent/>
            </div>
        </>
    )
}

export default Upgrades

import React, {useEffect, useState} from 'react';
import axios from "axios";
import {baseURL} from "../../global/global";
import {useDispatch, useSelector} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {setAlert} from "../../store/actions/alert";

const MemeSearch = ({addImage,canvas}) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const keywordArray = ['funny','kids','cat','animal','new'];
    const [keyword, setKeyword] = useState('cat');

    const [memeObj, setMemeObj] = useState({
        search_type: 'meme',
        keyword: '',
        page: 1
    });

    const [loader, setLoader] = useState(false);
    const [image, setImage] = useState({data: []});

    const onInputChange = (e) =>{
        setMemeObj({...memeObj, keyword: e.target.value, page: 1});

        setImage({data: []});
    }

    const loadMore = async () => {
        await setMemeObj({...memeObj,page: (memeObj.page+1)});
        searchMeme();
    }

    const searchMeme = async () => {
        const options = {
            headers: {
                'Authorization': auth.token
            }
        }
        await axios.post(`${baseURL}search-media`,memeObj,options).then(res=>{
            if(res.data.status === true){
                setImage({...image, data: image.data.concat(res.data.data)});
                setLoader(false);

                setMemeObj({...memeObj, page: (memeObj.page+1)})
            }else{
                if(memeObj.page === 1){
                    dispatch(setAlert(res.data.message, 'danger'));
                }

                setLoader(false);
            }
        })
    }

    const searchImageDefault = async () => {
        const options = {
            headers: {
                'Authorization': auth.token
            }
        }
        await axios.post(`${baseURL}search-media`,JSON.stringify({search_type: 'meme', keyword: keyword, page: 1}),options).then(res=>{
            if(res.data.status === true){
                setImage({...image, data: image.data.concat(res.data.data)});
            }
        })
    }

    const onImageSearch = (e) =>{
        e.preventDefault();
        setLoader(true);

        searchMeme();
    }

    useEffect(()=>{

        searchImageDefault();

        return () => {
            setImage({...image, data: []});
        }
    },[])

    return (
        <>
            <form method="post" onSubmit={(e)=>onImageSearch(e)} className="search-img">
                <div className="form-group has-search">
                    <input type="text" className="form-control" onChange={(e)=>onInputChange(e)} placeholder="Search" />
                    <span className="fa fa-search form-control-feedback" />
                    <button type="submit" style={{display: "none"}}></button>
                </div>
            </form>
            <div id="scrollableMeme" className="mt-2" style={{ overflowX: "hidden", height: "205px" }}>
                <InfiniteScroll
                    dataLength={image.data.length} //This is important field to render the next data
                    next={(e)=>searchMeme()}
                    hasMore={true}
                    scrollableTarget="scrollableMeme"
                >
                    <div className="row">
                        {
                            image.data.length > 0 ?
                                image.data.map((item,index)=>{
                                    return(
                                        <div className="col-md-4" key={index} style={{marginBottom: "10px"}}>
                                            <div className="img-part cursor-pointer">
                                                <img crossOrigin="anonymous" width="100%" src={item.image} className="img-fluid" onClick={(e)=>addImage(canvas,item.image,'meme')} alt />
                                            </div>
                                        </div>
                                    )
                                })
                                : <div className="col-12">
                                    <p className="text-center">{loader === true ? <h6 style={{width: "100%"}} className="m-0 text-center">
                                        <i style={{fontSize: "40px", color: "#16d489"}} className="fa fa-spinner fa-spin mr-2"/></h6> : 'Search meme here...'}
                                    </p>
                                </div>
                        }
                    </div>
                </InfiniteScroll>

            </div>
        </>
    )
}

export default MemeSearch;
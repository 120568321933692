import React from "react";
import yt from "../../images/youtube_new.png";
import GoogleLogin from "react-google-login";
import {useDispatch, useSelector} from "react-redux";
import {saveYoutube} from "../../store/actions/socialAccount";

const Youtube = ({checkAccount,editClient}) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const responseGoogle = (response) => {
        console.log(response);
        if (response.code) {
            const data = {
                user_id: editClient.id !== null ? editClient.id : auth.user.id,
                code: response.code,
            };
            dispatch(saveYoutube(data));
        }
    }

    return(
        <>
            <GoogleLogin
                clientId="385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com"
                render={(renderProps) => (
                    <a className={`${checkAccount === false ? 'hide-button' : '' }`} onClick={renderProps.onClick} href="#">
                        <img className={`${checkAccount === false ? 'client-img-opacity' : '' }`} src={yt} alt="youtube-img" />
                    </a>
                )}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                scope="https://www.googleapis.com/auth/youtube"
                responseType="code"
                accessType="offline"
                prompt="consent"
                cookiePolicy={"single_host_origin"}
            />
        </>
    )

}


export default Youtube;
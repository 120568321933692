import React from 'react'

function Cstream() {
    return (
        <>
            <section className="sec-1">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="sec-1-heading">
                        <h6 className="m-0">Create Content Stream</h6>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Cstream

import React, {useEffect, useState} from 'react'
import youtube from '../../images/youtube.png';
import success from '../../images/success.png';
import trush from '../../images/trush.png';
import error from '../../images/error.png';
import schudle from '../../images/schudle.png';
import {useDispatch, useSelector} from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import {deleteUserStream} from "../../store/actions/streamAction";
import redditImage from "../../images/reddit_new.png";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../store/actions/alert";
import Alert from "../alert/alert";
import {Link} from "react-router-dom";
import vimeoImage from "../../images/vimeo_new.png";
import linkedin from "../../images/linkedin_new.png";
import yt from "../../images/youtube_new.png";
import telegram from "../../images/telegram_new.png";
import fb from "../../images/facebook_new.png";
import twitter from "../../images/twitter_new.png";
import mediumImage from "../../images/medium-logo.png";
import wordpressImage from "../../images/wordpress-logo.png";
import {fetchScheduleContent} from "../../store/actions/schedularAction";



function SDetailsTable(props) {

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const streamData = useSelector(state => state.scheduler);
    const params = props.id.match.params;

    const streamsArray = streamData.schedule.filter(stream => stream.id === params.id);

    const [membership, setMemberships] = useState([]);
    const [repurpose, setRepurpose] = useState(streamsArray[0].re_purpose);
    const [streams, setStreams] = useState(JSON.parse(streamsArray[0].streams));

    const [engData , setEngData] = useState({data: []});
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupSocial, setShowPopupSocial] = useState(false);
    const [engStatus, setEngStatus] = useState(false);

    const [currentSocial, setCurrentSocial] = useState([]);

    const likes = ["facebook","youtube","twitter","vimeo"];
    const shares = ["facebook","vimeo"];
    const comments = ["facebook","youtube","vimeo"];
    const views = ["facebook","youtube"];
    const dislike = ["youtube"];
    const reTweet = ["twitter"];

    /** Sweetalert */
    const [deleteId, setDeleteId] = useState({
        id: null
    });
    const [showDelete, setShowDelete] = useState(false);
    const [button, setButton] = useState('Delete');

    const [contentStream, setContentStream] = useState(streamsArray[0].content_stream);
    const [clientStream, setClientStream] = useState(streamsArray[0].client);

    const openSocialStatus = (data) => {
        setCurrentSocial(data);
        setShowPopupSocial(true);
    }

    const [q, setQ] = useState('');

    const onConfirm = (e) => {
        setButton('Please wait...');

        dispatch(deleteUserStream(deleteId, setShowDelete));
    }

    const onButtonClick = (e,id) => {
        e.preventDefault();
        setShowDelete(true);

        setDeleteId({...deleteId, id: id});
    }

    const fetchEngagement = ( id ) => {
        setEngStatus(true);
        axios({
            method: 'POST',
            url : `${baseURL}fetch-stream-data`,
            data: JSON.stringify({scheduler_id: params.id, stream_id: id}),
            headers: {'Authorization': auth.token}
        }).then(res =>{

            if(res.data.status === true){
                setEngData({...engData, data: res.data.data});
                setShowPopup(true);
            }else{
               dispatch(setAlert(res.data.message,'danger'));
            }
            setEngStatus(false);

        }).catch(error=>{
            console.log(error);
            setEngStatus(false);
        })
    }

    const onSearch = (e) => {
        setQ(e.target.value);
    }

    const search = (users) => {
        return users.filter(
            user => user.title!=='' ? user.title.toLowerCase().indexOf(q.toLowerCase()) > -1 : user
        );
    };

    useEffect(()=>{
        setStreams(streamsArray[0].content_stream.streams);
    },[streamData.schedule]);

    useEffect(()=>{
        if(auth.token !== false) {
            dispatch(fetchScheduleContent(auth.token));
        }
    },[]);

    useEffect(()=>{
        if(auth.user.membership !== null){
            setMemberships(auth.user.membership.split("__"));
        }
    },[])

    return (
        <>
            <Alert/>

            <section className="sec-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-4 d-flex align-items-center">
                            <div className="sec-1-heading newline">
                                <ul className="list-inline m-0 d-flex">
                                    <li><a href="#">Schedule Details</a></li>
                                    <li className="ml-5"><a href="#">Details</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6  col-md-8 d-flex justify-content-end">
                            <div className="search-add d-flex align-items-center ">
                                <div className="search-container mr-lg-3">
                                    <form>
                                        <input type="text" placeholder="Search.." name="search" onChange={(e)=>onSearch(e)} />
                                        <button type="submit"><i className="fa fa-search" /></button>
                                    </form>
                                </div>
                                <div className="add ml-lg-0 ml-md-2 d-flex align-items-center">
                                    <Link to="/scheduler"  className="button"><span><i className="fas fa-plus-circle mr-2" /></span>SCHEDULE</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="schedule-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="details-box">
                                <div className="schedule-details">
                                    <h6><strong>Stream Title: </strong> {contentStream.title}</h6>
                                    <h6><strong>Stream Description: </strong> {contentStream.description}</h6>
                                    <h6><strong>Repurpose: </strong> {repurpose === "1" ? "Yes" : "No"}</h6>
                                    <h6><strong>Accounts: </strong>
                                        <div className="schedule-icon">
                                            <ul className="list-inline m-0 d-flex">
                                                {(() => {
                                                    if (streamsArray[0].accounts.includes('reddit')) {
                                                        return (
                                                            <li><a className="ml-1">
                                                                <img src={redditImage} alt="fb-img" className="img-fluid" />
                                                            </a></li>
                                                        )
                                                    }
                                                })()}
                                                {(() => {
                                                    if (streamsArray[0].accounts.includes('vimeo')) {
                                                        return (
                                                            <li><a className="ml-1">
                                                                <img src={vimeoImage} alt="fb-img" className="img-fluid" />
                                                            </a></li>
                                                        )
                                                    }
                                                })()}
                                                {(() => {
                                                    if (streamsArray[0].accounts.includes('linkedin')) {
                                                        return (
                                                            <li><a className="ml-1">
                                                                <img src={linkedin} alt="fb-img" className="img-fluid" />
                                                            </a></li>
                                                        )
                                                    }
                                                })()}
                                                {(() => {
                                                    if (streamsArray[0].accounts.includes('youtube')) {
                                                        return (
                                                            <li><a className="ml-1">
                                                                <img src={yt} alt="fb-img" className="img-fluid" />
                                                            </a></li>
                                                        )
                                                    }
                                                })()}
                                                {(() => {
                                                    if (streamsArray[0].accounts.includes('telegram')) {
                                                        return (
                                                            <li><a className="ml-1">
                                                                <img src={telegram} alt="fb-img" className="img-fluid" />
                                                            </a></li>
                                                        )
                                                    }
                                                })()}
                                                {(() => {
                                                    if (streamsArray[0].accounts.includes('facebook')) {
                                                        return (
                                                            <li><a className="ml-1">
                                                                <img src={fb} alt="fb-img" className="img-fluid" />
                                                            </a></li>
                                                        )
                                                    }
                                                })()}
                                                {(() => {
                                                    if (streamsArray[0].accounts.includes('twitter')) {
                                                        return (
                                                            <li><a className="ml-1">
                                                                <img src={twitter} alt="fb-img" className="img-fluid" />
                                                            </a></li>
                                                        )
                                                    }
                                                })()}
                                                {(() => {
                                                    if (streamsArray[0].accounts.includes('wordpress')) {
                                                        return (
                                                            <li><a className="ml-1">
                                                                <img src={wordpressImage} alt="fb-img" className="img-fluid" />
                                                            </a></li>
                                                        )
                                                    }
                                                })()}
                                                {(() => {
                                                    if (streamsArray[0].accounts.includes('medium')) {
                                                        return (
                                                            <li><a className="ml-1">
                                                                <img src={mediumImage} alt="fb-img" className="img-fluid" />
                                                            </a></li>
                                                        )
                                                    }
                                                })()}
                                            </ul>
                                        </div>
                                    </h6>
                                    <h6 style={{textTransform: "capitalize"}}>
                                        <strong>Type: </strong>
                                        {streamsArray[0].type} {repurpose === "1" ? "(Automatically rescheduled after the below mentioned are published )" : ""}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


         <section className="schedule-details-sec">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="schedule-table table-hover table-responsive">
                    <table className="table table-striped mt-3">
                      <thead className="theadbgcolor text-white">
                        <tr>
                          <th scope="col">PREVIEW</th>
                          <th scope="col">TITLE</th>
                          <th scope="col">DESCRIPTION</th>
                          <th scope="col">SCHEDULED</th>
                          <th scope="col">STATUS</th>
                          <th scope="col">POST URL</th>
                            {
                                membership.includes('unlimited') ? <th scope="col">ENGAGEMENT</th> : ''
                            }
                          <th scope="col">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        streams.length > 0 ?
                            search(streams).map((stream,index)=>{
                              return(
                                  <>
                                    <tr key={index} className="table-light">
                                      <th scope="row">
                                        <div className="d-flex align-items-center">
                                          <div className="youtube-name text-center ">
                                            <img src={stream.thumbnails !== null ? stream.thumbnails : youtube} alt className="img-fluid" />
                                          </div>
                                        </div>
                                      </th>
                                      <td><h6 title={stream.title !== '' ? stream.title : contentStream.title} className="strem-title">
                                          {stream.title !== '' ? stream.title : contentStream.title}
                                      </h6></td>
                                      <td><h6 title={stream.description !== '' ? stream.description : contentStream.description} className="strem-description">
                                          {stream.description !== '' ? stream.description : contentStream.description}
                                      </h6></td>
                                      <td>{stream.scheduled}</td>
                                      <td>
                                        <div className="d-flex align-items-center ">
                                          <div className="success-icon">
                                              {(() => {
                                                  if (stream.done === '0') {
                                                      return (
                                                          <p style={{color: "orange"}} className="m-0" >Scheduled</p>
                                                      )
                                                  }else if(stream.done === 1){
                                                      return (
                                                          <p style={{color: "green"}} className="m-0" >Posted</p>
                                                      )
                                                  }
                                                  else if (stream.done === '2') {
                                                      return (
                                                          <p style={{color: "red"}} className="m-0" >Failed</p>
                                                      )
                                                  }
                                              })()}
                                          </div>
                                        </div>
                                      </td>
                                      <td>

                                          {
                                              stream.social_networks != null ?
                                                  stream.social_networks.length > 0 ?
                                                      <i title="view post url" style={{cursor: "pointer"}} className="fas fa-eye" onClick={(e)=>openSocialStatus(stream.social_networks)} ></i>
                                                      : ''
                                                  : ''
                                          }
                                      </td>
                                        {
                                            membership.includes('unlimited') ?
                                                <td>

                                                    {
                                                        stream.social_networks != null ?
                                                            stream.social_networks.length > 0 ?
                                                                <i title="view engagement" style={{cursor: "pointer"}} className="fas fa-eye" onClick={(e)=>fetchEngagement(stream.id)}></i>
                                                                : ''
                                                            : ''
                                                    }
                                                </td>
                                            : ''
                                        }
                                      <td>
                                        <div className="schedule-tresh">
                                          <a onClick={(e)=>onButtonClick(e,stream.id)} href="#"><img src={trush} alt /></a>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                              )
                            })
                        : ''
                      }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>

            <div className={`modal ${showPopup ? "fade show d-block" : ""}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{padding: "15px 20px"}}>
                        <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title text-center">Engagement</h5>
                            <button type="button" className="close" onClick={(e)=>setShowPopup(false)} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="schedule-table table-hover table-responsive">
                                <table className="table table-striped mt-3">
                                    <thead className="theadbgcolor text-white" style={{background: "#327ac2"}}>
                                    <tr>
                                        <th scope="col">Account</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Likes</th>
                                        <th scope="col">Views</th>
                                        <th scope="col">Shares</th>
                                        <th scope="col">Comments</th>
                                        <th scope="col">Retweet</th>
                                        <th scope="col">Engagement Points</th>
                                    </tr>
                                    </thead>
                                    <tbody style={{background: "#ffffff"}}>
                                    {
                                        engData.data.length > 0 ?
                                            engData.data.map((item, index)=>{
                                                return(
                                                    <tr>
                                                        <td>{item.type}</td>
                                                        <td>{item.social_account_name}</td>
                                                        <td>
                                                            {
                                                                likes.includes(item.type) ? item.likes : 'N/A'
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                views.includes(item.type) ? item.views : 'N/A'
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                shares.includes(item.type) ? item.shares : 'N/A'
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                comments.includes(item.type) ? item.comments : 'N/A'
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                reTweet.includes(item.type) ? item.shares : 'N/A'
                                                            }
                                                        </td>
                                                        <td>
                                                            { (parseInt(item.likes)*2) + (parseInt(item.comments)*3) + (parseInt(item.views))  + (parseInt(item.shares)*5) }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        : ''
                                    }
                                    {
                                        engStatus === true ? <i style={{fontSize: "40px", color: "#16d489"}} className="fa fa-spinner fa-spin mr-2"/>: ''
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-center ">
                    </div>
                </div>
            </div>


            <div className={`modal ${showPopupSocial ? "fade show d-block" : ""}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{padding: "15px 20px"}}>
                        <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title text-center">Social Accounts</h5>
                            <button type="button" className="close" onClick={(e)=>setShowPopupSocial(false)} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="schedule-table table-hover table-responsive">
                                <table className="table table-striped mt-3">
                                    <thead className="theadbgcolor text-white" style={{background: "#327ac2"}}>
                                    <tr>
                                        <th scope="col">Account</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Post URL</th>
                                        <th scope="col">Error</th>
                                    </tr>
                                    </thead>
                                    <tbody style={{background: "#ffffff"}}>
                                    {
                                        currentSocial.length > 0 ?
                                            currentSocial.map((item,index)=>{
                                                return(
                                                    <tr>
                                                        <td>{item.type}</td>
                                                        <td>
                                                            {
                                                                item.status === true ? <span className="social-success">Posted</span> : <span className="social-failed">Failed</span>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                item.post_url !== "" ? <a target="_blank" href={item.post_url}>Visit</a> : "N/A"
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                item.status !== true ? <h6 title={item.message} className="pop-up-overflow">{item.message}</h6> : "N/A"
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        : ''
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-center ">
                    </div>
                </div>
            </div>


            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e)=>onConfirm(e)}
                onCancel={(e)=>setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete this Scheduler
            </SweetAlert>
   
        </>
    )
}

export default SDetailsTable

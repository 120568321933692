import React, {useEffect, useState} from 'react'
import ticon2 from '../../images/ticon2.png';
import {Link, NavLink} from "react-router-dom";
import axios from "axios";
import trainingbg from "../../images/trainingbg.png";


function TrainingImp() {

    const [loadCategory, setLocalCategory] = useState(false);
    const [category, setCategory] = useState([]);
    const [q, setQ] = useState('');

    const fetchCategory = async () => {
        setLocalCategory(true);
        await axios({
            method: "POST",
            url: `https://adminserver.reelapps.io/api/fetch-article`,
            data: JSON.stringify({app_id: 35}),
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            if (res.data.status === true) {
              setCategory(res.data.data);
            }
          setLocalCategory(false);

        }).catch(error => {
          setLocalCategory(false);
        })
    }

    const onSearch = (e) => {
        setQ(e.target.value);
    }

    const search = (users) => {
        return users.filter(
            user => user.tags.toLowerCase().indexOf(q.toLowerCase()) > -1
        );
    };

    useEffect(() => {
      fetchCategory();
    }, [])


    return (
        <>
            <section className="sec-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-4 d-flex align-items-center">
                            <div className="sec-1-heading newline">
                                <ul className="list-inline m-0 d-flex">
                                    <li><NavLink to="#">Help and Support</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="training-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-center">
                            <div className="training-content">
                                <h5>What do you want to know ?</h5>
                                <div className="input-group mt-3">
                                    <input type="text" className="form-control" onChange={(e)=>onSearch(e)} placeholder="Search" aria-label="Username" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-search" /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="training-img">
                                <img src={trainingbg} alt className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="training-important-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-10 mx-auto">
                            <div className="training-important-head text-center">
                                <h4>Important Topics</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                      {
                        category.length > 0 ?
                          search(category).map((item,index)=>{
                            return(
                                <div key={index} className="col-lg-3 d-flex justify-content-center text-center flex-column align-items-center col-md-3">
                                  <Link to={`/training?id=${item.id}`} className="training-box d-flex justify-content-center align-items-center">
                                    <img src={item.logo} alt className="img-fluid"/>
                                  </Link>
                                  <h6 className="mt-4">{item.name}</h6>
                                </div>
                            )
                          })
                        : ''
                      }
                      {
                        loadCategory === true ?
                            <div className="text-center">
                              <p><i className="fa fa-spinner fa-spin mr-2"/> Loading...</p>
                            </div>
                            : ''
                      }
                    </div>

                </div>
            </section>

        </>
    )
}

export default TrainingImp

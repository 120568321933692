import React, {useState} from "react";
import linkdin from "../../images/linkedin_new.png";
import {useDispatch, useSelector} from "react-redux";
import {baseURL} from "../../global/global";
import randomstring from "randomstring";
import Alert from "../alert/alert";
import {checkNetworkStatus} from "../../store/actions/socialAccount";

const LinkedInConnect = ({checkAccount,editClient}) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [checkStatus, setCheckStatus] = useState(true);
    const [randomString, setRandomString] = useState(randomstring.generate());

    const connectLinkedIn = (e) => {
        let url = `${baseURL}connect-linkedin?token=${auth.token}&key=${randomString}&user_id=${editClient.id !== null ? editClient.id : auth.user.id}`;
        let myWindow = window.open(url,'mywindow','width=500,height=600');

        let interval = setInterval(() => {
            if (checkStatus) {
                dispatch(checkNetworkStatus(randomString,setCheckStatus,interval,myWindow));
                console.log("waiting for the next call.");
            }

        }, 5000);
    }


    return(
      <>
          <Alert/>
          <a className={`${checkAccount === false ? 'hide-button' : '' }`} onClick={(e)=>connectLinkedIn(e)} href="#">
              <img className={`${checkAccount === false ? 'client-img-opacity' : '' }`} src={linkdin} alt="linkedin-img" />
          </a>
      </>
    );

}

export default LinkedInConnect;
import React, {useState} from "react";
import twitter from "../../images/twitter_new.png";
import TwitterLogin from "react-twitter-login";
import {useDispatch, useSelector} from "react-redux";
import randomstring from "randomstring";
import {baseURL} from "../../global/global";
import {checkNetworkStatus} from "../../store/actions/socialAccount";

const TwitterConnect = ({checkAccount,editClient}) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [checkStatus, setCheckStatus] = useState(true);
    const [randomString, setRandomString] = useState(randomstring.generate());

    const connectTwitter = (e) => {
        let url =  `${baseURL}connect-twitter?token=${auth.token}&key=${randomString}&user_id=${editClient.id !== null ? editClient.id : auth.user.id}`;

        let myWindow = window.open(url,'mywindow','width=500,height=600');

        let interval = setInterval(() => {
            if (checkStatus) {
                dispatch(checkNetworkStatus(randomString,setCheckStatus,interval,myWindow));
                console.log("waiting for the next call.");
            }

        }, 5000);
    }

    return (
        <>
            <a href="#"  className={`${checkAccount === false ? 'hide-button' : '' }`} onClick={(e)=>connectTwitter(e)}>
                <img className={`${checkAccount === false ? 'client-img-opacity' : '' }`} src={twitter} alt="twitter-img" />
            </a>
        </>
    )
}


export default TwitterConnect;
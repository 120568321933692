import React from 'react'
import queryString from "query-string";

function ImageCreatorHead({location}) {
    return (
        <>
            <section className="sec-1">
               <div className="container">
                    <div className="row">
                       <div className="col-md-12">
                         <div className="sec-1-heading">
                          <h5 className="m-0">
                              {queryString.parse(location.search).id !== undefined ? 'Edit' : 'Create'} Image / Meme
                          </h5>
                       </div>
                   </div>
                </div>
            </div>
          </section>

        </>
    )
}

export default ImageCreatorHead

import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "./alert";

export const addSchedulerContent = (data,history,setShowPopup,setLoader) => (dispatch,getState) => {

    axios({
        method: 'POST',
        url : `${baseURL}save-scheduler`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token }
    }).then(res =>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch(fetchScheduleContent(getState().auth.token));

            history.push('schedule-content');
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setShowPopup(false);
        setLoader(false);
    }).catch(error => {
        setShowPopup(false);
        setLoader(false);
    })
}


export const fetchScheduleContent = (token) => (dispatch) => {

    axios({
        method: 'POST',
        url : `${baseURL}fetch-schedulers`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization': token }

    }).then(res=>{
        if(res.data.status === true){
            dispatch({type: 'ADD_SCHEDULE_CONTENT', payload: res.data.data});
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
    }).catch(error=>{

    })
}

export const deleteScheduler = (data,setShowDelete,setButton) => (dispatch,getState) =>{
    axios({
        method: 'POST',
        url : `${baseURL}delete-scheduler`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token }

    }).then(res=>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch(fetchScheduleContent(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setShowDelete(false);
        setButton('Delete');
    }).catch(error=>{
        setShowDelete(false);
        setButton('Delete');
    })
}


export const updateSchedulerTime = (data,setShowPopup, setLoader) => (dispatch,getState) =>{
    axios({
        method: 'POST',
        url : `${baseURL}save-scheduler`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token }

    }).then(res=>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch(fetchScheduleContent(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setShowPopup(false);
        setLoader(false);
    }).catch(error=>{
        setShowPopup(false);
        setLoader(false);
    })
}
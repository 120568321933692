import React, {useEffect, useState} from 'react';
import redditImage from "../../images/reddit_new.png";
import ReactTooltip from "react-tooltip";

const SocialAccountType = ({engData,type,image,social,onInputSocial,scheduleStream}) =>{

    let title = "";
    if(type === 'reddit'){
        title = "Please note- You may have limit of 1 post per 15min on Reddit if you're new to Reddit or if you've previously been downvoted. It can also be so if you have a habit of posting and then deleting posts.";
    }
    else if(type === 'twitter'){
        title = "Please note- If you are scheduling a video to post on Twitter, please make sure your video is not longer than 30 sec.";
    }

    let engTitle;

    const[engD , setEng] = useState(engData.data.find(item => item.type === type))

    useEffect(()=> {
        console.log(engData.data,type, 'eng')

        setEng(engData.data.find(item => item.type === type))
        console.log(engD);

    },[engData.data])


    return(
        <>
            <div className={`icon-pop text-center`} style={{position: "relative"}} >
                <div className="form-check" data-iscapture="true"  data-tip={title}  data-for="mainTooltip">
                    <input className="form-check-input" type="checkbox" name="social_accounts"
                           id="exampleRadios1" value={social.id}
                           onChange={(e)=>onInputSocial(e)} />
                    <label className="form-check-label" htmlFor="exampleRadios1">
                    </label>
                </div>
                <span>
                    <img data-iscapture="true" data-tip={title}  data-for="mainTooltip" src={image} alt="fb-img" className="img-fluid" style={{borderRadius: "50%"}} />
                    <span className={`check-span ${scheduleStream.social_accounts.includes(social.id) ? 'show-span' : ''}`}><i className="fas fa-check"></i></span>
                </span>
                <h6 title={social.username} >{social.username !== null ? social.username : social.type}</h6>
                <span
                    data-tip={engD !== undefined ? engD.date !== null ? 'Best Engagement Time : '+engD.date.split(" ")[1] : 'Do not have enough data to calculate Best Engagement Time' : 'Do not have enough data to calculate Best Engagement Time'}
                    data-for="socialTool"><i className="icon-bulb fa fa-lightbulb-o"></i></span>
            </div>
            {
                type === 'reddit' ?
                    <ReactTooltip
                        id="mainTooltip"
                        place="top"
                        multiline={true}
                        width="100px"
                    />
                : ''
            }
            {
                type === 'twitter' ?
                    <ReactTooltip
                        id="mainTooltip"
                        place="top"
                        multiline={true}
                        width="100px"
                    />
                    : ''
            }
            <ReactTooltip
                id="socialTool"
                place="top"
                multiline={true}
                width="100px"
            />
        </>
    )
}

export default SocialAccountType;
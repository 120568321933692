import React, {useCallback, useEffect, useState} from 'react'
import undo from '../../images/undo.png';
import redo from '../../images/redo.png';
import laryer from '../../images/laryer.png';
import downarrow from '../../images/downarrow.png';
import uparrow from '../../images/uparrow.png';
import icon from '../../images/icon.png';
import downloaded from '../../images/downloaded.png';
import save from '../../images/save.png';
import star from '../../images/star.png';
import images from '../../images/images.png';
import text from '../../images/text.png';
import cinema from '../../images/cinema.png';
import speach from '../../images/speach.png';
import borders from '../../images/borders.png';
import ReactTooltip from "react-tooltip";

import { fabric } from 'fabric';
import ImageSearch from "./ImageSearch";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {baseURL} from "../../global/global";
import TextEditor from "./TextEditor";
import emojiData from '../../global/emoji.json';
import iconData from '../../global/icon.json';
import parse from "html-react-parser";
import {setAlert} from "../../store/actions/alert";
import {useHistory} from "react-router-dom";
import queryString from "query-string";
import randomstring from "randomstring";
import {SketchPicker} from "react-color";
import swal from "sweetalert";
import MemeSearch from "./MemeSearch";
import QuoteImage from "./QuoteImage";


function ImageEditor({location}) {

  const initCanvas = () => (
      new fabric.Canvas('canvas', {
        preserveObjectStacking: true,
        height: 394,
        width: 700,
        backgroundColor: '#ffffff'
      })
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector(state => state.auth);
  const contentsData = useSelector(state => state.content);

  const [canvasLoader, setCanvasLoader] = useState(false);
  const [canvas, setCanvas] = useState(null);
  const [campData, setCampData] = useState({
    name: 'agencyreel_'+randomstring.generate(7),
    dimensions: ''
  });
  const [editId, setEditId] = useState('');

  const [canvasJson, setCanvasJson] = useState({
    data: []
  });
  const [textClick, setTextClick] = useState(false);
  const [uploadedImg, setUploadedImg] = useState({
    files: []
  });
  const [loadUploadImg, setLoadUploadImg] = useState(false);

  const [canvasHistory, setCanvasHistory] = useState([canvas]);
  const [canvasState, setCanvasState] = useState(canvas);

  const [emoji, setEmoji] = useState(emojiData);
  const [iconImg, setIconImg] = useState(iconData);
  const [emojiQ, setEmojiQ] = useState('');
  const [iconQ, setIconQ] = useState('');


  /*color picker*/
  const [showCanvasColor,setShowCanvasColor] = useState(false);
  const [canvasColor, setCanvasColor] = useState('');

  const [showShapeColor, setShowShapeColor] = useState(false);
  const [shapeHex, setShapeHex] = useState('');

  const [showBorderColor, setShowBorderColor] = useState(false);
  const [borderHex, setBorderHex] = useState('');

  const onObjectModified = useCallback(
      e => {
        const newCanvasState = e.target.canvas.toJSON();
        setCanvasState(newCanvasState);
        // Limit history depth
        setCanvasHistory(history => [...history, newCanvasState]);
      },

      [setCanvasState, setCanvasHistory]
  );


  const inputCampName = (e) => {
    setCampData({...campData, [e.target.name]: e.target.value })
  }

  /** Save Canvas JSON Data */
  const saveJSon = () => {
    let newCanvasState = JSON.stringify(canvas);
    setCanvasState(newCanvasState);
    // Limit history depth
    setCanvasHistory(history => [...history, newCanvasState]);
  }

  /** Initialize Canvas */


  const initCanvasStyle = () => {
    fabric.Object.prototype.set({
      borderColor: '#3e99f3',
      cornerColor: '#16d489',
      cornerSize: 10,
      cornerStyle: 'circle',
      transparentCorners: false,
      padding: 5,
      rotatingPointOffset: 20,
      borderDashArray: [5, 5],
      objectCaching: true,
      hoverCursor: 'pointer'
    });

  }

  const canvasBgColor = (e) => {
    setCanvasColor(e.hex);

    canvas.backgroundColor = e.hex;
    canvas.renderAll();
  }

  /** Add Text in Canvas */
  const addText = (itext,type) => {
    setTextClick(true);

    let text = new fabric.IText(itext, {
      fontFamily: 'arial',
      left: 100,
      top: 100,
      objecttype: 'text',
      fill: '#000000'
    });

    text.type = 'text';

    if(type === 'head'){
      text.fontWeight = 'bold';
      text.fontSize = 40;
    }else if(type === 'sub'){
      text.fontWeight = 400;
      text.fontSize = 30;
    }else if(type === 'body'){
      text.fontWeight = 'normal';
      text.fontSize = 20;
    }

    canvas.add(text);
    canvas.renderAll();
  }

  const addQuotesData = (text,type) => {
    setTextClick(true);

    let quote = new fabric.Textbox(text, {
      fontFamily: 'arial',
      left: 100,
      top: 100,
      width: 350,
      objecttype: 'text',
      fill: '#000000'
    });

    quote.type = 'text';

    if(type === 'body'){
      quote.fontWeight = 'normal';
      quote.fontSize = 20;
    }

    canvas.add(quote);
    canvas.renderAll();
  }

  /** Add Images in Canvas */
  const addImage = (canvi,url,type='') => {
    new fabric.Image.fromURL(url, img => {
      img.scale(type !== ''? 0.4 : 0.2);
      canvi.add(img);
      canvi.centerObject(img);
      canvi.renderAll();
    },{ crossOrigin: 'Anonymous' });
    setCanvasJson({...canvasJson,data: canvi.toJSON()});
    saveJSon();
  }

  const uploadImage = async (e) => {

    if(e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {

      if(e.target.files[0].size < 5000000){
        setLoadUploadImg(true);
        const formData = new FormData();
        formData.append('file_type', 'image');
        formData.append('file', e.target.files[0]);

        let options = {
          headers: {
            'Authorization': auth.token
          }
        }

        await axios.post(`${baseURL}upload-file`, formData, options).then(res => {
          if (res.data.status === true) {
            let array = uploadedImg.files;
            array.push(res.data.data);
            setUploadedImg({...uploadedImg, files: array});
            setLoadUploadImg(false);
          }
        });
      }else{
        swal("Oops!","Max allowed size 5MB");
      }

    }else{
      swal("Oops!","You have Selected Invalid File Type");
    }

  }

  /** Add Shapes in Canvas */
  const addShapes = (canvi,type) => {
    let shape;
    if(type === 'rect') {
      shape = new fabric.Rect({
        height: 100,
        width: 150,
        fill: '#000000',
        id: 'filled'
      });
    }else if(type === 'square'){
      shape = new fabric.Rect({
        height: 100,
        width: 100,
        fill: '#000000',
        id: 'filled'
      });
    }else if(type === 'round'){
      shape = new fabric.Rect({
        height: 100,
        width: 100,
        rx: 6,
        ry: 6,
        fill: '#000000',
        id: 'filled'
      });
    }else if(type === 'circle'){
      shape = new fabric.Circle({
        radius: 55,
        stroke: 'rgb(0,0,0)',
        strokeWidth: 2,
        fill: '#000000',
        id: 'filled'
      });
    }
    else if(type === 'triangle'){
      shape = new fabric.Triangle({
        width: 100,
        height: 100,
        rx: 30,
        ry: 30,
        stroke: 'rgb(0,0,0)',
        strokeWidth: 2,
        fill: '#000000'
      });
    } else if(type === 'circle-border'){
      shape = new fabric.Circle({
        radius: 55,
        stroke: 'rgb(0,0,0)',
        strokeWidth: 2,
        fill: 'rgba(0, 0, 0, 0)',
        id: 'nofilled'
      });
    }else if(type === 'triangle-border'){
      shape = new fabric.Triangle({
        width: 100,
        height: 100,
        rx: 30,
        ry: 30,
        stroke: 'rgb(0,0,0)',
        strokeWidth: 2,
        fill: 'rgba(0, 0, 0, 0)',
        id: 'nofilled'
      });
    }
    else if(type === 'rect-border'){
      shape = new fabric.Rect({
        height: 100,
        width: 150,
        fill: 'rgba(0, 0, 0, 0)',
        stroke: 'rgb(0,0,0)',
        strokeWidth: 2,
        id: 'nofill'
      });
    }else if(type === 'square-border'){
      shape = new fabric.Rect({
        height: 100,
        width: 100,
        fill: 'rgba(0, 0, 0, 0)',
        stroke: 'rgb(0,0,0)',
        strokeWidth: 2,
        id: 'nofill'
      });
    }else if(type === 'round-border'){
      shape = new fabric.Rect({
        height: 100,
        width: 100,
        rx: 6,
        ry: 6,
        fill: 'rgba(0, 0, 0, 0)',
        stroke: 'rgb(0,0,0)',
        strokeWidth: 2,
        id: 'nofill'
      });
    }

    canvi.add(shape);
    canvi.renderAll();
    setCanvasJson({...canvasJson,data: canvi.toJSON()});
    saveJSon();
  }

  const shapeColor = (e) => {
    let activeObject = canvas.getActiveObject();
    let objID = activeObject.id;

    setShapeHex(e.hex);

    if(activeObject !== null) {

      if(objID == 'nofill'){
        canvas.getActiveObject().set("stroke", e.hex);
      }else{
        canvas.getActiveObject().set("fill", e.hex);
        canvas.getActiveObject().set("stroke", e.hex);
      }

      canvas.renderAll();
    }
    saveJSon();
  }

  /** Add Border in Canvas */
  const addBorder = (type) => {
    let border = new fabric.Rect({
      width: 347,
      height: 50,
      fill: 'transparent',
      stroke: '#222',
      id: 'border'
    });

    if(type === 'solid-border'){
      border.set({
        strokeWidth: 3
      })
    }
    else if(type === 'dashed-border'){
      border.set({
        strokeWidth: 3,
        strokeDashArray: [8, 8]
      });
    }
    else if(type === 'dashed-border-light'){
      border.set({
        strokeWidth: 2,
        strokeDashArray: [5, 5]
      });
    }
    else if(type === 'dotted-border'){
      border.set({
        strokeWidth: 3,
        strokeLineCap: "round",
        strokeDashArray: [0, 8],
        strokeLineJoin: 'crop'
      });
    }

    canvas.add(border);
    canvas.renderAll();
  }

  const borderColor = (e) => {
    let activeObject = canvas.getActiveObject();
    setBorderHex(e.hex);
    if(activeObject !== null){
      canvas.getActiveObject().set({
        stroke: e.hex
      });
      canvas.renderAll();
    }
  }

  /** Add Emoji in Canvas */
  const addEmoji = (code) => {
    let text = new fabric.IText(code, {
      fontSize: 40,
      editable: false,
    });

    text.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
    });

    canvas.add(text);
    text.center();
  }

  /** Add Icon in canvas */
  const addIcon = (code) => {
    let iconUnicode = getIconUnicode(code);
    let icon = new fabric.IText(iconUnicode, {
      fill: '#000000',
      fontSize: 50,
      fontFamily: 'FontAwesome',
      editable: false,
    });

    icon.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
    });

    canvas.add(icon);
    canvas.renderAll();
    icon.center();
  }

  function getIconUnicode(elem) {
    let content = window.getComputedStyle(
        document.querySelector('.' + elem), ':before'
    ).getPropertyValue('content');
    return content.replace(/["']/g, '');
  }

  /** Add top or Bottom layer */
  const addLayer = (type) => {
    let layer = new fabric.Rect({
      width: 100,
      height: 80,
      left: -4,
      fill: '#000000',
      lockMovementY: true,
      lockMovementX: true,
    });
    layer.set({
      scaleX: canvas.width / layer.width
    });

    if(type === 'top'){
      layer.set({
        id: type,
        top: -1,
      });
    }else if(type === 'bottom'){
      layer.set({
        id: type,
        top: canvas.height - (layer.height * layer.scaleY),
      });
    }

    canvas.add(layer);
    canvas.sendToBack(layer);
    canvas.bringToFront(layer)
  }

  const layerColor = (e) => {
    let activeLayer = canvas.getActiveObjects();
    if(activeLayer !== undefined){
      canvas.getActiveObject().set({
        fill: e.target.value
      });
      canvas.renderAll();
    }
  }

  const onSearchEmoji = (e) => {
    setEmojiQ(e.target.value)
  };

  const onIconSearch = (e) => {
    setIconQ(e.target.value);
  }

  const searchEmoji = (emojis) => {
    return emojis.filter(
        emoji => emoji.tag.toLowerCase().indexOf(emojiQ.toLowerCase()) > -1
    );
  }

  const searchIcon = (icons) => {
    return icons.filter(
        icon => icon.toLowerCase().indexOf('fa-'+iconQ.toLowerCase()) > -1
    );
  }

  /** Delete Selected Layer */
  const deleteLayer = () => {
    let activeLayer = canvas.getActiveObjects();
    activeLayer.forEach(function (object) {
      canvas.remove(object);
    });
    saveJSon();
  }

  /** Layer Front or Back */
  const layerFront = (canvas) => {
    let activeObject = canvas.getActiveObject();
    canvas.bringToFront(activeObject);
    canvas.renderAll();
  }

  const layerBack = (canvas) => {
    let activeObject = canvas.getActiveObject();
    canvas.sendToBack(activeObject);
    canvas.renderAll();
  }

  /** Duplicate Layer */
  const duplicate = () => {
    let activeObject = canvas.getActiveObject();

    if(activeObject !== null) {
      activeObject.clone(function (clonedObj) {
        canvas.discardActiveObject();
        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true,
        });
        if (clonedObj.type === 'activeSelection') {
          clonedObj.canvas = canvas;
          clonedObj.forEachObject(function (obj) {
            canvas.add(obj);
          });
          clonedObj.setCoords();
        } else {
          canvas.add(clonedObj);
        }
        activeObject.top += 10;
        activeObject.left += 10;
        canvas.setActiveObject(clonedObj);
        canvas.requestRenderAll();
      });
    }
  }


  /** Get All JSON Data */
  const getJson = async (e) => {
    setCanvasLoader(true);
    let data = JSON.stringify(canvas);
    let thumbnail = canvas.toDataURL({height : 394, width : 700});

    let json_data = {
      thumbnail: thumbnail,
      data: data,
      name: campData.name,
      id: editId
    }

    let options = {
      headers: {
        'Authorization': auth.token
      }
    }

    await axios.post(`${baseURL}save-thumbnail`,json_data,options).then(res=>{
      if(res.data.status === true){
        dispatch(setAlert(res.data.message,'success'));
        history.push('/contents');
      }
      setCanvasLoader(false);
    });

  }

  const moveHistory = useCallback(
      step => {
        const currentStateIndex = canvasHistory.indexOf(canvasState);
        const prevState = canvasHistory[currentStateIndex + step];
        canvas.loadFromJSON(prevState);
        setCanvasState(prevState);
      },
      [canvas, canvasState, canvasHistory, setCanvasState]
  );

  const onUndo = useCallback(() => moveHistory(-1), [moveHistory]);

  const onRedo = useCallback(() => moveHistory(1), [moveHistory]);

  const downloadCanvas = (e) => {

    console.log('called');
    let timestamp = Math.floor(new Date().getTime() / 1000);
    let multiplier = 720 / parseFloat('394');
    let url = canvas.toDataURL({
      multiplier: multiplier
    });
    let name = timestamp+'.png';
    
    let downloadLink = document.getElementById('downloadCanvas');
    downloadLink.setAttribute('href',url);
    downloadLink.setAttribute('download',name);
    downloadLink.click();
  }


  useEffect(() => {

    setCanvas(initCanvas);
    initCanvasStyle();

  }, []);


  useEffect(() => {
    if(canvas !== null){
      canvas.loadFromJSON(canvas);

      canvas.on("object:modified", onObjectModified);
    }
  }, [canvas, onObjectModified]);

  const onObjectSelected = (e) =>{
    if(e.target.get('type') === 'text'){

      setTextClick(true);
    }else{
      setTextClick(false);
    }
  }

  useEffect(()=>{
    if(canvas !== null){
      canvas.on('selection:updated', onObjectSelected);
    }
  },[canvas,onObjectSelected])

  useEffect(() => {

      if(canvas !== null) {
        if (queryString.parse(location.search).id !== undefined) {
          console.log(queryString.parse(location.search).id,'id');

          initCanvasStyle();

          let us = contentsData.images.filter(cont => cont.id === queryString.parse(location.search).id);

          setEditId(queryString.parse(location.search).id);
          canvas.loadFromJSON(JSON.parse(us[0].data), function () {
            canvas.renderAll();
          })

        }

      }


  },[canvas])

  useEffect(()=>{
    if(canvas !== null){
      if (queryString.parse(location.search).id === undefined) {

        canvas.dispose();

        setCanvas(initCanvas);
        initCanvasStyle();
        setEditId('');

        console.log('called');
      }
    }
  },[location])



  return (
        <>
          <section className="imgcreator-sec">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                <h6>Select a Layer To Make Changes To It</h6>
                  <div className="row">
                    <div className="col-lg-8  col-lg-8-custome  p-0 ">
                      <div className="creator-box">
                        <div className="creator">
                          <div className="row">
                            <div className="col-md-6 d-flex align-items-center">
                              <div className="creator-icon">
                                <ul className="list-inline d-flex m-0">
                                  <li>
                                    <a onClick={(e)=>onUndo()} className={`${canvasHistory[0] === canvasState ? 'hide-button' : ''}`}  data-iscapture="true"  data-tip="Undo"  data-for="mainTooltip">
                                      <img src={undo} alt="undo-icon" />
                                      <ReactTooltip
                          id="mainTooltip"
                          place="top"
                          multiline={false}
                          width="100px"
                      />
                                    </a>
                                  </li>
                                  <li>
                                    <a onClick={(e)=>onRedo()} className={`${canvasHistory[canvasHistory.length - 1] === canvasState ? 'hide-button' : ''}`} data-iscapture="true"  data-tip="Redo"  data-for="mainTooltip">
                                      <img src={redo} alt="redo-icon" />
                                      <ReactTooltip
                          id="mainTooltip"
                          place="top"
                          multiline={false}
                          width="100px"
                      />
                                    </a>
                                  </li>
                                  <li>
                                    <a onClick={(e)=>duplicate(e)} data-iscapture="true"  data-tip="Duplicate Layer"  data-for="mainTooltip">
                                      <img src={laryer} alt="laryer" />
                                      <ReactTooltip
                          id="mainTooltip"
                          place="top"
                          multiline={false}
                          width="100px"
                      />
                                    </a>
                                  </li>
                                  <li className="downsize">
                                    <a onClick={(e)=>layerBack(canvas)} data-iscapture="true"  data-tip="Down Layer"  data-for="mainTooltip">
                                      <img src={downarrow} alt="downarrow" />
                                      <ReactTooltip
                          id="mainTooltip"
                          place="top"
                          multiline={false}
                          width="100px"
                      />
                                    </a>
                                  </li>
                                  <li className="downsize">
                                    <a onClick={(e)=>layerFront(canvas)} data-iscapture="true"  data-tip="Up Layer"  data-for="mainTooltip">
                                      <img src={uparrow} alt="uparrow" />
                                      <ReactTooltip
                          id="mainTooltip"
                          place="top"
                          multiline={false}
                          width="100px"
                      />
                                    </a>
                                  </li>
                                  <li>
                                    <a onClick={(e)=>deleteLayer(e)} data-iscapture="true"  data-tip="Delete"  data-for="mainTooltip">
                                      <img src={icon} alt="delete-icon" />
                                      <ReactTooltip
                          id="mainTooltip"
                          place="top"
                          multiline={false}
                          width="100px"
                      />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-6 d-flex align-items-end flex-column justify-content-center">
                              <div className="creator-download"  >
                                <ul className="list-inline mb-1 d-flex justify-content-end">
                                  <li>
                                    <a onClick={(e)=>downloadCanvas(e)} >
                                      <span><img src={downloaded} alt="download-icon" /></span> Download</a>
                                    <a id="downloadCanvas" style={{display: "none"}}></a>
                                  </li>
                                  <li>
                                    <a onClick={(e)=>getJson(e)}>
                                      <span><img src={save} alt="save-icon" /></span> Save
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-12">
                          
                              <canvas id="canvas" className="creator-img" width="742px">
                                {/*<img src={bitcoin} className="img-fluid" alt="bitcoin-img" />*/}
                                <h4>Search from library or upload an image to start customizing it</h4> 
                              </canvas>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 p-0  height">
                      <div className="box" id="laryer-options">
                        <nav>
                          <div className="nav nav-tabs d-flex justify-content-between  " id="nav-tab" role="tablist">
                           
                            <a className="nav-item active show nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="true">
                              <span><img src={images} alt="images-img" /></span>
                              <h6 className="m-0">Images</h6>
                            </a>

                            <a className="nav-item nav-link" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="false">
                              <span><img src={star} alt="star-img" /></span>
                              <h6 className="m-0">General</h6>
                            </a>


                            <a className="nav-item nav-link" id="nav-text-tab" data-toggle="tab" href="#nav-text" role="tab" aria-controls="nav-text" aria-selected="false">
                              <span><img src={text} alt="star-img" /></span>
                              <h6 className="m-0">Text</h6>
                            </a>
                            <a className="nav-item nav-link" id="nav-quote-tab" data-toggle="tab" href="#nav-quote" role="tab" aria-controls="nav-quote" aria-selected="false">
                              <span><i class="fas fa-quote-left"></i></span>
                              <h6 className="m-0">Quotes</h6>
                            </a>
                            <a className="nav-item nav-link" id="nav-cinema-tab" data-toggle="tab" href="#nav-cinema" role="tab" aria-controls="nav-cinema" aria-selected="false">
                              <span><img src={cinema} alt="star-img" /></span>
                              <h6 className="m-0">Cinema</h6>
                            </a>
                            <a className="nav-item nav-link" id="nav-speach-tab" data-toggle="tab" href="#nav-speach" role="tab" aria-controls="nav-speach" aria-selected="false">
                              <span><img src={speach} alt="star-img" /></span>
                              <h6 className="m-0">Shapes</h6>
                            </a>
                            <a className="nav-item nav-link" id="nav-border-tab" data-toggle="tab" href="#nav-border" role="tab" aria-controls="nav-border" aria-selected="false">
                              <span><img src={borders} alt="star-img" /></span>
                              <h6 className="m-0">Border</h6>
                            </a>
                          </div>
                        </nav>
                        <div className="tab-content  parent-tab" id="nav-tabContent">
                          {/* <div id="extra-tab-pane" className="tab-pane  p-4">
                            <p className="flex-box mb-4"><b>Image Type:</b>Create Image</p>
                            <p className="flex-box mb-4"><b>Width:</b>1280px</p>
                            <p className="flex-box"><b>Height:</b>720px</p>
                          </div> */}
                         
                          <div className="tab-pane active show p-2 fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <div className="nav-img-content ">
                              <ul className="nav nav-pills mb-2 d-flex justify-content-center" id="pills-tab" role="tablist">
                                <li className="nav-item">
                                  <a className="nav-link" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="false">Library</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link active" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="true">Uploads</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" id="pills-color-tab" data-toggle="pill" href="#pills-color" role="tab" aria-controls="pills-color" aria-selected="false">Color</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" id="pills-color-tab" data-toggle="pill" href="#pills-meme" role="tab" aria-controls="pills-color" aria-selected="false">Meme</a>
                                </li>
                              </ul>
                              <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                  <ImageSearch addImage={addImage} canvas={canvas} />
                                </div>
                                <div className="tab-pane fade " id="pills-meme" role="tabpanel" aria-labelledby="pills-home-tab">
                                  <MemeSearch addImage={addImage} canvas={canvas} />
                                </div>
                                <div className="tab-pane active show fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                  <div className="uplaod-img" style={{cursor: "pointer"}}>
                                    <i className="fa fa-cloud-upload mr-2" /> { loadUploadImg ? <i style={{fontSize: "20px", color: "#fff"}} className="fa fa-spinner fa-spin mr-2"/> : 'Upload an image' }
                                    <input readOnly onChange={(e)=>uploadImage(e)} id="upload" className={`img-upload-input ${loadUploadImg ? 'hide-social' : '' }`} type="file" accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG" />
                                  </div>
                                  <hr />
                                  {
                                    uploadedImg.files.length > 0 ?
                                        <div className="row mt-2">
                                          {
                                            uploadedImg.files.map((item,index)=>{
                                              return(
                                                  <div className="col-md-4" key={index} style={{marginBottom: "10px"}}>
                                                    <div className="img-part">
                                                      <img width="100%" src={item} className="img-fluid" onClick={(e)=>addImage(canvas,item)} alt />
                                                    </div>
                                                  </div>
                                              )
                                            })
                                          }
                                        </div>
                                     : <p className="text-center">You do not have any uploads</p>
                                  }
                                </div>
                                <div className="tab-pane p-2 fade" id="pills-color" role="tabpanel" aria-labelledby="pills-color-tab">
                                  <div className="row p-2">
                                    <div className="col-md-6 p-0 d-flex align-items-center justify-content-end">
                                      <h6 className="bgcolor">Background Color</h6>
                                    </div>
                                    <div className="col-md-6 d-flex align-items-center image-editor">
                                      <div className="color-chooser" style={{width: "100%"}}>
                                        <div id="cp5c" className="input-group" title="Using format option">
                                          {/*<input onChange={(e)=>canvasBgColor(e)} type="color" className="form-control input-lg" />*/}
                                          {/*<span className="input-group-append">*/}
                                          {/*  <span className="input-group-text colorpicker-input-addon"><i /></span>*/}
                                          {/*</span>*/}

                                          {
                                            <>
                                              <div className="swatch" onClick={ (e)=>setShowCanvasColor(true) }>
                                                <div className="swatch-color" style={{backgroundColor: canvasColor}} />
                                              </div>
                                              { showCanvasColor ? <div>
                                                <div className="swatch-cover" onClick={(e)=>setShowCanvasColor(false)}/>
                                                <SketchPicker color={canvasColor} onChange={ (e)=>canvasBgColor(e) } />
                                              </div> : null }
                                            </>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="tab-pane p-2 fade" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div className="general-box mt-4">
                              <label>Campaign Name</label>
                              <input type="text" name="name" className="form-control" onChange={(e)=>inputCampName(e)} placeholder="Enter campaign name" />
                            </div>
                          </div>

                          <div className="tab-pane p-2 fade" id="nav-text" role="tabpanel" aria-labelledby="nav-text-tab">
                            <div className={`text-items ${textClick ? 'hide-social' : ''} `} id="text-realative">
                              <a onClick={(e)=>addText('Add a Heading','head')} className="text-item item item-1">Add a Heading</a>
                              <a onClick={(e)=>addText('Add a SubHeading','sub')} className="text-item item item-1 subhead">Add a SubHeading</a>
                              <a onClick={(e)=>addText('Add some body text','body')} className="text-item item item-1 txtbody">Add some body text</a>
                            </div>
                            <div id={`text-styles`} className={`text-styles-wrap ${textClick ? 'display-div left-0' : 'hide-social'}  `}>
                              <a onClick={(e)=>setTextClick(false)} className="visit"><i className="fas fa-long-arrow-alt-left" /></a>
                              <TextEditor canvas={canvas} />
                            </div>
                          </div>
                          <div className="tab-pane p-2 fade" id="nav-quote" role="tabpanel" aria-labelledby="nav-quote-tab">
                            <div className={`text-items`} id="quote-realative">
                              <QuoteImage addQuotesData={addQuotesData} />
                            </div>
                          </div>
                          <div className="tab-pane p-2 fade" id="nav-cinema" role="tabpanel" aria-labelledby="nav-cinema-tab">
                            <div className="cinema-tab">
                              <ul className="nav nav-pills  d-flex justify-content-center mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item">
                                  <a className="nav-link active" id="pills-icon-tab" data-toggle="pill" href="#pills-icon" role="tab" aria-controls="pills-icon" aria-selected="true">Icons</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" id="pills-emoji-tab" data-toggle="pill" href="#pills-emoji" role="tab" aria-controls="pills-emoji" aria-selected="false">Emoji</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" id="pills-top-tab" data-toggle="pill" href="#pills-top" role="tab" aria-controls="pills-top" aria-selected="false">Top &amp; Bottom Layer</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" id="pills-upno-tab" data-toggle="pill" href="#pills-upno" role="tab" aria-controls="pills-upno" aria-selected="false">Uploads</a>
                                </li>
                              </ul>
                              <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-icon" role="tabpanel" aria-labelledby="pills-icon-tab">
                                  <div className="search-img">
                                    <div className="form-group has-search">
                                      <input type="text" onChange={(e)=>onIconSearch(e)} className="form-control" placeholder="Search" />
                                      <span className="fa fa-search form-control-feedback" />
                                    </div>
                                  </div>
                                  <div className="icon-items">
                                    {
                                      iconImg.length > 0 ?
                                        searchIcon(iconImg).map((item,index)=>{
                                          return(
                                              <a onClick={(e)=>addIcon(item)} key={index} className="emoji-custom">
                                                <i className={`fa ${item}`} aria-hidden="true"></i>
                                              </a>
                                          )
                                        })
                                      : ''
                                    }
                                  </div>
                                </div>
                                <div className="tab-pane fade" id="pills-emoji" role="tabpanel" aria-labelledby="pills-emoji-tab">
                                  <div className="search-img">
                                    <div className="form-group has-search">
                                      <input type="text" onChange={(e)=>onSearchEmoji(e)} className="form-control" placeholder="Search" />
                                      <span className="fa fa-search form-control-feedback" />
                                    </div>
                                  </div>
                                  <div className="icon-items">
                                    {
                                      emoji.length > 0 ?
                                         searchEmoji(emoji).map((item,index)=>{
                                           return(
                                               <>
                                                 <a onClick={(e)=>addEmoji(parse(`&#x${item.code};`))} key={index} className="emoji-custom">
                                                   <span title={item.tag}>{parse(`&#x${item.code};`)}</span>
                                                 </a>
                                               </>
                                           )
                                         })
                                      : ''
                                    }
                                  </div>
                                </div>
                                <div className="tab-pane p-2 fade" id="pills-top" role="tabpanel" aria-labelledby="pills-top-tab">
                                  <div className="aad-layer">
                                    <h6>Add top layer</h6>
                                    <div className="laryer mt-4" onClick={(e)=>addLayer('top')} />
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                                      <div className="top-color">
                                        <h6 className="m-0">Background Color</h6>
                                      </div>
                                    </div>
                                    <div className="col-md-6 d-flex align-items-center">
                                      <div id="cp5" className="input-group" title="Using color option">
                                        <input type="color" onChange={(e)=>layerColor(e)} className="form-control input-lg" />
                                        <span className="input-group-append">
                                          <span className="input-group-text colorpicker-input-addon"><i /></span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <br/>
                                  <div className="aad-layer">
                                    <h6>Add bottom layer</h6>
                                    <div className="laryer mt-4" onClick={(e)=>addLayer('bottom')} />
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                                      <div className="top-color">
                                        <h6 className="m-0">Background Color</h6>
                                      </div>
                                    </div>
                                    <div className="col-md-6 d-flex align-items-center">
                                      <div id="cp5" className="input-group" title="Using color option">
                                        <input type="color" onChange={(e)=>layerColor(e)} className="form-control input-lg" />
                                        <span className="input-group-append">
                                          <span className="input-group-text colorpicker-input-addon"><i /></span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="tab-pane fade" id="pills-upno" role="tabpanel" aria-labelledby="pills-upno-tab">
                                  <div className="uplaod-img">
                                    <i className="fa fa-cloud-upload mr-2" /> { loadUploadImg ? 'Uploading...' : 'Upload an image' }
                                    <input readOnly onChange={(e)=>uploadImage(e)} id="upload" className={`img-upload-input ${loadUploadImg ? 'hide-social' : '' }`} type="file" accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG" />
                                  </div>
                                  <hr />
                                  {
                                    uploadedImg.files.length > 0 ?
                                        <div className="row mt-2">
                                          {
                                            uploadedImg.files.map((item,index)=>{
                                              return(
                                                  <div className="col-md-6" key={index} style={{marginBottom: "10px"}}>
                                                    <div className="img-part">
                                                      <img width="100%" src={item} className="img-fluid" onClick={(e)=>addImage(canvas,item)} alt />
                                                    </div>
                                                  </div>
                                              )
                                            })
                                          }
                                        </div>
                                        : <p className="text-center">You do not have any uploads</p>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="nav-speach" role="tabpanel" aria-labelledby="nav-speach-tab">
                            <div className="shape-color p-2">
                              <h6 className="mt-2">Shape Color</h6>
                              <div id="cp6" className="input-group mt-3 image-editor-2" title="Using color option">
                                {/*<input type="color" className="form-control input-lg" onChange={(e)=>shapeColor(e)} />*/}
                                {/*<span className="input-group-append">*/}
                                {/*  <span className="input-group-text colorpicker-input-addon"><i /></span>*/}
                                {/*</span>*/}
                                {
                                  <>
                                    <div className="swatch" onClick={ (e)=>setShowShapeColor(true) }>
                                      <div className="swatch-color" style={{backgroundColor: shapeHex}} />
                                    </div>
                                    { showShapeColor ? <div>
                                      <div className="swatch-cover" onClick={(e)=>setShowShapeColor(false)}/>
                                      <SketchPicker color={shapeHex} onChange={ (e)=>shapeColor(e) } />
                                    </div> : null }
                                  </>
                                }
                              </div>
                              <div className="shape-box d-flex justify-content-around  mt-2">
                                <div className="shape-item">
                                  <a onClick={(e)=>addShapes(canvas,'square')} />
                                </div>
                                <div className="shape-item2">
                                  <a onClick={(e)=>addShapes(canvas, 'round')}  />
                                </div>
                                <div className="shape-item3">
                                  <a onClick={(e)=>addShapes(canvas, 'rect')}/>
                                </div>
                              </div>

                              <div className="shape-box d-flex justify-content-around  mt-2">
                                  <div className="shape-item">
                                      <a onClick={(e)=>addShapes(canvas,'circle')} className="circle"></a>
                                      <a onClick={(e)=>addShapes(canvas,'triangle')} className="triangle"></a>
                                  </div>
                              </div>


                              <div className="shape-box d-flex justify-content-around  mt-2">
                                <div className="shape-item-border">
                                  <a onClick={(e)=>addShapes(canvas,'square-border')} />
                                </div>
                                <div className="shape-item2-border">
                                  <a onClick={(e)=>addShapes(canvas, 'round-border')}  />
                                </div>
                                <div className="shape-item3-border">
                                  <a onClick={(e)=>addShapes(canvas, 'rect-border')}/>
                                </div>
                              </div>


                              <div className="shape-box d-flex justify-content-around   mt-2">
                                  <div className="shape-item">
                                      <a onClick={(e)=>addShapes(canvas,'circle-border')} className="cliped-circle "/>
                                      <a onClick={(e)=>addShapes(canvas,'triangle-border')} className="cliped-triangle " />
                                  </div>
                              </div>




                            </div>
                          </div>
                          <div className="tab-pane p-2 fade" id="nav-border" role="tabpanel" aria-labelledby="nav-border-tab">
                            <div className="border-content mt-2">
                              <h6>Border color</h6>
                              <div id="cp7" className="input-group mt-3 image-editor-2" title="Using color option">
                                {/*<input type="color" onChange={(e)=>borderColor(e)} className="form-control input-lg" />*/}
                                {/*<span className="input-group-append">*/}
                                {/*  <span className="input-group-text colorpicker-input-addon"><i /></span>*/}
                                {/*</span>*/}
                                {
                                  <>
                                    <div className="swatch" onClick={ (e)=>setShowBorderColor(true) }>
                                      <div className="swatch-color" style={{backgroundColor: borderHex}} />
                                    </div>
                                    { showBorderColor ? <div>
                                      <div className="swatch-cover" onClick={(e)=>setShowBorderColor(false)}/>
                                      <SketchPicker color={borderHex} onChange={ (e)=>borderColor(e) } />
                                    </div> : null }
                                  </>
                                }
                              </div>
                              <div className="change-border-color mt-3">
                                <a onClick={(e)=>addBorder('solid-border')} className="border-style solid-border" />
                                <a onClick={(e)=>addBorder('dashed-border')} className="border-style dashed-border" />
                                <a onClick={(e)=>addBorder('dashed-border-light')} className="border-style dashed-border-light" />
                                <a onClick={(e)=>addBorder('dotted-border')} className="border-style dotted-border" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>

          {
            canvasLoader === true ?
                <div className="loaderbox">
                  <div className="loader"></div>
                </div>
            : ''
          }


        </>
    )
}

export default ImageEditor;

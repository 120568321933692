const initialState = {
    accounts: [],
    loader: false,
    message: null
}

export default function (state = initialState, action) {

    switch (action.type) {
        case 'FETCH_SOCIAL_ACCOUNTS':
            return{
                ...state,
                accounts: action.payload
            }
        case 'SET_SOCIAL_STATUS':
            return{
                ...state,
                loader: true,
                message: action.payload
            }
        case 'REMOVE_SOCIAL_STATUS':
            return {
                ...state,
                loader: false,
                message: null
            }
        default:
            return {
                ...state
            }
    }
}
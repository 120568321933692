import React, {useEffect, useState} from "react";
import {Link, NavLink, useHistory} from "react-router-dom";
import * as queryString from "query-string";
import axios from "axios";
import Navbar from "./Navbar";
import BannerLinebar from "./BannerLinebar";

const ArticleDetails = ({location}) => {

    const history = useHistory();
    const [category, setCategory] = useState([]);
    const [article, setArticle] = useState([]);
    const [loadContent, setLoadContent] = useState(false);

    const articleId = queryString.parse(location.search).cid;
    const categoryId = queryString.parse(location.search).id;

    const [articleData, setArticleData] = useState({
        id:0,
        title:"",
        article:""
    })

    const onBackPrev = () => {
        history.goBack()
    }

    const fetchCategory = async () => {
        setLoadContent(true);
        await axios({
            method: "POST",
            url: `https://adminserver.reelapps.io/api/fetch-article-category`,
            data: JSON.stringify({app_id: 35}),
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            if (res.data.status === true) {
                setCategory(res.data.data);
            }
            setLoadContent(false);
        }).catch(error => {
            setLoadContent(false);
        })
    }

    const fetchArticle = async () => {
        setLoadContent(true);
        await axios({
            method: "POST",
            url: `https://adminserver.reelapps.io/api/fetch-article`,
            data: JSON.stringify({app_id: 35}),
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            if (res.data.status === true) {
                setArticle(res.data.data);
            }
            setLoadContent(false);
        }).catch(error => {
            setLoadContent(false);
        })
    }

    useEffect(() => {
        if(articleId !== undefined){
            const queriedCategory = article.find(item => item.category_id === categoryId);
            if(queriedCategory !== undefined)
            {
                const queriedArticle = queriedCategory.items.find(article => article.id == articleId);
                if(queriedArticle !== undefined)
                {
                    setArticleData({
                        id:queriedArticle.id,
                        title: queriedArticle.title,
                        article: queriedArticle.content
                    })
                }
            }
        }
    },[article])

    useEffect(() => {
        fetchCategory();
        fetchArticle();
    }, [])

    return(
        <>
            <Navbar/>
            <BannerLinebar/>
            <section className="sec-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sec-1-heading">
                                <h5 className="m-0">Video Training</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="videoreel-tutorial-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Link className="rv-anchor" to="help-and-support">
                                Support
                            </Link> /
                            {
                                category.length > 0 ?
                                    category.map(item => item.id === categoryId ? <Link className="rv-anchor" to={"training?id=" + item.id}>{item.name}</Link> : '' )
                                    : ''
                            } /
                            {
                                category.length > 0 ?
                                    category.map(item => item.id === categoryId ? <a className="rv-anchor">{articleData.title}</a> : '' )
                                    : ''
                            }
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-4 col-md-12">
                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                                 aria-orientation="vertical">
                                <h5>POPULAR TOPICS</h5>
                                {
                                    category.length > 0 ?
                                        category.map(item => <NavLink to={"training?id=" + item.id}
                                                                      className={`nav-link ${item.id === categoryId ? 'active' : ''}`}>{item.name} </NavLink>)
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="col-md-8 custom-training">
                            <div className="tab-content articalbgcolor" id="v-pills-tabContent">
                                <div className="tab-pane fade show active text-white">
                                    <h4>{articleData.title}</h4>
                                    <div className="content">
                                        <div className="text-white"
                                             dangerouslySetInnerHTML={{
                                                 __html: articleData.article
                                             }}>
                                        </div>
                                    </div>
                                    {
                                        loadContent === true ?
                                            <div className="text-center">
                                                <p style={{fontSize: "40px"}}><i className="fa fa-spinner fa-spin mr-2"/></p>
                                            </div>
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ArticleDetails;
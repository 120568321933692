import React, {useEffect, useState} from "react";
import wordpressLogo from "../../images/wordpress-logo.png";
import Alert from "../alert/alert";
import {useDispatch, useSelector} from "react-redux";
import {saveWordpress} from "../../store/actions/socialAccount";
import {feUrl} from "../../global/global";

const WordpressConnect = ({checkAccount,editClient}) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader,setLoader] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [wordpressInput, setWordpressInput] = useState({
        user_id: editClient.id,
        url: null,
        username: null,
        password: null
    })

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        console.log(wordpressInput);
        dispatch(saveWordpress(wordpressInput,setShowPopup,setLoader));
    }


    const connectWordpress = (e) => {
        setShowPopup(true);

    }

    const onInputChange = (e) => {
        setWordpressInput({...wordpressInput, [e.target.name]: e.target.value});
    }

    useEffect(()=>{
        setWordpressInput({...wordpressInput,user_id: editClient.id});
    },[editClient])

    return(
        <>
            <a className={`${checkAccount === false ? 'hide-button' : '' }`} onClick={(e)=>connectWordpress(e)} href="#">
                <img className={`${checkAccount === false ? 'client-img-opacity' : '' }`} src={wordpressLogo} alt="telegram" />
            </a>

            <Alert/>
            <div className={`modal ${showPopup ? "fade show d-block" : ""}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <form className="modal-content" style={{padding: "15px 20px"}} method="post" onSubmit={(e)=>onFormSubmit(e)}>
                        <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title text-center">Connect Wordpress</h5>
                            <button type="button" className="close" onClick={(e)=>setShowPopup(false)} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="form-group mt-1">
                                    <label htmlFor="exampleInputEmail1">Username</label>
                                    <input type="text" className="form-control"
                                           aria-describedby="emailHelp" placeholder="Enter Username" required
                                           name="username" onChange={(e)=> onInputChange(e)}
                                    />
                                </div>
                                <div className="form-group mt-1">
                                    <label htmlFor="exampleInputEmail1">Password</label>
                                    <input type="password" className="form-control"
                                           aria-describedby="emailHelp" placeholder="Enter Password" required
                                           name="password" onChange={(e)=> onInputChange(e)}
                                    />
                                </div>
                                <div className="form-group mt-1">
                                    <label htmlFor="exampleInputEmail1">URL</label>
                                    <input type="url" className="form-control"
                                           aria-describedby="emailHelp" placeholder="Enter URL" required
                                           name="url" onChange={(e)=> onInputChange(e)}
                                    />
                                </div>
                                <a href={`${feUrl}article-details?id=9&cid=8`} target="_blank">How to get this information ?</a>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-center ">
                            <button type="submit" disabled={loader}>
                                { loader ? <i className="fa fa-spinner fa-spin mr-2"/> : '' } Connect
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default WordpressConnect;
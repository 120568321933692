import React from 'react'
import CreateVideoHead from './SubComponent/CreateVideoHead'
import CreateVideotab from './SubComponent/CreateVideotab'
import Footer from './SubComponent/Footer'
import Navbar from './SubComponent/Navbar'
import {Helmet} from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";

function CreateVideo({location}) {
    return (
        <>
            <Helmet>
                <title>AgencyReel | Create Video </title>
            </Helmet>
           <div className="wrapper-box">         
            <Navbar/>
               <BannerLinebar/>
            <CreateVideoHead/>
            <CreateVideotab location={location}/>
            </div>
        </>
    )
}

export default CreateVideo

import axios from "axios";
import {baseURL} from '../../global/global';
import { setAlert } from "../actions/alert";
import {fetchStream, fetchUserStream} from "./streamAction";
import {fetchSocialAccounts} from "./socialAccount";
import {fetchScheduleContent} from "./schedularAction";
import {addImageContent, addVideoContent} from "./contentsActions";


export const loadUser = () => async (dispatch, getState) => {

    let headers;

    if(localStorage.state){
        headers = {
            'Content-Type': 'application/json',
            'Authorization' : JSON.parse(localStorage.getItem('state')).auth.token
        }
    }

    axios({
        method: 'POST',
        url : `${baseURL}fetch-user`,
        data: {},
        headers: headers
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'LOAD_USER_DATA',payload: res.data.data});
            dispatch(fetchTeamAccount(getState().auth.token));
            dispatch(fetchAppClient(getState().auth.token));
            dispatch(addRebrandData(getState().auth.token));
            dispatch(fetchScheduleContent(getState().auth.token));
        }
    }).catch(error => {

    })


}

export const masterLoginUser = (email,token,history) => (dispatch) => {

    axios({
        method: 'POST',
        url : `${baseURL}master-login`,
        data: JSON.stringify({email, token}),
        headers: {'Content-Type': 'application/json' }
    }).then(res =>{

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch({type: 'LOGIN_USER',payload: res.data.data});

            dispatch(fetchClientAccounts(res.data.data.token));
            dispatch(fetchTeamAccount(res.data.data.token));
            dispatch(fetchAppClient(res.data.data.token));
            dispatch(fetchStream(res.data.data.token));
            dispatch(fetchUserStream(res.data.data.token));
            dispatch(fetchSocialAccounts(res.data.data.token));
            dispatch(fetchScheduleContent(res.data.data.token));
            dispatch(addImageContent(res.data.data.token));
            dispatch(addVideoContent(res.data.data.token));
            dispatch(addRebrandData(res.data.data.token));
            history.push('/dashboard');

        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }

    }).catch(error => {
        console.log(error);
    })

}


export const loginUser = (data,setLoader) => (dispatch) => {

    axios({
        method: 'POST',
        url : `${baseURL}login`,
        data: data,
        headers: {'Content-Type': 'application/json' }
    }).then(res =>{

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch({type: 'LOGIN_USER',payload: res.data.data});

            dispatch(fetchClientAccounts(res.data.data.token));
            dispatch(fetchTeamAccount(res.data.data.token));
            dispatch(fetchAppClient(res.data.data.token));
            dispatch(fetchStream(res.data.data.token));
            dispatch(fetchUserStream(res.data.data.token));
            dispatch(fetchSocialAccounts(res.data.data.token));
            dispatch(fetchScheduleContent(res.data.data.token));
            dispatch(addImageContent(res.data.data.token));
            dispatch(addVideoContent(res.data.data.token));
            dispatch(addRebrandData(res.data.data.token));

        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }

        setLoader(false);

    }).catch(error => {
        console.log(error);

        setLoader(false);
    })

}

export const logoutUser = () => (dispatch,getState) => {
    axios({
        method: 'POST',
        url : `${baseURL}logout`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token }
    }).then(res =>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch({type: 'LOGOUT'});
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
    }).catch(error => {
        console.log(error);
    })
}


export const forgetPassword = (data, setLoader) => (dispatch) =>{

    axios({
        method: 'POST',
        url : `${baseURL}forgot-password`,
        data: data,
        headers: {'Content-Type': 'application/json'}
    }).then(res =>{

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }

        setLoader(false);

    }).catch(error => {
        setLoader(false);
    })

}

export const resetPassword = (data,setLoader,history) => (dispatch) => {

    axios({
        method: 'POST',
        url : `${baseURL}reset-password`,
        data: data,
        headers: {'Content-Type': 'application/json'}
    }).then(res =>{

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            history.push('/');
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }

        setLoader(false);

    }).catch(error => {

        setLoader(false);
    })
}


export const updateName = (data, setLoader) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url : `${baseURL}profile`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res =>{

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch({type: 'CHANGE_USER_NAME',action: data.name});
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false);

    }).catch(error => {
        setLoader(false);
    })

}


export const changePassword = (data,setLoader) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url : `${baseURL}profile`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res =>{

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false);

    }).catch(error => {

        setLoader(false);
    })

}

export const changeEmail = (data,setLoader) => (dispatch,getState) => {

    axios({
        method: 'POST',
        url: `${baseURL}change-email`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res =>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch({type:'CHANGE_USER_EMAIL',action: data.email});
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false);

    }).catch(error => {
        setLoader(false);
    })

}

export const changeProfile = (data,setProfileStatus) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url: `${baseURL}change-image`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res =>{

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch({type: 'CHANGE_USER_IMAGE', payload: res.data});
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setProfileStatus(false);

    }).catch(error => {
        setProfileStatus(false);
    })
}

export const changeClientProfile = (data,setProfileStatus) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url: `${baseURL}change-image`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res =>{

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch(fetchAppClient(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setProfileStatus(false);

    }).catch(error => {
        setProfileStatus(false);
    })
}


export const addClientTeam = (data,setLoader) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url: `${baseURL}create-client`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res =>{

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch(fetchClientAccounts(getState().auth.token));
            dispatch(fetchTeamAccount(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false);

    }).catch(error => {
        setLoader(false);
    })
}


export const addAppClient = (data, setLoader,setCheckAccount,setClientID,setOpenCustomSweet) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url: `${baseURL}add-app-client`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res=>{
        console.log(res);
        if(res.data.status === true){
            // dispatch(setAlert(res.data.message, "success"));
            dispatch(fetchAppClient(getState().auth.token));

            setCheckAccount(true);
            setClientID(res.data.data[0].id);
            setOpenCustomSweet(true);
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false);

    }).catch(error=>{
        setLoader(false);
    });
}

export const editAppClient = (data, setLoader) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url: `${baseURL}edit-app-client-account`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res=>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch(fetchAppClient(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false);

    }).catch(error=>{
        setLoader(false);
    });
}


export const editCaVaAccount = (data,id,setLoader,value) => (dispatch,getState) =>{

    const dataJSON = JSON.stringify({name: data.name, id: id, password: data.password});

    axios({
        method: 'POST',
        url: `${baseURL}edit-client-account`,
        data: JSON.stringify({name: data.name, id: id, password: data.password}),
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res=>{

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));

            if(value === 'Client')
                dispatch({type: 'CHANGE_CLIENT_DETAILS',payload: JSON.parse(dataJSON)});
            else
                dispatch({type: 'CHANGE_TEAM_DETAILS',payload: JSON.parse(dataJSON)});
        }
        else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false);

    }).catch(error =>{
        setLoader(false);
    })
}

export const deleteClientAccount = (data, setShowDelete) => (dispatch, getState) =>{
    axios({
        method: 'POST',
        url: `${baseURL}delete-client`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res=>{

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch(fetchClientAccounts(getState().auth.token));
            dispatch(fetchTeamAccount(getState().auth.token));
            dispatch(fetchAppClient(getState().auth.token));
        }
        else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setShowDelete(false);

    }).catch(error =>{
        setShowDelete(false);
    })
}

export const fetchClientAccounts = (token) => (dispatch) =>{
    axios({
        method: 'POST',
        url: `${baseURL}fetch-client-account`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization': token}
    }).then(res=>{
        if(res.data.status === true){
            dispatch({type: 'USER_CLIENT_ACCOUNTS',payload: res.data.data});
        }
    }).catch(error=>{

    });
}

export const fetchTeamAccount = (token) => (dispatch) =>{
    axios({
        method: 'POST',
        url: `${baseURL}fetch-team-members`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization': token}
    }).then(res=>{
        if(res.data.status === true){
            dispatch({type: 'USER_TEAM_ACCOUNTS',payload: res.data.data});
        }
    }).catch(error=>{

    });
}


export const fetchAppClient = (token) => (dispatch) =>{
    axios({
        method: 'POST',
        url: `${baseURL}fetch-app-client`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization': token}
    }).then(res=>{
        if(res.data.status === true){
            dispatch({type: 'USER_APP_CLIENT',payload: res.data.data});
        }
    }).catch(error=>{

    });
}


export const gdprAction = (data,type,setShowDelete,history) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url: `${baseURL}${type}`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res =>{

        if(res.data.status === true){

            if(type === 'download-user-data'){
                window.location.href = res.data.url;
            }else{
                window.location.reload();
            }

            dispatch(setAlert(res.data.message, "success"));
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setShowDelete(false);

    }).catch(error => {
        dispatch(setAlert('Getting Internal Server Error', "danger"));
        setShowDelete(false);
    })
}


export const addEvents = (token) => (dispatch) =>{

    axios({
        method: 'POST',
        url : `${baseURL}get-calender`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization': token}
    }).then(res =>{

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));

            dispatch({type: 'ADD_EVENTS',payload: res.data.data});
        }

    }).catch(error => {

    })

}

export const addRebrandData = (token) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${baseURL}fetch-wl-rebranding`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization': token}
    }).then(res=>{
        if(res.data.status === true){
            dispatch({type: 'ADD_REBRAND',payload: res.data.data});
        }
    }).catch(error=>{

    })
}


export const removeRebrandData = (token) => (dispatch) =>{
    dispatch({type: 'REMOVE_ADD_REBRAND'});
}
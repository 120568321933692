import React, { useState} from 'react'
import {useDispatch} from "react-redux";
import {changePassword} from "../../store/actions/authAction";
import Alert from "../alert/alert";

function Myacpcom() {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [user,setUser] = useState({
      password: null,
      new_password: null,
      confirm_password: null
    });

    const onInputChange = (e) => {
      setUser({...user,[e.target.name]:e.target.value})
    }

    const onFormSubmit = (e) => {
      e.preventDefault();
      setLoader(true);

      dispatch(changePassword(user,setLoader));
    }

    return (
        <>
         <section className="password-sec">
  <div className="container">
    <Alert/>
    <div className="row  d-flex justify-content-center"> 
      <div className="col-md-12 col-lg-10">
        <div className="personal-heading">
          <h5>Change Password</h5>
        </div>
      </div>
    </div>
    <div className="row mt-2 d-flex justify-content-center">
      <div className="col-md-12 col-lg-10">
        <form className="personal-box line" method="post" onSubmit={(e)=>onFormSubmit(e)}>
          <div className="row d-flex justify-content-center">
            <div className="col-md-6">
              <div className="form-group">
                <small id="emailHelp" className="form-text text-muted">Current Password</small>
                <input type="password" required placeholder="Current Password" className="form-control"
                       id="exampleInputEmail1" aria-describedby="emailHelp" name="password"
                       onChange={(e)=>onInputChange(e)} />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 mt-lg-4">
              <div className="form-group">
                <small id="emailHelp" className="form-text text-muted">New Password</small>
                <input type="password" required placeholder="New Password" className="form-control"
                       id="exampleInputEmail1" aria-describedby="emailHelp" name="new_password"
                       onChange={(e)=>onInputChange(e)} />
              </div> 
            </div>
            <div className="col-md-6 mt-lg-4">
              <div className="form-group">
                <small id="emailHelp" className="form-text text-muted">Confirm New Password</small>
                <input type="password" required placeholder="Confirm Password" className="form-control"
                       id="exampleInputEmail1" aria-describedby="emailHelp" name="confirm_password"
                       onChange={(e)=>onInputChange(e)} />
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12 d-flex justify-content-center">
              <div className="update">
                <button type="submit" className="button" disabled={loader}>
                  {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : '' } Update
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
   
        </>
    )

}

export default Myacpcom

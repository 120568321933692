import React, {useEffect, useState, ReactDOM} from 'react'
import dragdrop from '../../images/drag-drop.png';
import img from '../../images/img.png';
import {DragDropContext, Draggable,Droppable}  from "react-beautiful-dnd";
import {useDispatch, useSelector} from "react-redux";
import {
  addStream, addStreamText, addUpdateStream,
  fetchUserStream,
  removeCurrentStream,
  updateFileSequence
} from "../../store/actions/streamAction";
import axios from "axios";
import {baseURL} from "../../global/global";
import swal from "sweetalert";
import StreamText from "./StreamText";
import {useHistory} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function Cstreamfrom(props) {

  const dispatch = useDispatch();
  const history = useHistory();
  const streamData = useSelector(state => state.stream);
  const auth = useSelector(state => state.auth);

  const [loader, setLoader] = useState(false);
  const [streamLoader, setStreamLoader] = useState(false);
  const [checkStream, setCheckStream] = useState(false);
  const [openCustomSweet, setOpenCustomSweet] = useState(false);
  const [addedStreamId, setAddedStreamId] = useState(null);
  const [checkInputText, setCheckInputText] = useState(true);

  const [updateStream, setUpdateStream] = useState({
    id: null,
    type: null,
    title: null,
    tags: null,
    description: null
  });

  const [stream, setStream] = useState({
    type: "image",
    title: updateStream.title,
    tags: null,
    description: updateStream.description
  });

  const onInputChange = (e) => {
    setStream({...stream,[e.target.name] : e.target.value});
  }

  const onInputUpdate = (e) => {
    setUpdateStream({...updateStream,[e.target.name] : e.target.value});
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    dispatch(addStream(stream,setLoader,setAddedStreamId,setOpenCustomSweet));
  }

  const onFormUpdate = (e) => {
    e.preventDefault();
    setLoader(true);

    dispatch(addUpdateStream(updateStream,setLoader));
  }

  useEffect(()=>{

    if(streamData.current_stream.length > 0) {
      setCheckStream(true);

      setUpdateStream({
        ...updateStream,
        id: streamData.current_stream[0].id,
        type: streamData.current_stream[0].type,
        title: streamData.current_stream[0].title,
        tags: streamData.current_stream[0].tags,
        description: streamData.current_stream[0].description
      });

      setUploadStream({
        ...uploadStream,
        content_stream_id:streamData.current_stream[0].id
      });
    }

  },[streamData.current_stream])

    /** Stream Upload Image or Videos */
    const [uploadStream, setUploadStream] = useState({
      title: updateStream.title,
      description: updateStream.description,
      sequence: 1,
      content_stream_id: null,
      stream: null
    });

    const [successFile,setSuccessFile] = useState({
      files: []
    });

    const [uploadStatus, setUploadStatus] = useState({
      percentage: 0,
      total_size: 0,
      uploaded_size: 0,
      file_name: null
    });

    const [uploadImageCount, setUploadImageCount] = useState(0);


    const onFileInput = async (e,file_type)  =>{

      let files = e.target.files;
      setUploadImageCount(files.length);

      if(file_type === "image"){

        for (let i = 0; i < files.length; i++) {

          let maxUploadSize = 5000000;
          if(files[i].type === 'image/jpg' || files[i].type === 'image/png'
              || files[i].type === 'image/jpeg' || files[i].type === 'image/JPEG'){

            if(files[i].type === 'video/mp4'){
              maxUploadSize = 20000000
            }

            if(files[i].size < maxUploadSize){
              setUploadStream({...uploadStream,sequence: uploadStream.sequence+1});

              const formData = new FormData();
              formData.append('title','');
              formData.append('description','');
              formData.append('sequence',uploadStream.sequence);

              formData.append('stream',files[i]);

              if(updateStream.id !== null){
                formData.append('content_stream_id',updateStream.id);
              }else{
                formData.append('content_stream_id',uploadStream.content_stream_id);
              }

              /** get file uploaded percentage */
              const options = {
                onUploadProgress: (progressEvent) =>{
                  const {loaded,total} = progressEvent;
                  let percent = Math.floor((loaded*100)/total);

                  /** set file size, percentage or name in local state */

                  setUploadStatus({
                    ...uploadStatus,
                    percentage: percent,
                    total_size: total,
                    uploaded_size: loaded,
                    file_name: files[i].name,
                  });

                },
                headers: {
                  'Authorization': auth.token
                }
              }

              await axios.post(`${baseURL}stream`,formData,options).then(res=>{
                if(res.data.status === true){ // Save successfully uploaded file
                  dispatch(fetchUserStream(auth.token));
                  let array = successFile.files;
                  array.push(res.data.data);

                  setSuccessFile({...successFile,files:array});
                  setUploadStatus({...uploadStatus ,percentage: 0,file_name: null});

                }

              })

            }else{
              swal("Oops!","Max Uploaded Size for Image 5MB, Video 20MB");
            }

          }else{
            swal("Oops!","You have Selected Invalid File Type");
          }

        }
      }else if(file_type === "video"){

        for (let i = 0; i < files.length; i++) {

          let maxUploadSize = 5000000;
          if(files[i].type === 'video/mp4'){

            if(files[i].type === 'video/mp4'){
              maxUploadSize = 20000000
            }

            if(files[i].size < maxUploadSize){
              setUploadStream({...uploadStream,sequence: uploadStream.sequence+1});

              const formData = new FormData();
              formData.append('title','');
              formData.append('description','');
              formData.append('sequence',uploadStream.sequence);

              formData.append('stream',files[i]);

              if(updateStream.id !== null){
                formData.append('content_stream_id',updateStream.id);
              }else{
                formData.append('content_stream_id',uploadStream.content_stream_id);
              }

              /** get file uploaded percentage */
              const options = {
                onUploadProgress: (progressEvent) =>{
                  const {loaded,total} = progressEvent;
                  let percent = Math.floor((loaded*100)/total);

                  /** set file size, percentage or name in local state */

                  setUploadStatus({
                    ...uploadStatus,
                    percentage: percent,
                    total_size: total,
                    uploaded_size: loaded,
                    file_name: files[i].name,
                  });

                },
                headers: {
                  'Authorization': auth.token
                }
              }

              await axios.post(`${baseURL}stream`,formData,options).then(res=>{
                if(res.data.status === true){ // Save successfully uploaded file
                  dispatch(fetchUserStream(auth.token));
                  let array = successFile.files;
                  array.push(res.data.data);

                  setSuccessFile({...successFile,files:array});
                  setUploadStatus({...uploadStatus ,percentage: 0,file_name: null});

                }

              })

            }else{
              swal("Oops!","Max Uploaded Size for Image 5MB, Video 20MB");
            }

          }else{
            swal("Oops!","You have Selected Invalid File Type");
          }

        }
      }else{
        swal("Oops!","You have Selected Invalid File Type");
      }


    }

    /** uploaded files drag and drop */
    const onSetRecorder = (result) => {
      if (!result.destination) {
        return;
      }

      const items = reorder(
          successFile.files,
          result.source.index,
          result.destination.index
      );


      /** Send Request for change source file sequence */
      dispatch(updateFileSequence(items));

      setSuccessFile({
        files: items
      })
    }

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

  const showEmptyMessage = (e) => {
    swal("Oops!","Please create content stream before upload files");
  }

  const [formattedText, setFormattedText] = useState([{value: null}]);

  const [inputText, setInputText] = useState([{value: null}]);
  const [textData, setTextData] = useState([<StreamText  />]);

  const removeText = (e,index) => {
    if(inputText.length > 1){
      e.preventDefault();

      if (index > -1) {
        //inputText.splice(index, 1);
        textData.splice(index, 1);

        let tempArr = [...inputText];
        let item = {...tempArr[index]};
        item.value = null;

        tempArr[index] = item;

        let element = document.getElementById(`hide-${index}`);
        let elementRemove = document.getElementById(`remove-${index}`);
        element.classList.add("hide-txt");
        elementRemove.removeAttribute("required");

        setInputText(tempArr);
        setTextData(textData);
      }

    }
  }

  const removeFormatted = (e,index) => {
    if(formattedText.length > 1){
      e.preventDefault();

      if (index > -1) {
        //inputText.splice(index, 1);
        // formattedText.splice(index, 1);

        let tempArr = [...formattedText];
        console.log(tempArr);
        let item = {...tempArr[index]};
        item.value = null;

        tempArr[index] = item;

        let element = document.getElementById(`hide-format-${index}`);
        element.classList.add("hide-txt");

        setFormattedText(tempArr)
      }

    }
  }


  const addMoreText = async () => {
      if(checkStream){
        await setTextData([...textData,<StreamText />]);

        let oldArr = [...inputText];

        let textArr = Array.from(oldArr.concat({value: null}));
        setInputText(textArr);
      }else{
        swal("Oops!","Please create content stream before adding text");
      }
  }

  const addMoreFormatted = async () => {
    if(checkStream){
      let tmpFormat = [...formattedText];

      let formatArr = Array.from(tmpFormat.concat({value: null}));
      setFormattedText(formatArr);
    }else{
      swal("Oops!","Please create content stream before adding formatted text");
    }
  }

  const inputSaveData = (e,index) => {
    if(checkStream){

      let tempArr = [...inputText];
      let item = {...tempArr[index]};
      item.value = e.target.value;

      tempArr[index] = item;
      setInputText(tempArr);

    }else{
      swal("Oops!","Please create content stream first");
    }
  }

  const onInputEditor = (e,index) => {
    if(checkStream){
      let tempArr = [...formattedText];
      let item = {...tempArr[index]};
      item.value = draftToHtml(e);

      tempArr[index] = item;
      setFormattedText(tempArr);

    }else{
      swal("Oops!","Please create content stream first");
    }
  }

  const saveTextFormatData = (e) => {
    e.preventDefault();
    if(checkStream) {
      setStreamLoader(true);

      const formData = new FormData();
      formData.append('title', updateStream.title);
      formData.append('description',JSON.stringify(formattedText));
      formData.append('sequence', null);
      if (updateStream.id !== null) {
        formData.append('content_stream_id', updateStream.id);
      } else {
        formData.append('content_stream_id', uploadStream.content_stream_id);
      }

      dispatch(addStreamText(formData, setStreamLoader,history));
    }else{
      swal("Oops!","Please create content stream first");
    }

  }

  const saveTextData = (e) => {
    e.preventDefault();
    if(checkStream) {
      setStreamLoader(true);

      const formData = new FormData();
      formData.append('title', updateStream.title);
      formData.append('description',JSON.stringify(inputText));
      formData.append('sequence', null);
      if (updateStream.id !== null) {
        formData.append('content_stream_id', updateStream.id);
      } else {
        formData.append('content_stream_id', uploadStream.content_stream_id);
      }

      dispatch(addStreamText(formData, setStreamLoader,history));
    }else{
      swal("Oops!","Please create content stream first");
    }

  }

  useEffect(()=>{
    dispatch(removeCurrentStream());
    setCheckStream(false);
  },[])


  useEffect(()=>{

    /** Edit Stream */
    if(Object.entries(props).length !== 0){
      setCheckStream(true);

      const params = props.id.match.params;
      console.log(params);

      let us = streamData.streams.filter(stream => stream.id === params.id);
      setUpdateStream({
        ...updateStream,
        id: us[0].id,
        title: us[0].title,
        description: us[0].description,
        tags: us[0].tags,
        type: us[0].type
      })
    }

  },[]);

  useEffect(()=>{
    setTextData(textData);
    console.log('called ',textData)
  },[textData.length, inputText]);


  /** Custom Sweetalert */

  const onConfirmCustom = (e) => {
    history.push(`edit-stream/${addedStreamId}`);
  }

  const onCancelCustom = (e) =>{
    history.push('/streams');
  }

    return (
        <>
         <section className="create_content_sec">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="create_content_box">
                    {
                      checkStream ?
                          <form method="post" onSubmit={(e) => onFormUpdate(e)}>
                            <div className="form-group mt-3">
                              <small id="emailHelp" className="form-text text-muted">Type</small>
                              <select className="form-control" required name="type" disabled={true}
                                      onChange={(e) => onInputUpdate(e)}>
                                <option selected={updateStream.type == 'image'} value="image">Image</option>
                                <option selected={updateStream.type == 'video'} value="video">Video</option>
                                <option selected={updateStream.type == 'text'} value="text">Text</option>
                                <option selected={updateStream.type == 'formatted'} value="formatted">Formatted Text</option>
                              </select>
                            </div>
                            <div className="form-group mt-3">
                              <small id="emailHelp" className="form-text text-muted">Tags</small>
                              <input type="text" className="form-control" id="exampleInputEmail1"
                                     aria-describedby="emailHelp" placeholder="Enter a tag separated by comma (,)" name="tags"
                                     defaultValue={updateStream.tags}
                                     onChange={(e) => onInputUpdate(e)}
                              />
                            </div>
                            <div className="form-group mt-3">
                              <small id="emailHelp" className="form-text text-muted">Title</small>
                              <input type="text" className="form-control" id="exampleInputEmail1"
                                     aria-describedby="emailHelp" placeholder="Title of Content Stream" name="title"
                                     defaultValue={updateStream.title}
                                     required
                                     onChange={(e) => onInputUpdate(e)}
                              />
                            </div>
                            <div className="form-group">
                              <small id="emailHelp" className="form-text text-muted">Description</small>
                              <textarea className="form-control" id="exampleFormControlTextarea1"
                                        placeholder="Description of Content Stream"
                                        rows={3} defaultValue={""} name="description"
                                        value={updateStream.description}
                                        required
                                        onChange={(e) => onInputUpdate(e)}
                              />
                            </div>
                            <div className="creat-btn mt-4 d-flex justify-content-end">
                              <button type="submit" className="button" disabled={loader}>
                                {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Update
                              </button>
                            </div>
                          </form>
                          :
                          <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                        <div className="form-group mt-3">
                          <small id="emailHelp" className="form-text text-muted">Type</small>
                          <select className="form-control" required name="type" onChange={(e) => onInputChange(e)}>
                            <option value="image">Image</option>
                            <option value="video">Video</option>
                            <option value="text">Text</option>
                            <option value="formatted">Formatted Text</option>
                          </select>
                        </div>
                        <div className="form-group mt-3">
                          <small id="emailHelp" className="form-text text-muted">Tags</small>
                          <input type="text" className="form-control" id="exampleInputEmail1"
                                 aria-describedby="emailHelp" placeholder="Enter a tag separated by comma (,)" name="tags"
                                 onChange={(e) => onInputChange(e)}
                          />
                        </div>
                        <div className="form-group mt-3">
                          <small id="emailHelp" className="form-text text-muted">Title</small>
                          <input type="text" className="form-control" id="exampleInputEmail1"
                                 aria-describedby="emailHelp" placeholder="Title of Content Stream" name="title"
                                 required
                                 onChange={(e) => onInputChange(e)}
                          />
                        </div>
                        <div className="form-group">
                          <small id="emailHelp" className="form-text text-muted">Description</small>
                          <textarea className="form-control" id="exampleFormControlTextarea1"
                                    placeholder="Description of Content Stream"
                                    rows={3} defaultValue={""} name="description"
                                    required
                                    onChange={(e) => onInputChange(e)}
                          />
                        </div>
                        <div className="creat-btn mt-4 d-flex justify-content-end">
                          <button type="submit" className="button" disabled={loader}>
                            {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Create
                          </button>
                        </div>
                      </form>
                    }
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="create_content_file_upload text-center">
                    {/* <div className="file-upload">
                      <h6>File Upload</h6>
                    </div> */}
                    <div className="drag-drop mt-4">
                      {
                        stream.type === 'text' ?
                            <>
                              {
                                inputText.map((text,index)=>{
                                  return(
                                      <div key={index} id={`hide-${index}`}>
                                        <StreamText />
                                        <div className="form-group" style={{marginBottom: "0"}}>
                                          <small id="emailHelp" className="form-text text-muted text-left">Text</small>
                                          <textarea className="form-control" id="exampleFormControlTextarea1" placeholder
                                                    rows={3} defaultValue={text.value} name="description"
                                                    onChange={(e) => inputSaveData(e,index)}
                                          />
                                        </div>
                                        <div className="text-right">
                                          <a onClick={(e)=>removeText(e,index)} href="#"><i className="fas fa-trash"></i></a>
                                        </div>
                                      </div>
                                  )
                                })
                              }
                              <button style={{margin: "auto",display: "inline-block", marginRight: "10px"}} onClick={saveTextData}
                                      className="btn-submit" disabled={streamLoader}>
                                { streamLoader ? <i className="fa fa-spinner fa-spin mr-2"/> : '' }  Save
                              </button>
                              <button style={{margin: "auto",display: "inline-block"}} onClick={addMoreText} className="btn-submit">Add More</button>
                            </>
                        :   updateStream.type === 'text' ?
                            <form method="post" onSubmit={(e)=>saveTextData(e)}>
                              {
                                inputText.map((text,index)=>{
                                  return(
                                      <div key={index} id={`hide-${index}`}>
                                        <StreamText />
                                        <div className="form-group" style={{marginBottom: "0"}}>
                                          <small id="emailHelp" className="form-text text-muted text-left">Text</small>
                                          <textarea className="form-control" id={`remove-${index}`} placeholder
                                                    rows={3} defaultValue={text.value} name="description"
                                                    onChange={(e) => inputSaveData(e,index)}
                                                    required
                                          />
                                        </div>
                                        <div className="text-right">
                                          <a onClick={(e)=>removeText(e,index)} href="#"><i className="fas fa-trash"></i></a>
                                        </div>
                                      </div>
                                  )
                                })
                              }
                              <button type="submit" style={{margin: "auto",display: "inline-block", marginRight: "10px"}}
                                      className="btn-submit" disabled={streamLoader}>
                                { streamLoader ? <i className="fa fa-spinner fa-spin mr-2"/> : '' }  Save
                              </button>
                              <button style={{margin: "auto",display: "inline-block"}} onClick={addMoreText} className="btn-submit">Add More</button>
                            </form>
                            :
                            stream.type === "formatted" || updateStream.type ==="formatted" ?
                                <>
                                  {
                                    formattedText.map((item,index)=>{
                                      return(
                                          <div key={index} id={`hide-format-${index}`}>
                                            {/*<CKEditor*/}
                                            {/*    initData={item.value}*/}
                                            {/*    onChange={(evt) => onInputEditor(evt,index)}*/}
                                            {/*/>*/}
                                            <Editor
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onContentStateChange={(e) => onInputEditor(e,index)}
                                            />
                                            <div key={index} className="text-right">
                                              <a onClick={(e)=>removeFormatted(e,index)} href="#"><i className="fas fa-trash"></i></a>
                                            </div>
                                          </div>
                                      )
                                    })
                                  }
                                  <button type="submit" style={{margin: "auto",display: "inline-block", marginRight: "10px"}}
                                          className="btn-submit" onClick={saveTextFormatData} disabled={streamLoader}>
                                    { streamLoader ? <i className="fa fa-spinner fa-spin mr-2"/> : '' }  Save
                                  </button>
                                  <button style={{margin: "auto",display: "inline-block"}} onClick={addMoreFormatted} className="btn-submit">Add More</button>
                                </>
                                 : <>
                                  <div className="drag-img">
                                    <img src={dragdrop} alt className="img-fluid" />
                                  </div>
                                  <div className="uplaod-size-img">
                                    {
                                      stream.type === 'image' && checkStream === true ?
                                        checkStream === true && updateStream.type === 'image' ? <h6>JPG , PNG upto 5MB is allowed</h6> : ''
                                      : ''
                                    }
                                    {
                                      stream.type === 'image' && checkStream === false ?
                                          <h6>JPG , PNG upto 5MB is allowed</h6>
                                          : ''
                                    }
                                    {
                                      stream.type === 'video' || updateStream.type === 'video'  ? <h6>mp4  upto 20MB  allowed</h6> : ''
                                    }
                                  </div>
                                  <div className="drag-btn">
                                    {
                                      checkStream ?
                                          <>
                                            <a href="#" className={`button ${checkStream ? '' : 'opacity-button'}`}>Upload</a>
                                            <input type="file" name="file" className=" button file-upload" multiple
                                                   onChange={(e)=>onFileInput(e,updateStream.type)}
                                                   accept={`${checkStream ? updateStream.type : stream.type}/*`}
                                            />
                                          </>
                                          :
                                          <>
                                            <a onClick={(e)=>showEmptyMessage(e)} href="#" className={`button ${checkStream ? '' : 'opacity-button'}`}>Upload</a>
                                          </>
                                    }
                                  </div>
                                </>
                      }
                    </div>
                    <div className="drop-content mt-3 text-left">
                      {
                        uploadStatus.percentage > 0 ?
                            <>
                              <h6>Uploading {uploadImageCount} Files</h6>
                              <div className="row mt-3">
                                <div className="col-md-2 d-flex align-items-center justify-content-center">
                                  <img src={img} alt />
                                </div>
                                <div className="col-md-10">
                                  <h6>{uploadStatus.file_name}</h6>
                                  <div className="progress">
                                    <div className={`progress-bar ${uploadStatus.percentage < 100 ? 'bg-info' : 'bg-success'} `} role="progressbar" style={{width: uploadStatus.percentage+'%'}} aria-valuenow={uploadStatus.percentage} aria-valuemin={0} aria-valuemax={100} />
                                  </div>
                                  <h6> {uploadStatus.percentage}% uploaded </h6>
                                </div>
                              </div>
                            </>
                          : ''
                      }

                      <DragDropContext onDragEnd={onSetRecorder}>
                        <Droppable droppableId="droppable">
                          {(provided) => (
                              <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                              >
                                {successFile.files.map((item, index) => (
                                    <Draggable key={item.id} draggableId={`id_${item.id}`} index={index}>
                                      {(provided) => (
                                          <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                          >
                                            <div className="row mt-3">
                                              <div className="col-md-2 d-flex align-items-center justify-content-center">
                                                <img src={item.thumbnail} alt />
                                              </div>
                                              <div className="col-md-10">
                                                <div className="progress">
                                                  <div className={`progress-bar bg-success`} role="progressbar" style={{width: '100%'}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                                                </div>
                                                <h6> Uploaded Successfully </h6>
                                              </div>
                                            </div>
                                          </div>
                                      )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                          )}
                        </Droppable>
                      </DragDropContext>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <SweetAlert
              success
              showCancel
              showCloseButton
              confirmBtnText="Continue"
              cancelBtnText="Later"
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="light"
              title="Stream Created Successfully"
              onConfirm={(e)=>onConfirmCustom(e)}
              onCancel={(e)=>onCancelCustom(e)}
              show={openCustomSweet}
          >
            Do you want to upload file to it ?
          </SweetAlert>
   
        </>
    )
}

export default Cstreamfrom;

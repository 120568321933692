import React from 'react'
import DeshboardBanner from './SubComponent/DeshboardBanner';
import DeshboardContent from './SubComponent/DeshboardContent';
import DeshJoin from './SubComponent/DeshJoin';
import Footer from './SubComponent/Footer';
import Navbar from './SubComponent/Navbar';
import {Helmet} from "react-helmet";
import BannerPart from './SubComponent/BannerPart';
import BannerLinebar from './SubComponent/BannerLinebar';
function Deshboard() {


    
  


    return (
        <>
            <Helmet>
                <title>AgencyReel | Dashboard </title>
            </Helmet>
          <div className="wrapper-box">
           <Navbar/> 
           <BannerLinebar/>
           <DeshboardBanner/>
           <DeshboardContent/>
           <BannerPart/>
           <DeshJoin/>



           </div>
        </>
    )
}

export default Deshboard

import React from 'react'
import Footer from './SubComponent/Footer'
import Navbar from './SubComponent/Navbar'
import Training2banner from './SubComponent/Training2banner'
import {Helmet} from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";

function Training2({location}) {
    return (
        <>
            <Helmet>
                <title>AgencyReel | Training </title>
            </Helmet>
          <div className="wrapper-box">
           <Navbar/>
              <BannerLinebar/>
           <Training2banner location={location}/>
           {/*<Footer/>*/}
           </div>
        </>
    )
}

export default Training2

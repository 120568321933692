import React, {useEffect, useState} from 'react'
import CustomizeVideo from "./CustomizeVideo";
import LogoWatermarkVideo from "./LogoWatermarkVideo";
import AudioVideo from "./AudioVideo";
import PreviewVideo from "./PreviewVideo";
import TilesVideo from "./TilesVideo";
import {useDispatch, useSelector} from "react-redux";
import {resetLayerData, updateAllLayerData} from "../../store/actions/videoAction";
import queryString from "query-string";

function CreateVideotab({location}) {

    const dispatch = useDispatch();
    const contentsData = useSelector(state => state.content);

    useEffect(()=>{
        dispatch(resetLayerData());

    },[]);

    useEffect(()=>{
        if(queryString.parse(location.search).id !== undefined){
            let us = contentsData.videos.filter(cont => cont.id === queryString.parse(location.search).id);

            dispatch(updateAllLayerData(JSON.parse(us[0].all_data), queryString.parse(location.search).id));

        }
    },[]);

    useEffect(()=>{
        if(queryString.parse(location.search).id === undefined){
            dispatch(resetLayerData());
        }
    },[location])

    return (
        <>
           <section className="create-video-sec">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 text-center  col-md-12">
        <div className="create_video_content">
         <ul className="nav nav-pills d-flex justify-content-center mb-3" id="pills-tab" role="tablist">
            <li className="nav-item">
              <a className="nav-link active"  tabIndex="0" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Customize</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" tabIndex="0" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Logo &amp; Watermark</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" tabIndex="0" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Audio</a>
            </li>
          </ul>
          <div className="row">
           <div className="col-md-12 topsize col-lg-7">
          <div className="tab-content" id="pills-tabContent">
            <div  className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <CustomizeVideo />
              </div>
            
            <div  className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <LogoWatermarkVideo/>
            </div>
            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
              <AudioVideo/>
            </div>
           

          </div>
          </div>

              <PreviewVideo location={location}/>

          </div>


            <TilesVideo/>

        </div> 
      </div>
    </div>       
  </div>
</section>
 
        </>
    )
}

export default CreateVideotab

import React, {useEffect, useState} from 'react'

import Navbar from './SubComponent/Navbar'
import ReportClients from "./SubComponent/ReportClients";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import BannerLinebar from "./SubComponent/BannerLinebar";

function Reports() {

    const auth = useSelector(state => state.auth);
    const history = useHistory();

    if(auth.user.membership !== null){
        let memberships = auth.user.membership.split("__");
        if(memberships.includes('enterprise') || memberships.includes('enterpriselite')){

        }else{
            history.push('/dashboard');
        }
    }

    return (
        <>
            <Helmet>
                <title>AgencyReel | Reports </title>
            </Helmet>
            <div className="wrapper-box">
                <Navbar/>
                <BannerLinebar/>
                <ReportClients/>
            </div>
        </>
    )
}

export default Reports;

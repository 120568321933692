import React, {useEffect, useState} from 'react'
import TeamMemberAdd from "./TeamMemberAdd";
import {useSelector} from "react-redux";

function TeamMs() {

    const auth = useSelector(state => state.auth);
    const [teams, setTeams] = useState(auth.teams);

    useEffect(()=>{
        setTeams(auth.teams);
    },[auth.teams]);

    return (
        <>
           <section className="team-member-sec">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="personal-heading">
                      <h5>Team Member Management</h5>
                    </div>
                  </div>
                </div>
                <div className="row mt-2 d-flex justify-content-center">
                  <div className="col-md-12 col-lg-12">
                    <div className="personal-box">
                        <section className="user-data" style={{padding: "0px"}}>
                            <div className="container" style={{padding: "0px"}}>
                                <div className="row">
                                    <div className="col-md-12 mx-auto">
                                        <table className="table table-border">
                                            <thead className="theadbgcolor text-white">
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email Address</th>
                                                <th scope="col">Account Type</th>
                                                <th scope="col">Created</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody className="tbody-bg">
                                            {
                                                teams.length > 0 ?

                                                    teams.map((client)=>{
                                                        return(
                                                            <TeamMemberAdd
                                                                key={client.id}
                                                                client={client}
                                                                value="Team Member"
                                                            />
                                                        )
                                                    })

                                                    :
                                                    (
                                                        <tr>
                                                            <td colSpan="5" align="center">No Records Found</td>
                                                        </tr>
                                                    )
                                            }


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </div>
                  </div>
                </div>
              </div>
            </section>
  
        </>
    )
}

export default TeamMs;

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../store/actions/alert";
import swal from "sweetalert";
import {addRebrandData, removeRebrandData} from "../../store/actions/authAction";
import SweetAlert from "react-bootstrap-sweetalert";

const Rebranding = () => {

    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [fileData, setFileData] = useState('');
    const [name, setName] = useState('');
    const [rebrand, setRebrand] = useState(rebrandData);

    // Sweetalert
    const [button, setButton] = useState('Delete');
    const [deleteData, setDeletedData] = useState({
        id: ''
    });
    const [showDelete, setShowDelete] = useState(false);

    const onConfirm = async () => {
        setButton('Deleting...')
        let options = {
            headers: {
                'Authorization': auth.token
            }
        }

        await axios.post(`${baseURL}delete-wl`,deleteData,options).then(res=>{
            if(res.data.status === true){
                dispatch(setAlert(res.data.message,'success'));
                dispatch(removeRebrandData(auth.token))
            }else{
                dispatch(setAlert(res.data.message,'danger'));
            }
            setLoader(false);
        }).catch(err=>{
            setLoader(false);
        })

        setShowDelete(false);
        setButton('Delete')
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const removeWl = (id) => {
        setDeletedData({...deleteData, id: id});
        setShowDelete(true);
    }

    const onInputChange = (e) => {
        setFileData(e.target.files[0]);
    }

    const onInputName = (e) => {
        setName(e.target.value);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        if (fileData.type === 'image/jpg' || fileData.type === 'image/png' || fileData.type === 'image/jpeg' || fileData === 'image/JPEG')
        {
            if (fileData.size < 5000000){
                setLoader(true);

                let formData = new FormData();
                formData.append('name',name);
                formData.append('logo',fileData);

                let options = {
                    headers: {
                        'Authorization': auth.token
                    }
                }

                axios.post(`${baseURL}wl-rebranding`,formData,options).then(res=>{
                    if(res.data.status === true){
                        dispatch(setAlert(res.data.message,'success'));
                        dispatch(addRebrandData(auth.token))
                    }else{
                        dispatch(setAlert(res.data.message,'danger'));
                    }
                    setLoader(false);
                }).catch(err=>{
                    setLoader(false);
                })
            }
            else{
                swal("Oops!","Max Uploaded Size for Image 5MB");
            }
        }
        else{
            swal("Oops!","You have Selected Invalid File Type");
        }

    }

    useEffect(()=>{
        setRebrand(rebrandData);
    },[rebrandData])

    return(
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="sc-bdnxRM jvCTkj">
                        <div className="contract-assets">
                            <section id="contract-assets-content">
                                <div className="container-fluid">
                                    <div
                                        className="row justify-content-center align-items-between fb-contract mt-5">
                                        <div className="col-10 mb-5">
                                            <form className="row bg-table justify-content-center align-items-center"
                                                  method="post"
                                                  onSubmit={(e)=>onFormSubmit(e)}
                                            >
                                                <div className="col-11 p-4" style={{height: "20vh", width: "100%", backgroundColor: "rgb(54, 56, 57)", border: "2px dashed white"}}>
                                                    <div
                                                        style={{
                                                            height: "100%",
                                                            width: "100%",
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        <div
                                                            className="upload-btn-wrapper">
                                                            <div
                                                                className="d-flex justify-content-center align-items-center flex-column">
                                                                <button
                                                                    style={{
                                                                        backgroundColor: "rgb(54, 56, 57)",
                                                                        color: "white",
                                                                        border: "none",
                                                                        fontSize: "1rem"
                                                                    }}
                                                                >
                                                                    Upload your brand
                                                                    logo (JPG, PNG, JPEG
                                                                    upto 5MB is
                                                                    allowed)
                                                                    (210x34) Pixels recommended
 

                                                                </button>
                                                                <i
                                                                    className="fa fa-cloud-upload"
                                                                    aria-hidden="true"
                                                                    style={{
                                                                        display: "block",
                                                                        color: "white",
                                                                        fontSize: "1.7rem",
                                                                        paddingTop: 10
                                                                    }}
                                                                />
                                                            </div>
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="customFile"
                                                                name="file"
                                                                required
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    padding: 0,
                                                                    cursor: "pointer"
                                                                }}
                                                                onChange={(e)=>onInputChange(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col col-lg-6 col-12 mt-4 p-1">
                                                    <div
                                                        className="form-group form-group-mb">
                                                        <label className="input-label">Brand
                                                            Name </label>
                                                        <input
                                                            className="form-control input-dark-accnt personal-info  w-100"
                                                            type="text"
                                                            required
                                                            name="firstName"
                                                            placeholder="Enter your brand Name"
                                                            onChange={(e)=>onInputName(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="col col-md-12 text-center mt-3">
                                                    <button
                                                        type="submit"
                                                        className="brandingBtn btn-3  accnt-mngmt-btn button mt-4"
                                                        disabled={loader}
                                                        style={{
                                                            width: "auto",
                                                            color: "#fff",
                                                            backgroundColor: "#16d489",
                                                            border: "none"
                                                        }}
                                                    >
                                                        {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Save
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    {
                                        rebrand.data !== false ?
                                            <button style={{display: "block", margin: "auto"}} type="button" onClick={(e)=>removeWl(rebrand.data.id)} className="btn-submit mb-3">Remove Rebranding</button>
                                            :''
                                    }

                                </div>
                            </section>

                        </div>
                    </div>
                </div>

            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e)=>onConfirm(e)}
                onCancel={(e)=>onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete this Rebranding
            </SweetAlert>

        </>
    )
}

export default Rebranding;
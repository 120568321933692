import React from 'react'
import {Link} from "react-router-dom";
import nextbtn from '../../images/NEXT BUTTON.png'

function Dsubcount(props) {
    return (
        <>
            <Link to={props.link}  style={{textDecoration: "none"}}>
                <div className={`client-arrow ${props.type} mb-4 button d-flex text-white  justify-content-between align-items-center`}>
                <div className="num">
                  <h6 className="m-0"><span>{props.num}</span> {props.name} </h6>
                </div>
                    {
                        props.name !== 'TOTAL EARNED' ?
                            <div className="arrow">
                                <span><img src={nextbtn} alt /></span>
                            </div>
                        : ''
                    }
              </div>
            </Link>
        </>
    )
}

export default Dsubcount

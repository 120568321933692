import React, {useEffect, useState} from "react";
import play from "../../images/play.png";
import {baseURL} from "../../global/global";
import axios from "axios";
import imgDefault from  "../../images/default_img.png";
import {useDispatch, useSelector} from "react-redux";
import {updateImage, updateThumbnail} from "../../store/actions/videoAction";
import queryString from "query-string";
import hexRgb from "hex-rgb";


const PreviewVideo = ({location}) => {

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const textStateData = useSelector(state => state.videoReducer);

    const keywordArray = ['nature','car','animal','water','birds','human','space','kids','water','fitness','dog','cat','food'];

    const [keyword, setKeyword] = useState(keywordArray[Math.floor(Math.random() * keywordArray.length)]);
    const [initImage, setInitImage] = useState({
        data: []
    });

    const fetchMedia = async ( ) => {
        await axios({
            method: 'POST',
            url : `${baseURL}search-media`,
            data: JSON.stringify({search_type: "image", keyword : keyword, page: 1}),
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res =>{
            console.log(res);

            let randomImg = res.data.data[Math.floor(Math.random() * keywordArray.length)];

            setInitImage({...initImage, data: res.data.data[0]});

            dispatch(updateImage(0,randomImg.image));
            dispatch(updateThumbnail(randomImg.image));

        }).catch(error=>{
            console.log(error);
        })
    }

    // Search Selected Layer
    let selectedLayer = textStateData.tiles.find(({is_selected}) => is_selected === "true");

    useEffect(()=>{
        console.log(keywordArray[Math.floor(Math.random() * keywordArray.length)]);
        if(queryString.parse(location.search).id === undefined) {

            fetchMedia().then(r => console.log(r));
        }
    },[location]);


    return(
        <>
            <div className="col-md-12 col-lg-5 mt-5">
                <div className="join-vidoes">
                    <div className="play-icon">
                        {
                            initImage.data.length > 0 ?
                                <img src={play} className="img-fluid" alt="default" />
                            :
                                <img src={selectedLayer.poster !== '' ? selectedLayer.poster : imgDefault} className={`img-fluid ${selectedLayer.blur === true ? 'blur-image' : ''}`} alt="preview" />
                        }
                         
                         <div className={`text-left-video ${selectedLayer.text_position}`}>
                        <span 
                           style={{
                               marginBottom: 0,
                               color: selectedLayer.text_color,
                               fontSize: selectedLayer.text_size+'px',
                               backgroundColor: selectedLayer.bg_color !== "" ? `rgba(${hexRgb(selectedLayer.bg_color).red},${hexRgb(selectedLayer.bg_color).green},${hexRgb(selectedLayer.bg_color).blue},${selectedLayer.opacity})` : selectedLayer.bg_color,
                               fontFamily: '"'+selectedLayer.font_name+'"',
                               lineHeight: 1.9,
                               padding:"4px 10px",
                               WebkitBoxDecorationBreak:'clone',
                                
                           }}>
                            {selectedLayer.text}
                        </span>
                        </div>
                     
                        {
                            textStateData.logo === false ? '' :
                                <div className={`logo-${textStateData.logo.position}`}>
                                    {
                                        textStateData.logo === false ? ''
                                            : <img src={textStateData.logo.url} width="100px" alt="logo" />
                                    }
                                </div>
                        }
                        <div className={`logo-${textStateData.watermark.position}`}>
                            {
                                textStateData.watermark === false ? ''
                                    : <img src={textStateData.watermark.url} width="100px" alt="logo" />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PreviewVideo;
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {deleteClientAccount, editCaVaAccount} from "../../store/actions/authAction";
import SweetAlert from "react-bootstrap-sweetalert";

const TeamMemberAdd = ({client,value}) => {

    const dispatch = useDispatch();

    const [showDelete, setShowDelete] = useState(false);
    const [button , setButton] = useState('Delete');
    const [deleteId, setDeleteId] = useState({
        client_id: null
    });

    const [loader, setLoader] = useState(false);
    const [clientDetails, setClientDetails] = useState({
        name: client.name,
        password: "",
        id: ""
    });

    const onFormSubmit = (e,id) => {
        e.preventDefault();
        setLoader(true);
        dispatch(editCaVaAccount(clientDetails,id,setLoader,value));
    }

    const onInputChange = (e) =>{
        setClientDetails({...clientDetails, [e.target.name]: e.target.value});
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const onConfirm = () => {
        setButton('Please wait...');

        dispatch(deleteClientAccount(deleteId,setShowDelete));
    }

    const onButtonClick = (e,id) => {
        e.preventDefault();

        setDeleteId({client_id: id});
        setShowDelete(true);
    }

    return(
        <>
            <tr className="accordion-toggle collapsed" id={`accordion${client.id}`} key={client.id}>
                <td  className="td-br"><h6 title={client.name} >{client.name}</h6></td>
                <td className="text-overflow td-br"><h6 title={client.email}>{client.email}</h6></td>
                <td className="td-br">{value} Account</td>
                <td className="td-br">{client.created}</td>
                <td className="expand-button">
                    <a className="action-icon delete-icon remove mb-1  mt-md-1 collapsed"
                       title="Edit" data-toggle="collapse" data-parent={`accordion${client.id}`}
                       href={`#client_${client.id}`} aria-expanded="false">
                        <i className="fas fa-edit"></i>
                    </a>

                    <a className="action-icon delete-icon remove mb-1 pl-xl-2  mt-md-1" style={{cursor: "pointer", color:"#f05359"}}
                       onClick={(e)=>onButtonClick(e,client.id)} to="delete">
                        <i className="fas fa-trash-alt"></i>
                    </a>
                </td>
            </tr>

            <tr className="hide-table-padding">
                <td colSpan="12" style={{padding: "0px"}}>
                    <div id={`client_${client.id}`} className="p-3 collapse in"
                         style={{width: "100%"}}>
                        <div className="col-12">
                            <p className="text-white"></p>
                            <div className="mt-2 mb-0">
                                <h6 className=" text-left mb-4 mt-3"
                                    style={{marginLeft: "-10px"}}>Edit {value}</h6>
                                <div>
                                    <form method="post" onSubmit={(e)=>onFormSubmit(e,client.id)}>
                                        <div
                                            className="row d-flex justify-content-center">
                                            <div className="col col-lg-6 col-12 p-1 text-left">
                                                <div
                                                    className="form-group form-group-mb">
                                                    <label className="input-label-pos">Name</label>
                                                    <input className="form-control input-dark-accnt personal-info w-100"
                                                           type="text" name="name" defaultValue={client.name}
                                                           onChange={(e)=>onInputChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 p-1 text-left">
                                                <div className="form-group form-group-mb">
                                                    <label className="input-label-pos">
                                                        Email Address
                                                    </label>
                                                    <input className="form-control input-dark-accnt personal-info"
                                                           type="email" name="email" placeholder="Email Address"
                                                           readOnly value={client.email} />
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 p-1 text-left">
                                                <div className="form-group form-group-mb">
                                                    <label className="input-label-pos">
                                                        Password <small>(leave blank for same password)</small>
                                                    </label>
                                                    <input className="form-control input-dark-accnt personal-info"
                                                           type="password" name="password" placeholder=""
                                                           defaultValue={''} onChange={(e)=>onInputChange(e)} />
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 p-1 text-left">
                                                <div
                                                    className="form-group form-group-mb">
                                                    <label
                                                        className="input-label-pos">Account
                                                        Type
                                                    </label>
                                                    <input className="form-control input-dark-accnt personal-info"
                                                    type="text" name="Account Type" placeholder="Account Type"
                                                    readOnly value={value} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-md-12 cancel-update text-center mt-5 mb-5">
                                            <a style={{display: "inline-block", marginRight: "10px"}} type="button" className="btn-submit"
                                                    data-toggle="collapse" data-parent={`accordion${client.id}`}
                                                    href={`#client_${client.id}`} aria-expanded="false"
                                            >Cancel</a>
                                            <button style={{display: "inline-block",textTransform: "capitalize"}} type="submit" className="button" disabled={loader}>
                                                { loader ? <i className="fa fa-spinner fa-spin mr-2"/> : '' }Update
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </td>

            </tr>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e)=>onConfirm(e)}
                onCancel={(e)=>onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete this {value} account
            </SweetAlert>

        </>
    )

}


export default TeamMemberAdd;
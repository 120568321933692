import React from 'react'
import Footer from './SubComponent/Footer'
import Navbar from './SubComponent/Navbar'
import TrainingBanner from './SubComponent/TrainingBanner'
import TrainingImp from './SubComponent/TrainingImp'
import TrainingVideo from './SubComponent/TrainingVideo'
import {Helmet} from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";

function Training() {
    return (
        <>
            <Helmet>
                <title>AgencyReel | Help & Support </title>
            </Helmet>
          <div className="wrapper-box">
            <Navbar/>
              <BannerLinebar/>
            {/*<TrainingBanner/>*/}
            <TrainingImp/>
            <TrainingVideo/>
            </div>
        </>
    )
}

export default Training

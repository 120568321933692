import React from 'react'
import Footer from './SubComponent/Footer'
import Navbar from './SubComponent/Navbar'
import NotificationCom from './SubComponent/NotificationCom'
import {Helmet} from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";

function Notification() {
    return (
        <>
            <Helmet>
                <title>AgencyReel | Notification </title>
            </Helmet>
          <Navbar/>
            <BannerLinebar/>
          <NotificationCom/> 
          <Footer/>
        </>
    )
}

export default Notification

import React, {useState} from "react";
import downloads from "../../images/downloads.png";
import axios from "axios";
import {baseURL} from "../../global/global";
import {useDispatch, useSelector} from "react-redux";
import {removeLogoWatermark, updateImage, updateLogoWatermark} from "../../store/actions/videoAction";
import {setAlert} from "../../store/actions/alert";
import Alert from "../alert/alert";
import swal from "sweetalert";


const LogoWatermarkVideo = () => {

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const textStateData = useSelector(state => state.videoReducer);

    const [loader, setLoader] = useState(false);
    const [uploadLogo, setUploadLogo] = useState({data: []});
    const [uploadWatermark, setUploadWatermark] = useState({data: []});
    const [removeLogo, setRemoveLogo] = useState(1);
    const [removeWatermark, setRemoveWatermark] = useState(1);

    const [logoStatus , setLogoStatus] = useState({
        position: 'top-left',
        url: ''
    });

    const [watermarkStatus, setWatermarkStatus] = useState({
        position: 'bottom-right',
        url: ''
    })

    /* Get Selected Index */
    let selectedIndex = textStateData.tiles.findIndex(({is_selected}) => is_selected === "true");

    const onLogoUpload = (e) => {
        fileUpload(e.target.files[0],'logo');
    }

    const onWatermarkUpload = (e) => {
        fileUpload(e.target.files[0],'watermark');
    }

    const handleRemoveLogo = (e) => {
        console.log(removeLogo);
        if(removeLogo === 1){
            setRemoveLogo(1);
            dispatch(removeLogoWatermark('REMOVE_LOGO_LAYER'))
        }else{
            setRemoveLogo(0);
        }
    }

    const handleRemoveWatermark = (e) => {
        console.log(removeWatermark);
        if(removeWatermark === 1){
            setRemoveWatermark(1);
            dispatch(removeLogoWatermark('REMOVE_WATERMARK_LAYER'))
        }else{
            setRemoveWatermark(0);
        }
    }

    const logoWaterPos = (position,type) => {
        let fileType = 'UPDATE_LOGO_POSITION';
        if(type === 'watermark'){
            fileType = 'UPDATE_WATERMARK_POSITION';
        }

        dispatch(updateLogoWatermark(fileType,position));
    }

    const fileUpload = (file,type) => {

        if(file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {

            if(file.size < 5000000){
                setLoader(true);
                let formData = new FormData();
                formData.append('file', file);
                formData.append('file_type', type);

                axios({
                    method: 'POST',
                    url: `${baseURL}upload-file`,
                    data: formData,
                    headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
                }).then(res => {
                    console.log(res.data);
                    if (res.data.status === true) {
                        if (type === "logo") {

                            setUploadLogo({...uploadLogo, data: uploadLogo.data.concat(res.data.data)});
                        } else if (type === "watermark") {

                            setUploadWatermark({...uploadWatermark, data: uploadWatermark.data.concat(res.data.data)});
                        }
                        selectImage(res.data.data,type);

                    } else {
                        dispatch(setAlert(res.data.message, 'danger'));
                    }
                    setLoader(false);

                }).catch(error => {
                    console.log(error);
                    setLoader(false);
                })
            }else{
                swal("Oops!","Max allowed size 5MB");
            }
        }else{
            swal("Oops!","You have Selected Invalid File Type");
        }
    }

    const selectImage = (url,type) => {
        let data = {
            status: true,
            url: url,
            position: type === 'logo' ? logoStatus.position : watermarkStatus.position
        }

        console.log(data);

        let uploadType = 'ADD_LOGO';
        if(type === "watermark"){
            uploadType = 'ADD_WATERMARK';
        }

        dispatch(updateLogoWatermark(uploadType,data));
    }

    return(
        <>
            <Alert/>
            <div className="create-video-box mt-5">
                <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <a className="nav-link active button" id="v-pills-logo-tab" data-toggle="pill" href="#v-pills-logo" role="tab" aria-controls="v-pills-logo" aria-selected="true">Logo</a>
                                    <a className="nav-link button" id="v-pills-watermark-tab" data-toggle="pill" href="#v-pills-watermark" role="tab" aria-controls="v-pills-watermark" aria-selected="false"> Watermark</a>
                                    {/*<a className="nav-link button" id="v-pills-uploadbtn-tab" data-toggle="pill" href="#v-pills-uploadbtn" role="tab" aria-controls="v-pills-uploadbtn" aria-selected="false">Upload</a>*/}
                                </div>
                            </div>
                            <div className="col-md-8  linebar ">
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-logo" role="tabpanel" aria-labelledby="v-pills-logo-tab">
                                        <div className="logo-box">
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="boxline">
                                                        <div className="box-upload p-3 ml-3">
                                                            <img src={downloads} alt="upload-icon"/>
                                                            <div className="custom-file">
                                                                <input type="file" className="custom-file-input" onChange={(e)=>onLogoUpload(e)} accept="image/*"  />
                                                                <label className="custom-file-label" >
                                                                    Choose file
                                                                </label>
                                                            </div>
                                                            <p className="m-0 mt-2 upload-font">JPG .PNG up to 5MB is allowed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 pl-lg-0">
                                                    <div className="logo-position">
                                                        <p className="mr-lg-3" >logo position</p>
                                                        <div className="box-wrapper mt-2">
                                                            <div onClick={(e)=>logoWaterPos('top-left','logo')}  className={`box top_left ${textStateData.logo !== false ? textStateData.logo.position === 'top-left' ? 'text-pos-active' : '' : '' }`}>
                                                                <span />
                                                            </div>
                                                            <div onClick={(e)=>logoWaterPos('top-right','logo')} className={`box top_right ${textStateData.logo !== false ? textStateData.logo.position === 'top-right' ? 'text-pos-active' : '' : '' }`}>
                                                                <span />
                                                            </div>
                                                            <div onClick={(e)=>logoWaterPos('bottom-left','logo')} className={`box bottom_left ${textStateData.logo !== false ? textStateData.logo.position === 'bottom-left' ? 'text-pos-active' : '' : '' }`}>
                                                                <span />
                                                            </div>
                                                            <div onClick={(e)=>logoWaterPos('bottom-right','logo')} className={`box bottom_right ${textStateData.logo !== false ? textStateData.logo.position === 'bottom-right' ? 'text-pos-active' : '' : '' }`}>
                                                                <span />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-5">
                                                <div className="col-md-12">
                                                    <div className="remove-box ml-3">
                                                        <div className="form-check text-left">
                                                            <input type="checkbox" value={removeLogo}
                                                                   onChange={(e)=>handleRemoveLogo(e)}
                                                                   className="form-check-input" id="exampleCheck1"
                                                                   checked={textStateData.logo === false ? false : true}
                                                                   disabled={textStateData.logo === false ? true : false}
                                                            />
                                                            <label className="form-check-label" htmlFor="exampleCheck1">
                                                                Remove
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-3 d-flex justify-content-center ">
                                                {
                                                    uploadLogo.data.length > 0 ?
                                                        uploadLogo.data.map((item,index)=>{
                                                            return(
                                                                <div className="col-md-3 p-1 m-1  logowatermark-icon  "   style={{objectFit:"cover" , position:"relative"  ,  backgroundColor:"#a2a0a008", cursor:"pointer" ,width:"8vw"  ,overflow:"hidden" }} key={index}>
                                                                   <h6 className="logomark-img-size" ><img src={item} width="100px"  onClick={(e)=>selectImage(item,'logo')} alt="logo" /></h6>
                                                                   {/*<span  className="delete-icon" style={{color:"red" , right:"3px", top:"0",  position:"absolute"}}><i className="far fa-trash-alt"></i></span>*/}
                                                                </div>
                                                            )
                                                        })
                                                    : ''
                                                }
                                                {
                                                    loader === true ? <h6 style= {{width: "100%", textAlign:"center"  }}> <i style={{fontSize: "40px", color: "#16d489"}} className="fa fa-spinner fa-spin mr-2"/></h6> : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-watermark" role="tabpanel" aria-labelledby="v-pills-watermark-tab">

                                        <div className="logo-box">
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="boxline">
                                                        <div className="box-upload p-3 ml-3">
                                                            <img src={downloads} alt="upload-icon"/>
                                                            <div className="custom-file">
                                                                <input type="file" className="custom-file-input"  onChange={(e)=>onWatermarkUpload(e)} accept="image/*" />
                                                                <label className="custom-file-label" >
                                                                    Choose file
                                                                </label>
                                                            </div>
                                                            <p className="m-0 mt-2 upload-font">JPG .PNG up to 5MB is allowed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="logo-position">
                                                        <p>watermark position</p>
                                                        <div className="box-wrapper mt-2">
                                                            <div onClick={(e)=>logoWaterPos('top-left','watermark')} className={`box top_left active ${textStateData.watermark !== false ? textStateData.watermark.position === 'top-left' ? 'text-pos-active' : '' : '' }`}>
                                                                <span />
                                                            </div>
                                                            <div onClick={(e)=>logoWaterPos('top-right','watermark')} className={`box top_right ${textStateData.watermark !== false ? textStateData.watermark.position === 'top-right' ? 'text-pos-active' : '' : '' }`}>
                                                                <span />
                                                            </div>
                                                            <div onClick={(e)=>logoWaterPos('bottom-left','watermark')} className={`box bottom_left ${textStateData.watermark !== false ? textStateData.watermark.position === 'bottom-left' ? 'text-pos-active' : '' : '' }`}>
                                                                <span />
                                                            </div>
                                                            <div onClick={(e)=>logoWaterPos('bottom-right','watermark')} className={`box bottom_right ${textStateData.watermark !== false ? textStateData.watermark.position === 'bottom-right' ? 'text-pos-active' : '' : '' }`}>
                                                                <span />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-5">
                                                <div className="col-md-12">
                                                    <div className="remove-box ml-3">
                                                        <div className="form-check text-left">
                                                            <input type="checkbox" value={removeWatermark} onClick={(e)=>handleRemoveWatermark(e)}
                                                                   className="form-check-input" id="exampleCheck1"
                                                                   checked={textStateData.watermark === false ? false : true}
                                                                   disabled={textStateData.watermark === false ? true : false}
                                                            />
                                                            <label className="form-check-label" htmlFor="exampleCheck1">
                                                                Remove
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-3 d-flex justify-content-center">
                                                {
                                                    uploadWatermark.data.length > 0 ?
                                                        uploadWatermark.data.map((item,index)=>{
                                                            return(
                                                                <div className="col-md-3 m-1  water-mark-delete-icon p-1" key={index} style={{objectFit:"cover" , cursor:"pointer" ,width:"8vw" ,  backgroundColor:"#a2a0a008" ,overflow:"hidden" }} >
                                                                  <h6 className="water-mark-size"><img src={item} width="100px" style={{opacity:"0.8"}}  onClick={(e)=>selectImage(item,'watermark')} alt="watermark" /></h6>
                                                                  {/*<span  className="delete-icon" style={{color:"red" , right:"3px", top:"0",  position:"absolute"}}><i className="far fa-trash-alt"></i></span>*/}
                                                                </div>
                                                            )
                                                        })
                                                        : ''
                                                }
                                                {
                                                    loader === true ? <h6 style={{width:"100%" , textAlign:"center" }}><i style={{fontSize: "40px", color: "#16d489"}} className="fa fa-spinner fa-spin mr-2"/></h6> : ''
                                                }
                                            </div>

                                        </div>

                                    </div>

                                    <div className="tab-pane fade" id="v-pills-uploadbtn" role="tabpanel" aria-labelledby="v-pills-uploadbtn-tab">
                                        <div className="upload-box-video">
                                            <div className="row">
                                                <div className="col-md-8 mx-auto p-3 upload-border">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" id="inputGroupFile01" />
                                                        <label className="custom-file-label" htmlFor="inputGroupFile01">
                                                            Choose file
                                                        </label>
                                                    </div>

                                                    <span><img src={downloads} alt="download"  /></span>
                                                    <p className="mt-3">click To upload file</p>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-12">
                                                    <div className="img-box">
                                                        <ul className="d-flex list-inline flex-wrap justify-content-center ">
                                                            <li><img src="" alt=""/></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>

            </div>
        </>
    )
}

export default LogoWatermarkVideo;
const initialState = {
    token: false,
    loader : false,
    loginText: 'Login',
    isAuthenticated: false,
    user: false,
    clients: [],
    teams: [],
    appClients: []
}

export default function (state = initialState,action){
    switch (action.type) {
        case 'LOGIN_USER':
            return {
                ...state,
                isAuthenticated: true,
                loader: false,
                loginText: 'Login',
                token: action.payload.token,
                user: action.payload.user
            }
        case 'LOGOUT':
            localStorage.clear();
            return {
                ...state,
                isAuthenticated: false,
                loader: false,
                token: false,
                user: false
            }
        case 'CHANGE_USER_NAME':
            state.user.name = action.action;
            return {
                ...state
            }
        case 'CHANGE_USER_EMAIL':
            state.user.email = action.action;
            return {
                ...state
            }
        case 'CHANGE_USER_IMAGE':
            state.user.image = action.payload.path;
            return {
                ...state
            }
        case 'USER_CLIENT_ACCOUNTS':
            return{
                ...state,
                clients: action.payload
            }
        case 'USER_TEAM_ACCOUNTS':
            return{
                ...state,
                teams: action.payload
            }
        case 'CHANGE_CLIENT_DETAILS':
            let client_index = state.clients.findIndex(({id}) => id === action.payload.id);
            state.clients[client_index].name = action.payload.name;
            return {
                ...state
            }
        case 'CHANGE_TEAM_DETAILS':
            let team_index = state.teams.findIndex(({id}) => id === action.payload.id);
            state.teams[team_index].name = action.payload.name;
            return {
                ...state
        }
        case 'USER_APP_CLIENT':
            return{
                ...state,
                appClients: action.payload
            }
        case 'CHANGE_APP_CLIENT_IMAGE':
            let app_client_index = state.appClients.findIndex(({id}) => id === action.payload.user_id);
            state.appClients[app_client_index].image = action.payload.path;
            return {
                ...state
            }

        case 'LOAD_USER_DATA':
            return {
                ...state,
                user: action.payload
            }

        default :
            return state;
    }
}
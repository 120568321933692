import React, {useEffect, useState} from 'react'
import Deshbcontent from './Deshbcontent'
import Dsubcount from './Dsubcount'
import {useSelector} from "react-redux";

function DeshboardBanner() {

    const auth = useSelector(state => state.auth);
    const stream = useSelector(state => state.stream);
    const scheduler = useSelector(state => state.scheduler);
    const contents = useSelector(state => state.content);
    const rebrandData = useSelector(state => state.rebrand);

    const [clientCount, setClientCount] = useState(auth.appClients.length);
    const [streamCount, setStreamCount] = useState(stream.streams.length);
    const [scheduleCount, setScheduleCount] = useState(scheduler.schedule.length);
    const [contentsCount, setContentsCount] = useState(contents.images.length +  contents.videos.length);
    const [totalEarn, setTotalEarn] = useState(0);
    const [rebrand, setRebrand] = useState(rebrandData);


    useEffect(()=>{
        setRebrand(rebrandData);
    },[rebrandData])

   useEffect(()=>{
       let amount = 0;
       for (let i = 0; i < auth.appClients.length; i++) {
           amount += parseInt(auth.appClients[i].earning);
       }

       setTotalEarn(amount);

   },[auth.appClients])

    useEffect(()=>{
        setClientCount(auth.appClients.length);
        setStreamCount(stream.streams.length);
        setScheduleCount(scheduler.schedule.length);
        setContentsCount(contents.images.length +  contents.videos.length);

    },[auth.appClients,stream.streams, scheduler.schedule,contents.images,contents.videos]);

    return (
        <>
         <section className="deshborad-banner-sec">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 d-flex align-items-center">
                      <Deshbcontent name="WELCOME TO"
                                    subname={`${rebrand.data !== false ? rebrand.data.name : 'AGENCYREEL 2.0'}`}
                                    para="The easiest way to manage you and your clients content and social media from one smart platform."
                                    btn="Watch Now"
                      />
                  </div>
                  <div className="col-lg-5">
                    <div className="deshborad-conunt">
                        <Dsubcount link="clients" num={`${ clientCount <= 9 ? '0'+clientCount : clientCount }`} name="CLIENTS" type="marks-client" />
                        {
                            auth.user.client == "0" ?
                                <>
                                    <Dsubcount link="streams"  num={`${ streamCount <= 9 ? '0'+streamCount : streamCount }`} name="CONTENT STREAM"  type="marks-stream"  />
                                    <Dsubcount link="schedule-content" num={`${ scheduleCount <= 9 ? '0'+scheduleCount : scheduleCount }`} name="CONTENT SCHEDULED" type="marks-schedule" />
                                    <Dsubcount link="contents" num={`${ contentsCount <= 9 ? '0'+contentsCount : contentsCount }`} name="CONTENT CREATED" type="marks-created" />
                                    <Dsubcount link="#" num={`$${ totalEarn <= 9 ? '0'+totalEarn : totalEarn }`} name="TOTAL EARNED" type="marks-earned" />
                                </>
                            : ''
                        }
                    </div>
                  </div>
                </div>
              </div>
            </section>
   
        </>
    )
}

export default DeshboardBanner

import React, {useState, useEffect} from 'react'
import Deshcontentcard from './Deshcontentcard'
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {baseURL} from "../../global/global";
import {addImageContent, addVideoContent} from "../../store/actions/contentsActions";

function DeshboardContent() {

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const contentData = useSelector(state => state.content);

    const [videoData, setVideoData] = useState({data: contentData.videos});
    const [imageData, setImageData] = useState({data: contentData.images});

    const fetchContents = (type) => {
        axios({
            method: 'POST',
            url : `${baseURL}${type}`,
            data: {},
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res =>{

            if(res.data.status === true){

                if(type === 'fetch-thumbnails'){
                    setImageData({...imageData, data: res.data.data});
                    dispatch(addImageContent(auth.token));

                }else{
                    setVideoData({...videoData, data: res.data.data});
                    dispatch(addVideoContent(auth.token));
                }

            }else{
                console.log(res.data);
            }

        }).catch(error=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        setImageData({...imageData, data: contentData.images});
        setVideoData({...videoData, data: contentData.videos});
    },[contentData.images, contentData.videos])


    useEffect(()=>{
        fetchContents('fetch-thumbnails');
        fetchContents('fetch-videos');

        return () => {
            setImageData({...imageData, data: []});
            setVideoData({...videoData, data: []});
        }

    },[]);


    useEffect(()=>{
        setVideoData({...videoData, data: contentData.videos});
    },[contentData.videos])

    return (
        <>
           <section className="deshborad-sec"  style={{backgroundColor:"#EBEBEB"}}  >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="deshborad-sec-2-head d-flex justify-content-between">
                    <h5>Recently Created Videos</h5>
                    <Link style={{textDecoration: "none", color:"#000"}} to="/contents">View All  <span><i class="fas fa-arrow-right"></i></span> </Link>
                  </div>
                </div>
              </div>
              <div className="row mt-4">


                    {
                        videoData.data.length > 0 ?
                            videoData.data.slice(0, 4).map((item,index)=>{
                                return(
                                    <div key={index} className="col-lg-3 col-md-6">
                                        <Deshcontentcard
                                            type="video"
                                            image={item.poster}
                                            name={item.name}
                                            download={item.video_path}
                                            create={item.created}
                                            id={item.id}
                                            fetchContents={fetchContents}
                                            status={item.status}
                                            failed={item.failed}
                                        />
                                    </div>
                                )
                            })
                            : <div className="col-12"><h6 className="text-center">You have no video created yet</h6></div>

                    }


              </div>

              <div className="row mt-5">
                <div className="col-lg-12">
                  <div className="deshborad-sec-2-head  mt-5 d-flex justify-content-between">
                    <h5>Recently Created Images</h5>
             
                    <Link style={{textDecoration: "none" , color:"#000" }} to="/contents">View All <span><i class="fas fa-arrow-right"></i></span> </Link>
                  </div>
                </div>
              </div>



              <div className="row mt-4">
                  {
                      imageData.data.length > 0 ?
                          imageData.data.slice(0, 4).map((item,index)=>{
                              return(
                                  <div key={index} className="col-lg-3 col-md-6">
                                      <Deshcontentcard
                                          type="image"
                                          image={item.thumbnail}
                                          name={item.name}
                                          download={item.thumbnail}
                                          create={item.created}
                                          id={item.id}
                                          fetchContents={fetchContents}
                                          status=""
                                          failed=""
                                      />
                                  </div>
                              )
                          })
                          : <div className="col-12"><h6 className="text-center">You have no images created yet</h6></div>

                  }

              </div>
            </div>
          </section>

        </>
    )
}

export default DeshboardContent;

import React, {useEffect, useState} from 'react'
import axios from "axios";


const TrainingVideo = () => {

    const [showPopup, setSetPopup] = useState(false);
    const [loadVideo, setVideos] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const [videosData, setVideosData] = useState([]);


    const onPlayVimeo = async (url) => {
        await setVideoUrl(url);

        setSetPopup(true);
    }

    const closePopUp = () =>{
        setSetPopup(false);

        setVideoUrl('');
    }


    const fetchVideos = async () => {
        setVideos(true);
        await axios({
            method: "POST",
            url: `https://adminserver.reelapps.io/api/fetch-videos`,
            data: JSON.stringify({app_id: 35}),
            headers: {'Content-Type': 'application/json'}
        }).then(res=>{
            if(res.data.status === true){
                setVideosData(res.data.data);
            }
            setVideos(false);

        }).catch(error=>{
            setVideos(false);
        })
    }


    useEffect(()=>{
        fetchVideos();
    },[])

    return (
        <>
          <section className="popular-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="text-center">Popular Video Tutorials</h4>
                </div>
              </div>
              <div className="row mt-5">

                  {
                      videosData.length > 0 ?
                          videosData.map((item,index)=>{
                              return(
                                  <div key={index} tabIndex="0" className="col-lg-3 col-md-6 mb-4">
                                      <img src={item.thumbnail} className="img-fluid" onClick={(e)=>onPlayVimeo(item.url)} />
                                      <div>
                                          <i onClick={(e)=>onPlayVimeo(item.url)} style={{cursor: "pointer"}} className="fas fa-play-circle"></i>
                                          &nbsp;&nbsp;
                                          <span>{item.title}</span>
                                      </div>
                                      <div className="video-time d-flex justify-content-between">
                                          <span>{item.created}</span>
                                      </div>

                                  </div>
                              )
                          })
                       : ''
                  }
                  {
                      loadVideo === true ?
                          <div className="text-center">
                              <p><i className="fa fa-spinner fa-spin mr-2"/> Loading...</p>
                          </div>
                      : ''
                  }
              </div>
            </div>
          </section>

          <section className="content-sec-videoreel">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="contact-videoreel">
                    <h5 className="text-center">CUSTOMER SUPPORT</h5>
                    <h3 className="text-center">Can't find your answer?</h3>
                    <p className="text-center">We're here to help! From general communication to specific AgencyReel<br/>
                      platform questions, please reach out so we can assist
                    </p>
                  </div>
                  <div className="contact-videoreel d-flex justify-content-center">
                    <a target="_blank" href="https://support.vineasx.com" className="button">Contact Our Helpdesk</a>
                  </div>
                </div>
              </div>
            </div>
          </section>



          <div className={`modal ${showPopup ? "fade show d-block" : ""}`}  id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e)=>closePopUp(e)}>
                                    <span aria-hidden="true" style={{color:'#000'}} >×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="embed-responsive embed-responsive-1by1">
                                            <iframe className="embed-responsive-item" src={videoUrl} allowFullScreen />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>








        </>
    )
}

export default TrainingVideo;

import React from 'react'
import AddClients from './SubComponent/AddClients'
import AddFrom from './SubComponent/AddFrom'
import Navbar from './SubComponent/Navbar'
import {Helmet} from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";

function Aaddpage() {
    return (
        <>
            <Helmet>
                <title>AgencyReel | Client </title>
            </Helmet>
         <div className="wrapper-box">
            <Navbar/>
             <BannerLinebar/>
            <AddClients client="Add"/>
            <AddFrom/>
         </div>
        </>
    )
}

export default Aaddpage

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import store from "./store/store";

import './Responsive.css';


ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode basename={'/'}>
            <App />
        </React.StrictMode>
    </Provider>,
    document.getElementById("root")
);



import React from 'react'
import Footer from './SubComponent/Footer'
import Navbar from './SubComponent/Navbar'
import SDetailshead from './SubComponent/SDetailshead'
import SDetailsname from './SubComponent/SDetailsname'
import SDetailsTable from './SubComponent/SDetailsTable'
import {Helmet} from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";

function ScheduleDetails(props) {
    return (
        <>
            <Helmet>
                <title>AgencyReel | Schedule Details </title>
            </Helmet>
            <div className="wrapper-box">
          <Navbar/>
                <BannerLinebar/>
          {/*<SDetailshead/>*/}
          {/*<SDetailsname id={props}/>*/}
          <SDetailsTable id={props}/>
          </div>
        </>
    )
}

export default ScheduleDetails

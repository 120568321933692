import React, {useEffect, useState} from "react";
import mediumImage from "../../images/medium-logo.png";
import Alert from "../alert/alert";
import {useDispatch, useSelector} from "react-redux";
import {saveMedium} from "../../store/actions/socialAccount";
import {feUrl} from "../../global/global";

const MediumConnect = ({checkAccount,editClient}) => {

    const dispatch = useDispatch();

    const [loader,setLoader] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [mediumInput, setMediumInput] = useState({
        user_id: editClient.id,
        access_token: null
    })

    const onInputChange = (e) => {
        setMediumInput({...mediumInput,access_token: e.target.value});
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(saveMedium(mediumInput,setShowPopup,setLoader));
    }

    useEffect(()=>{
        setMediumInput({...mediumInput,user_id: editClient.id});
    },[editClient])

    return(
        <>

            <a className={`${checkAccount === false ? 'hide-button' : '' }`} onClick={(e)=>setShowPopup(true)} href="#">
                <img className={`${checkAccount === false ? 'client-img-opacity' : '' }`} src={mediumImage} alt="telegram" />
            </a>

            <Alert/>
            <div className={`modal ${showPopup ? "fade show d-block" : ""}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <form className="modal-content" style={{padding: "15px 20px"}} method="post" onSubmit={(e)=>onFormSubmit(e)}>
                        <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title text-center">Connect Medium</h5>
                            <button type="button" className="close" onClick={(e)=>setShowPopup(false)} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="form-group mt-1">
                                    <label htmlFor="exampleInputEmail1">Access Token</label>
                                    <input type="text" className="form-control"
                                           aria-describedby="emailHelp" placeholder="Enter Access Token" required
                                           name="access_token" onChange={(e)=> onInputChange(e)}
                                    />
                                </div>
                                <a href={`${feUrl}article-details?id=9&cid=3`} target="_blank">How to get this information ?</a>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-center ">
                            <button type="submit" disabled={loader}>
                                { loader ? <i className="fa fa-spinner fa-spin mr-2"/> : '' } Connect
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default MediumConnect;
import React from 'react';

const StreamText = () => {


    return (
        <>

        </>
    )
}

export default StreamText;
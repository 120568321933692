import React, {useState} from 'react'
import LoginLogo from '../../images/loginlogo.png';
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {loginUser} from "../../store/actions/authAction";
import Alert from "../alert/alert";
import {Helmet} from "react-helmet";

function Logincontent() {

  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector(state => state.auth);

  const [loader,setLoader] = useState(false);
  const [user,setUser] = useState({
    email: null,
    password: null
  });

  const onInputChange = (e) => {
    setUser({...user,[e.target.name]:e.target.value})
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    dispatch(loginUser(user,setLoader));

  }

  if(auth.isAuthenticated) {
    // history.push('/dashboard');
    // window.location.reload();
  }

    return (
        <>
          <Helmet>
            <title>AgencyReel | Login </title>
          </Helmet>
          <section className="login-sec">
            <div className="container">

              <Alert />

              <div className="row d-flex justify-content-center">
                <div className="col-md-12 col-lg-10">
                  <div className="login">
                    <div className="row">
                      <div className="col-lg-5   col-md-12 d-flex align-items-center">
                        <div className="login-content">
                          <div className="logo-img-line">
                            <img src={LoginLogo} alt className="img-fluid" />
                          </div>
                          <div className="logo-head text-white">
                            <h5>Professional Agency Business  <span className="d-lg-block"> For You With Just Minutes Of Work</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 offset-lg-1 col-md-12">
                        <div className="login-account">

                          <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                            <h5 className="text-center">Login To your  Account Now</h5>
                            <div className="form-group mt-5">
                              <label htmlFor="exampleInputEmail1">Email Address</label>
                              <input type="email" className="form-control" id="exampleInputEmail1"
                                     aria-describedby="emailHelp" placeholder="Email Address " required
                                     name="email" onChange={(e)=> onInputChange(e)}
                              />
                            </div>
                            <div className="form-group mt-5">
                              <label htmlFor="exampleInputPassword1">Password</label>
                              <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password"
                                     name="password" required onChange={(e) => onInputChange(e)}
                              />
                            </div>
                            <div className="forget-sec m-4 text-center">
                              <h6>Forgot your account details? <span><Link to="forget-password">Click here</Link></span> </h6>
                            </div>
                            <button type="submit" className="button"
                             disabled={loader} > { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Login</button>
                          </form>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
}

export default Logincontent

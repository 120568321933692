import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

function AddClients(props) {

    const auth = useSelector(state => state.auth);
    const [clientCount, setClientCount] = useState(auth.appClients.length);

    useEffect(()=>{
        setClientCount(auth.appClients.length);
    },[auth.appClients]);

    return (
        <>
          <section className="sec-1">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="sec-1-heading">
                    <ul className="list-inline m-0 d-flex">
                        <li>
                            <a href="#">{props.client} Clients</a>
                        </li>
                        {
                            auth.user.client !== "1" ?
                            <li className="ml-5">
                                <Link to="/clients">{clientCount} Clients</Link>
                            </li>
                            : ''
                        }
                    </ul>
                    </div>
                </div>
                </div>
            </div>
         </section>    
        </>
    )
}

export default AddClients

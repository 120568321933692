const initialState = {
    streams: [],
    user_stream: [],
    current_stream: []
}

export default function (state = initialState, action) {

    switch (action.type) {
        case 'ADD_STREAM':
            return{
                ...state,
                streams: action.payload
            }
        case 'ADD_CURRENT_STREAM':
            return {
                ...state,
                current_stream: action.payload
            }
        case 'ADD_USER_STREAM':
            return {
                ...state,
                user_stream: action.payload
            }
        case 'REMOVE_CURRENT_STREAM':
            return {
                ...state,
                current_stream: []
            }
        default:
            return {
                ...state
            }
    }
}
import React, {useState} from "react";
import queryString from "query-string";
import {useDispatch} from "react-redux";
import {saveVimeo} from "../../store/actions/socialAccount";
import {useHistory} from "react-router-dom";
import Alert from "../alert/alert";

const VimeoCallback = ({location}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [appCode, setAppCode] = useState({
        code: queryString.parse(location.search).code
    });


    if(appCode.code !== ''){
        let user_id = queryString.parse(location.search).state;
        // window.close();
        dispatch(saveVimeo(appCode));
        history.push(`/edit-client/${user_id}`);
    }


    return(
        <>
            <Alert/>
        </>
    )

}

export default VimeoCallback;
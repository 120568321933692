import React, {useState} from "react";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton, InstapaperIcon, InstapaperShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton,
    PocketIcon,
    PocketShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TumblrShareButton,
    TwitterIcon,
    TwitterShareButton, ViberIcon, ViberShareButton, VKIcon, VKShareButton,
    WhatsappIcon,
    WhatsappShareButton, WorkplaceIcon, WorkplaceShareButton
} from "react-share";

const GoogleTrend = ({item,index}) => {

    const [googleCollapse, setGoogleCollapse] = useState('Show More');

    const toggleGoogle = () => {
        if(googleCollapse === 'Show More'){
            setGoogleCollapse('Show Less');
        }else{
            setGoogleCollapse('Show More');
        }
    }

    return (
        <>
            <div className="col-lg-6"
                 style={{marginBottom: "1rem"}}>
                <div className="youtube-box-content"
                     style={{
                         boxShadow: "0px 0px 5px #a2a2a2d1",

                         overflow: "hidden"
                     }}>
                    <div className="row">
                        <div
                            className="col-md-4   pr-0 d-flex align-items-start"
                            style={{backgroundColor: "#eee"}}>
                            <img style={{width: "100%"}}
                                 src={item.image.imgUrl}
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-8 pl-0 " style={{
                            backgroundColor: "#eee",
                            position: "relative"
                        }}>
                            {
                                item.articles.length > 0 ?
                                    item.articles.slice(0, 1).map((art, i) => {
                                        return (
                                            <div key={i} className="youtube-content">
                                                <h5 className="m-0">
                                                    {art.articleTitle.replaceAll("&#39;","'")}
                                                </h5>
                                                <span className="d-block">
                                                                                            <a target="_blank"
                                                                                               href={art.shareUrl}>
                                                                                                {art.source}</a>- {art.time}
                                                                                        </span>
                                                <p title={art.snippet.replaceAll("&#39;","'")}>
                                                    {art.snippet.replaceAll("&#39;","'")}
                                                </p>


                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="view-visit-download">
                                                            <ul className="list-inline m-0 d-flex justify-content-between">
                                                                <li>
                                                                    <a
                                                                        target="_blank"
                                                                        href={art.url}
                                                                    >
                                                                        <span><i
                                                                            className="fas fa-play-circle"></i></span>Visit
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="view-visit-download social-icon-share">
                                                            {
                                                                <>
                                                                    <FacebookShareButton
                                                                        url={art.url}
                                                                        quote={art.url}>
                                                                        <FacebookIcon/>
                                                                    </FacebookShareButton>

                                                                    <EmailShareButton
                                                                        url={art.url}
                                                                        quote={art.url}>
                                                                        <EmailIcon/>
                                                                    </EmailShareButton>

                                                                    <LinkedinShareButton
                                                                        url={art.url}
                                                                        quote={art.url}>
                                                                        <LinkedinIcon/>
                                                                    </LinkedinShareButton>

                                                                    <PinterestShareButton
                                                                        url={art.url}
                                                                        quote={art.url}>
                                                                        <PinterestIcon/>
                                                                    </PinterestShareButton>

                                                                    <RedditShareButton
                                                                        url={art.url}
                                                                        quote={art.url}>
                                                                        <RedditIcon/>
                                                                    </RedditShareButton>

                                                                    <TelegramShareButton
                                                                        url={art.url}
                                                                        quote={art.url}>
                                                                        <TelegramIcon/>
                                                                    </TelegramShareButton>

                                                                    <PocketShareButton
                                                                        url={art.url}
                                                                        quote={art.url}>
                                                                        <PocketIcon/>
                                                                    </PocketShareButton>

                                                                    <TwitterShareButton
                                                                        url={art.url}
                                                                        quote={art.url}>
                                                                        <TwitterIcon/>
                                                                    </TwitterShareButton>

                                                                    <WhatsappShareButton
                                                                        url={art.url}
                                                                        quote={art.url}>
                                                                        <WhatsappIcon/>
                                                                    </WhatsappShareButton>

                                                                    <TumblrShareButton
                                                                        url={art.url}
                                                                        quote={art.url}>
                                                                        <TumblrIcon/>
                                                                    </TumblrShareButton>

                                                                    <InstapaperShareButton
                                                                        url={art.url}
                                                                        quote={art.url}>
                                                                        <InstapaperIcon/>
                                                                    </InstapaperShareButton>

                                                                    <ViberShareButton
                                                                        url={art.url}
                                                                        quote={art.url}>
                                                                        <ViberIcon/>
                                                                    </ViberShareButton>

                                                                    <VKShareButton
                                                                        url={art.url}
                                                                        quote={art.url}>
                                                                        <VKIcon/>
                                                                    </VKShareButton>

                                                                    <WorkplaceShareButton
                                                                        url={art.url}
                                                                        quote={art.url}>
                                                                        <WorkplaceIcon/>
                                                                    </WorkplaceShareButton>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        )
                                    })
                                    : ""
                            }

                            <div className="row">
                                <div className="col-md-12">
                                    <button style={{fontSize: "14px", textDecoration: "none"}}
                                            className="btn btn-link show-google-toggle" data-toggle="collapse"
                                            data-target={`#collapseOne_${index}`} aria-expanded="true"
                                            aria-controls={`collapseOne_${index}`} onClick={(e)=>toggleGoogle()} >{googleCollapse}
                                    </button>
                                </div>
                            </div>

                            <div className="row">
                                <div id={`collapseOne_${index}`} className="collapse" aria-labelledby="headingOne"
                                     data-parent="#accordion">
                                    {
                                        item.articles.length > 0 ?
                                            item.articles.slice(1, item.articles.length).map((art, i) => {
                                                return (
                                                    <div key={i} className="youtube-content">
                                                        <h5 className="m-0">
                                                            {art.articleTitle.replaceAll("&#39;","'")}
                                                        </h5>
                                                        <span className="d-block">
                                                                                            <a target="_blank"
                                                                                               href={art.url}>
                                                                                                {art.source}</a>- {art.time}
                                                                                        </span>
                                                        <p title={art.snippet.replaceAll("&#39;","'")}>
                                                            {art.snippet.replaceAll("&#39;","'")}
                                                        </p>


                                                        <div className="row">
                                                            <div className="col-md-12 show-google">
                                                                <div className="view-visit-download">
                                                                    <ul className="list-inline m-0 d-flex justify-content-between">
                                                                        <li>
                                                                            <a
                                                                                target="_blank"
                                                                                href={art.url}
                                                                            >
                                                                                <span><i
                                                                                    className="fas fa-play-circle"></i></span>Visit
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="view-visit-download social-icon-share">
                                                                    {
                                                                        <>
                                                                            <FacebookShareButton
                                                                                url={art.url}
                                                                                quote={art.url}>
                                                                                <FacebookIcon/>
                                                                            </FacebookShareButton>

                                                                            <EmailShareButton
                                                                                url={art.url}
                                                                                quote={art.url}>
                                                                                <EmailIcon/>
                                                                            </EmailShareButton>

                                                                            <LinkedinShareButton
                                                                                url={art.url}
                                                                                quote={art.url}>
                                                                                <LinkedinIcon/>
                                                                            </LinkedinShareButton>

                                                                            <PinterestShareButton
                                                                                url={art.url}
                                                                                quote={art.url}>
                                                                                <PinterestIcon/>
                                                                            </PinterestShareButton>

                                                                            <RedditShareButton
                                                                                url={art.url}
                                                                                quote={art.url}>
                                                                                <RedditIcon/>
                                                                            </RedditShareButton>

                                                                            <TelegramShareButton
                                                                                url={art.url}
                                                                                quote={art.url}>
                                                                                <TelegramIcon/>
                                                                            </TelegramShareButton>

                                                                            <PocketShareButton
                                                                                url={art.url}
                                                                                quote={art.url}>
                                                                                <PocketIcon/>
                                                                            </PocketShareButton>

                                                                            <TwitterShareButton
                                                                                url={art.url}
                                                                                quote={art.url}>
                                                                                <TwitterIcon/>
                                                                            </TwitterShareButton>

                                                                            <WhatsappShareButton
                                                                                url={art.url}
                                                                                quote={art.url}>
                                                                                <WhatsappIcon/>
                                                                            </WhatsappShareButton>

                                                                            <TumblrShareButton
                                                                                url={art.url}
                                                                                quote={art.url}>
                                                                                <TumblrIcon/>
                                                                            </TumblrShareButton>

                                                                            <InstapaperShareButton
                                                                                url={art.url}
                                                                                quote={art.url}>
                                                                                <InstapaperIcon/>
                                                                            </InstapaperShareButton>

                                                                            <ViberShareButton
                                                                                url={art.url}
                                                                                quote={art.url}>
                                                                                <ViberIcon/>
                                                                            </ViberShareButton>

                                                                            <VKShareButton
                                                                                url={art.url}
                                                                                quote={art.url}>
                                                                                <VKIcon/>
                                                                            </VKShareButton>

                                                                            <WorkplaceShareButton
                                                                                url={art.url}
                                                                                quote={art.url}>
                                                                                <WorkplaceIcon/>
                                                                            </WorkplaceShareButton>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GoogleTrend;
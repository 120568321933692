import React from 'react'
import Logincontent from './SubComponent/Logincontent'
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";


function Login() {

    const history = useHistory();
    const auth = useSelector(state => state.auth);

    if(auth.isAuthenticated) {
        history.push('/dashboard');
    }

    return (
        <>
        
       <Logincontent/>
        </>
    )
}

export default Login

import React, {useEffect, useState} from 'react';
import {SketchPicker} from "react-color";

const TextEditor = ({canvas}) => {

    const [showTextColor ,setShowTextColor] = useState(false);
    const [txtHex, setTxtHex] = useState('');

    const [textLayerData, setTextLayerData] = useState({
        font_size: 40,
        font_color: '#000000',
        font_family: '',
        bold: '',
        italic: '',
        underline: false,
        linethrough: false
    })

    const textFontFamily = (e) => {
        let activeObject = canvas.getActiveObject();
        if(activeObject !== null) {
            canvas.getActiveObject().set("fontFamily", e.target.value);

            setTextLayerData({...textLayerData, font_family: e.target.value});

            canvas.renderAll();
        }
    }

    const textColor = (e) => {
        let activeObject = canvas.getActiveObject();
        setTxtHex(e.hex);

        if(activeObject !== undefined) {
            canvas.getActiveObject().set("fill", e.hex);


            setTextLayerData({...textLayerData, font_color: e.hex});
            canvas.renderAll();
        }
    }

    const textSize = (e) => {
        let activeObject = canvas.getActiveObject();
        if(activeObject !== undefined) {
            canvas.getActiveObject().set("fontSize", e.target.value);
            setTextLayerData({...textLayerData, font_size: e.target.value});
            canvas.renderAll();
        }
    }

    const textAlign = (align=null) => {
        let activeObject = canvas.getActiveObject();
        if(activeObject !== undefined){

            if(align !== null){
                canvas.getActiveObject().set({textAlign: align});
            }

            canvas.renderAll();
        }
    }

    const textItalic = () => {
        let activeObject = canvas.getActiveObject();
        if(activeObject !== undefined){
            if(activeObject.fontStyle === 'normal'){
                canvas.getActiveObject().set({fontStyle: 'italic'});
            }else{
                canvas.getActiveObject().set({fontStyle: 'normal'});
            }
            canvas.renderAll();
        }
    }

    const textBold = () =>{
        let activeObject = canvas.getActiveObject();

        if(activeObject !== undefined){
            if(activeObject.fontWeight === 'normal'){
                canvas.getActiveObject().set({fontWeight: 'bold'});
            }else{
                canvas.getActiveObject().set({fontWeight: 'normal'});
            }
            canvas.renderAll();
        }
    }

    const textUnderline = () => {
        let activeObject = canvas.getActiveObject();

        if(activeObject !== undefined){
            if(activeObject.underline === false){
                canvas.getActiveObject().set({underline: true});
            }else{
                canvas.getActiveObject().set({underline: false});
            }
            canvas.renderAll();
        }
    }

    const textStrike = () => {
        let activeObject = canvas.getActiveObject();
        if(activeObject !== undefined){
            if(activeObject.linethrough === false){
                canvas.getActiveObject().set({linethrough: true});
            }else{
                canvas.getActiveObject().set({linethrough: false});
            }
            canvas.renderAll();
        }
    }


    useEffect(()=>{
        if(canvas !== null){

            canvas.on('selection:updated', function (e) {
                let txtObj = canvas.getActiveObject();
                setTextLayerData({...textLayerData,
                    font_size: txtObj.fontSize,
                    font_color: txtObj.fill,
                    font_family: txtObj.fontFamily,
                    bold: txtObj.fontWeight,
                    italic: txtObj.fontStyle,
                    underline: txtObj.underline,
                    linethrough: txtObj.linethrough
                });
            });
        }
    },[canvas])


    return(
        <>
            <div className="form-group mt-4">
                <label htmlFor="exampleFormControlSelect1">Font</label>
                <select id="font-family" className="form-control" name="font-family" autoComplete="off" onChange={(e)=>textFontFamily(e)}>
                    <option selected={textLayerData.font_family === "Aaron-Bold, Poppins"} value="Aaron-Bold, Poppins" data-fonttype="custom" style={{fontFamily: 'Aaron-Bold, Poppins'}}>Aaron-Bold</option>
                    <option selected={textLayerData.font_family === "Abril Fatface Poppins"} value="Abril Fatface Poppins" data-fonttype="custom" style={{fontFamily: 'Abril Fatface Poppins'}}>Abril Fatface</option>
                    <option selected={textLayerData.font_family === "Aero, Poppins"} value="Aero, Poppins" data-fonttype="custom" style={{fontFamily: 'Aero, Poppins'}}>Aero</option>
                    <option selected={textLayerData.font_family === "Almond-Regular, Poppin"} value="Almond-Regular, Poppin" data-fonttype="custom" style={{fontFamily: 'Almond-Regular, Poppins'}}>Almond-Regular</option>
                    <option selected={textLayerData.font_family === "Anton"} value="Anton" data-fonttype="custom" style={{fontFamily: 'Anton, Poppins'}}>Anton</option>
                    <option selected={textLayerData.font_family === "Arial"} value="Arial" data-fonttype="default" style={{fontFamily: 'Arial'}}>Arial</option>
                    <option selected={textLayerData.font_family === "Auto-Rich"} value="Auto-Rich" data-fonttype="custom" style={{fontFamily: 'Auto-Rich, Poppins'}}>Auto-Rich</option>
                    <option selected={textLayerData.font_family === "BankGothic-Bold"} value="BankGothic-Bold" data-fonttype="custom" style={{fontFamily: 'BankGothic-Bold, Poppins'}}>BankGothic-Bold</option>
                    <option selected={textLayerData.font_family === "Baskerville"} value="Baskerville" data-fonttype="custom" style={{fontFamily: 'Baskerville, Poppins'}}>Baskerville</option>
                    <option selected={textLayerData.font_family === "Big-Noodle-Titling"} value="Big-Noodle-Titling" data-fonttype="custom" style={{fontFamily: 'Big-Noodle-Titling, Poppins'}}>Big-Noodle-Titling</option>
                    <option selected={textLayerData.font_family === "Blingtastic"} value="Blingtastic" data-fonttype="custom" style={{fontFamily: 'Blingtastic, Poppins'}}>Blingtastic</option>
                    <option selected={textLayerData.font_family === "Comic sans ms"} value="Comic sans ms" data-fonttype="default" style={{fontFamily: 'Comic sans ms'}}>Comic sans ms</option>
                    <option selected={textLayerData.font_family === "Courier"} value="Courier" data-fonttype="default" style={{fontFamily: 'Courier'}}>Courier</option>
                    <option selected={textLayerData.font_family === "Engagement"} value="Engagement" data-fonttype="custom" style={{fontFamily: 'Engagement, Poppins'}}>Engagement</option>
                    <option selected={textLayerData.font_family === "Futura-Bold"} value="Futura-Bold" data-fonttype="custom" style={{fontFamily: 'Futura-Bold, Poppins'}}>Futura-Bold</option>
                    <option selected={textLayerData.font_family === "Futura-Bold-Italic"} value="Futura-Bold-Italic" data-fonttype="custom" style={{fontFamily: 'Futura-Bold-Italic, Poppins'}}>Futura-Bold-Italic</option>
                    <option selected={textLayerData.font_family === "Futura-Book"} value="Futura-Book" data-fonttype="custom" style={{fontFamily: 'Futura-Book, Poppins'}}>Futura-Book</option>
                    <option selected={textLayerData.font_family === "Futura-Medium"} value="Futura-Medium" data-fonttype="custom" style={{fontFamily: 'Futura-Medium, Poppins'}}>Futura-Medium</option>
                    <option selected={textLayerData.font_family === "Georgia"} value="Georgia" data-fonttype="default" style={{fontFamily: 'Georgia'}}>Georgia</option>
                    <option selected={textLayerData.font_family === "Gobold-Bold"} value="Gobold-Bold" data-fonttype="custom" style={{fontFamily: 'Gobold-Bold, Poppins'}}>Gobold-Bold</option>
                    <option selected={textLayerData.font_family === "Gobold-High-Bold"} value="Gobold-High-Bold" data-fonttype="custom" style={{fontFamily: 'Gobold-High-Bold, Poppins'}}>Gobold-High-Bold</option>
                    <option selected={textLayerData.font_family === "Gobold-Regular"} value="Gobold-Regular" data-fonttype="custom" style={{fontFamily: 'Gobold-Regular, Poppins'}}>Gobold-Regular</option>
                    <option selected={textLayerData.font_family === "Helvetica"} value="Helvetica" data-fonttype="default" style={{fontFamily: 'Helvetica'}}>Helvetica</option>
                    <option selected={textLayerData.font_family === "impact"} value="impact" data-fonttype="default" style={{fontFamily: 'impact'}}>impact</option>
                    <option selected={textLayerData.font_family === "Jomhuria"} value="Jomhuria" data-fonttype="custom" style={{fontFamily: 'Jomhuria, Poppins'}}>Jomhuria</option>
                    <option selected={textLayerData.font_family === "Kristen ITC"} value="Kristen ITC" data-fonttype="custom">Kristen ITC</option>
                    <option selected={textLayerData.font_family === "LeagueSpartan-Bold"} value="LeagueSpartan-Bold" data-fonttype="custom" style={{fontFamily: 'LeagueSpartan-Bold, Poppins'}}>LeagueSpartan-Bold</option>
                    <option selected={textLayerData.font_family === "Lora-Bold"} value="Lora-Bold" data-fonttype="custom" style={{fontFamily: 'Lora-Bold, Poppins'}}>Lora-Bold</option>
                    <option selected={textLayerData.font_family === "Lora-Bold-Italic"} value="Lora-Bold-Italic" data-fonttype="custom" style={{fontFamily: 'Lora-Bold-Italic, Poppins'}}>Lora-Bold-Italic</option>
                    <option selected={textLayerData.font_family === "Merriweather"} value="Merriweather" data-fonttype="custom" style={{fontFamily: 'Merriweather, Poppins'}}>Merriweather</option>
                    <option selected={textLayerData.font_family === "Merriweather-Bold"} value="Merriweather-Bold" data-fonttype="custom" style={{fontFamily: 'Merriweather-Bold, Poppins'}}>Merriweather-Bold</option>
                    <option selected={textLayerData.font_family === "Montserrat"} value="Montserrat" data-fonttype="custom" style={{fontFamily: 'Modak, Poppins'}}>Modak</option>
                    <option selected={textLayerData.font_family === "Montserrat"} value="Montserrat" data-fonttype="default" style={{fontFamily: 'Montserrat'}}>Montserrat</option>
                    <option selected={textLayerData.font_family === "Myriad pro"} value="Myriad pro" data-fonttype="default" style={{fontFamily: 'Myriad pro'}}>Myriad pro</option>
                    <option selected={textLayerData.font_family === "Nexa-Bold"} value="Nexa-Bold" data-fonttype="custom" style={{fontFamily: 'Nexa-Bold, Poppins'}}>Nexa-Bold</option>
                    <option selected={textLayerData.font_family === "Nunito"} value="Nunito" data-fonttype="default" style={{fontFamily: 'Nunito'}}>Nunito</option>
                    <option selected={textLayerData.font_family === "Pacifico"} value="Pacifico" data-fonttype="custom" style={{fontFamily: 'Pacifico, Poppins'}}>Pacifico</option>
                    <option selected={textLayerData.font_family === "Plaster"} value="Plaster" data-fonttype="custom" style={{fontFamily: 'Plaster, Poppins'}}>Plaster</option>
                    <option selected={textLayerData.font_family === "Poppins"} value="Poppins" data-fonttype="default" style={{fontFamily: 'Poppins'}}>Poppins</option>
                    <option selected={textLayerData.font_family === "PT Sans"} value="PT Sans" data-fonttype="custom" style={{fontFamily: 'PT Sans, Poppins'}}>PT Sans</option>
                    <option selected={textLayerData.font_family === "Rajdhani-Bold"} value="Rajdhani-Bold" data-fonttype="custom" style={{fontFamily: 'Rajdhani-Bold, Poppins'}}>Rajdhani-Bold</option>
                    <option selected={textLayerData.font_family === "Roboto"} value="Roboto" data-fonttype="custom" style={{fontFamily: 'Roboto, Poppins'}}>Roboto</option>
                    <option selected={textLayerData.font_family === "SinkinSans-Black-Italic"} value="SinkinSans-Black-Italic" data-fonttype="custom" style={{fontFamily: 'SinkinSans-Black-Italic, Poppins'}}>SinkinSans-Black-Italic</option>
                    <option selected={textLayerData.font_family === "SinkinSans-Bold"} value="SinkinSans-Bold" data-fonttype="custom" style={{fontFamily: 'SinkinSans-Bold, Poppins'}}>SinkinSans-Bold</option>
                    <option selected={textLayerData.font_family === "SinkinSans-Regular"} value="SinkinSans-Medium" data-fonttype="custom" style={{fontFamily: 'SinkinSans-Medium, Poppins'}}>SinkinSans-Medium</option>
                    <option selected={textLayerData.font_family === "Aaron-Bold, Poppins"} value="SinkinSans-Regular" data-fonttype="custom" style={{fontFamily: 'SinkinSans-Regular, Poppins'}}>SinkinSans-Regular</option>
                    <option selected={textLayerData.font_family === "SinkinSans-SemiBold"} value="SinkinSans-SemiBold" data-fonttype="custom" style={{fontFamily: 'SinkinSans-SemiBold, Poppins'}}>SinkinSans-SemiBold</option>
                    <option selected={textLayerData.font_family === "verdana"} value="verdana" data-fonttype="default" style={{fontFamily: 'verdana'}}>verdana</option>
                </select>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <input className="form-control" name="inputNumber" type="number" min="1" value={textLayerData.font_size} onChange={(e)=>textSize(e)}/>
                </div>
                <div className="col-md-6">
                    <div className="btn-group" role="group" aria-label="Basic example">
                        <button onClick={(e)=>textBold()} type="button" className={`btn btn-secondary ${textLayerData.bold === 'bold' ? 'active-edit' : ''}`}><i className="fas fa-bold" /></button>
                        <button onClick={(e)=>textItalic()} type="button" className={`btn btn-secondary ${textLayerData.italic === 'italic' ? 'active-edit' : ''}`}><i className="fas fa-italic" /></button>
                        <button onClick={(e)=>textUnderline()} type="button" className={`btn btn-secondary ${textLayerData.underline === true ? 'active-edit' : ''}`}><i className="fas fa-underline" /></button>
                        <button onClick={(e)=>textStrike()} type="button" className={`btn btn-secondary ${textLayerData.linethrough === true ? 'active-edit' : ''}`}><i className="fas fa-strikethrough" /></button>
                    </div>
                </div>
                <div className="col-md-6 d-flex align-items-center">
                    <div id="cp4" className="input-group image-editor" title="Using color option">
                        {/*<input type="color" onChange={(e)=>textColor(e)} className="form-control input-lg" />*/}
                        {/*<span className="input-group-append">*/}
                        {/*    <span className="input-group-text colorpicker-input-addon"><i /></span>*/}
                        {/*</span>*/}
                        {
                            <>
                                <div className="swatch" onClick={ (e)=>setShowTextColor(true) }>
                                    <div className="swatch-color" style={{backgroundColor: textLayerData.font_color}} />
                                </div>
                                { showTextColor ? <div>
                                    <div className="swatch-cover" onClick={(e)=>setShowTextColor(false)}/>
                                    <SketchPicker color={txtHex} onChange={ (e)=>textColor(e) } />
                                </div> : null }
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-12">
                    <div className="aligment-group">
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button onClick={(e)=>textAlign('left')} type="button" className="btn btn-secondary1"><i className="fas fa-align-left" /></button>
                            <button onClick={(e)=>textAlign('center')} type="button" className="btn btn-secondary1"><i className="fas fa-align-center" /></button>
                            <button onClick={(e)=>textAlign('right')} type="button" className="btn btn-secondary1"><i className="fas fa-align-right" /></button>
                            <button onClick={(e)=>textAlign('justify')} type="button" className="btn btn-secondary1"><i className="fas fa-align-justify" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TextEditor

import React from 'react';
import Navbar from "./SubComponent/Navbar";
import ScheduleHead from "./SubComponent/ScheduleHead";
import ScheduleCalender from "./SubComponent/ScheduleCalender";
import {Helmet} from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";

const Scheduler = () => {
    return(
        <>
            <Helmet>
                <title>AgencyReel | Scheduler </title>
            </Helmet>
            <div className="wrapper-box">
            <Navbar/>
                <BannerLinebar/>
            <ScheduleHead/>
            <ScheduleCalender/>
            </div>
        </>
    )
}

export default Scheduler;
import randomstring from "randomstring";
import {setAlert} from "./alert";


export const addTextLayer = (image) => (dispatch,getState) => {

    let data = {
        type: "image",
        text:"",
        id: randomstring.generate(),
        text_position:"bottom-center",
        text_color:"#ffffff",
        start_time:"1",
        duration:"5",
        text_size:"20",
        bg_color:"",
        opacity: "1",
        font:"",
        font_name:"Select Font",
        file_url:image,
        transition:"",
        blur:"false",
        remove_bg:"false",
        audio:"",
        tts_text: "",
        tts_gen: "",
        poster:image,
        is_selected: "false"
    }

    dispatch({type: 'ADD_TEXT_LAYER',payload: data});
}

export const updateImage = (index,data) => (dispatch) => {

    let jsonData = {
        id: index,
        image: data,
        type: "image"
    }

    dispatch({type: 'UPDATE_IMAGE_LAYER',payload: jsonData});
}

export const updateImageType = (index,data,url) => (dispatch) => {

    let jsonData = {
        id: index,
        image: data,
        type: 'video',
        file_url: url
    }

    dispatch({type: 'UPDATE_IMAGE_TYPE_LAYER',payload: jsonData});
}

export const updateTextLayer = (index,data) => (dispatch) => {

    let jsonData = {
        id: index,
        text: data
    }

    dispatch({type: 'UPDATE_TEXT_LAYER',payload: jsonData});
}

export const updateColorLayer = (index,data) => (dispatch) => {

    let jsonData = {
        id: index,
        color: data
    }

    dispatch({type: 'UPDATE_TEXT_COLOR_LAYER',payload: jsonData});
}

export const updateSizeLayer = (index,data) => (dispatch) => {

    let jsonData = {
        id: index,
        size: data
    }

    dispatch({type: 'UPDATE_TEXT_SIZE_LAYER',payload: jsonData});
}

export const updateLayerDetails = (type,data) => (dispatch) => {
    dispatch({type: type,payload: data});
}


export const updateLogoWatermark = (type,data) => (dispatch) => {
    dispatch({type: type,payload: data});
}


export const addMusicLayer = (data) => (dispatch) => {
    dispatch({type: 'ADD_MUSIC_LAYER',payload: data})
}

export const removeMusicLayer = (data) => (dispatch) => {
    dispatch({type: 'ADD_MUSIC_LAYER',payload: data})
}

export const changeSelected = (data) => (dispatch) => {
    dispatch({type: 'CHANGE_SELECTED_LAYER', payload: data});
}

export const updateSlideTime = (index,value) => (dispatch) => {

    let data = {
        index: index,
        value: value
    }

    dispatch({type: 'ADD_VIDEO_DURATION', payload: data});
}

export const updateSlideOpacity = (index,value) => (dispatch) => {

    let data = {
        index: index,
        value: value
    }

    dispatch({type: 'CHANGE_VIDEO_OPACITY_LAYER', payload: data});
}


export const updateProjectName = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_PROJECT_NAME', payload: data})
}


export const updateAudioLayer = (index,value) => (dispatch) => {

    let data = {
        index: index,
        data: value
    }

    dispatch({type: 'UPDATE_AUDIO_LAYER', payload: data})
    dispatch(setAlert('TTS Applied Successfully','success'));
}


export const removeLogoWatermark = (type) => (dispatch) => {
    dispatch({type: type})
}

export const removeBgLayer = (index) => (dispatch) => {
    let data = {
        index: index
    }

    dispatch({type: 'REMOVE_BACKGROUND_LAYER', payload: data})
}

export const removeBlurLayer = (index,value) => (dispatch) => {
    let data = {
        index: index,
        value: value
    }

    dispatch({type: 'UPDATE_BLUR_LAYER', payload: data})
}

export const updateThumbnail = (image) => (dispatch) => {

    let data = {
        thumbnail: image
    }

    dispatch({type: 'UPDATE_THUMBNAIL', payload: data});
}


export const updateRenderStatus = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_RENDER_TYPE', payload: data});
}


export const resetLayerData = () => (dispatch) => {

    let data = {
        tiles: [{
            type: "image",
            text:"",
            id: randomstring.generate(),
            text_position:"bottom-center",
            text_color:"#ffffff",
            start_time:"1",
            duration:"5",
            text_size:"20",
            opacity: "1",
            bg_color:"",
            font:"",
            font_name:"Select Font",
            file_url:"",
            transition:"",
            blur:"false",
            remove_bg:"false",
            tts_text: "",
            tts_gen: "",
            audio:"",
            poster:"",
            is_selected: "true"
        }],
        logo: false,
        watermark: false,
        audio: false,
        thumbnail: false,
        type: 'save',
        keyword: false,
        name: 'agencyreel_'+randomstring.generate(8)
    }

    dispatch({type: 'RESET_LAYER_DATA', payload: data});
}


export const updateAllLayerData = (jsonData, id) => (dispatch) => {
    let data = {
        json: jsonData,
        id: id
    }

    dispatch({type: 'UPDATE_ALL_LAYER_DATA',payload: data});
}

export const updateTtxText = (value, id) => (dispatch) => {
    let data = {
        value: value,
        index: id
    }

    dispatch({type: 'UPDATE_TTS_TEXT',payload: data});
}


export const updateTtsGen = (value, id) => (dispatch) => {
    let data = {
        value: value,
        index: id
    }

    dispatch({type: 'UPDATE_TTS_GEN',payload: data});
}


export const updateTtsGenStatus = (id) => (dispatch) => {
    let data = {
        index: id
    }

    dispatch({type: 'UPDATE_TTS_GEN_STATUS',payload: data});
}


export const addUserKeyword = (value) => (dispatch) => {
    let data = {
        keyword: value
    }

    dispatch({type: 'ADD_USER_KEYWORD',payload: data});
}
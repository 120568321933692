import React from 'react'
import noticheck from '../../images/noticheck.png';
import notiworng from '../../images/notiworng.png';
import notiarror from '../../images/notierror.png';
import notiworng2 from '../../images/notiworng2.png';
import notiworng3 from '../../images/notiworng3.png';
import notiaccess from '../../images/notiaccess.png';
import Notialert from './Notialert';
function NotificationCom() {
    return (
        <>
            <section className="notification-sec">
  <div className="container">
    <div className="row">
      <div className="col-lg-6 mx-auto col-md-10">
        <div className="notifiction-box">
          <div className="row">
            <div className="col-md-10">
              <div className="success-box">
                  <Notialert notiimg={noticheck}
                             notihead="Success !"
                             notipara="Your Purchase has been confirmed !" 
                  />
              </div>
            </div>
            <div className="col-md-2">
              <div className="noti-worng">
                <img src={notiworng} alt />
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-lg-6 mx-auto col-md-10">
        <div className="notifiction-box-error">
          <div className="row">
            <div className="col-md-10">
              <div className="success-box">
              <Notialert notiimg={notiarror}
                             notihead="Error!"
                             notipara="Your Purchase has been declined !" 
                  />

              
              </div>
            </div>
            <div className="col-md-2">
              <div className="noti-worng">
                <img src={notiworng2} alt />
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-lg-6 mx-auto col-md-10">
        <div className="notifiction-box-messages">
          <div className="row">
            <div className="col-md-10">
              <div className="success-box">
              <Notialert notiimg={notiaccess}
                             notihead="Info Message !"
                             notipara="This is an Info Message ." 
                  />
   

                
              </div>
            </div>
            <div className="col-md-2">
              <div className="noti-worng">
                <img src={notiworng3} alt />
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</section>

        </>
    )
}

export default NotificationCom

import axios from "axios";
import {setAlert} from "./alert";
import {baseURL} from "../../global/global";

export const saveFacebook = (data) => async (dispatch,getState) => {

    const config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': getState().auth.token
        },
    };
    const body = JSON.stringify(data);

    try{
        const res = await axios.post(`${baseURL}save-facebook`,body,config);
        console.log(res);

        if(res.data.status === true){
            dispatch(setAlert(res.data.message,"success"));
            dispatch(fetchSocialAccounts(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message,"danger"));
        }
    }catch(err){
        console.log(err);
    }

}

export const saveTelegram = (data,setShowPopup,setLoader) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url : `${baseURL}connect-telegram`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization' : getState().auth.token}
    }).then(res =>{
        console.log(res);

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch(fetchSocialAccounts(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }

        setLoader(false);
        setShowPopup(false);

    }).catch(error => {
        console.log(error);

        setLoader(false);
        setShowPopup(false);
    })

}


export const saveWordpress = (data,setShowPopup,setLoader) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url : `${baseURL}save-wordpress`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization' : getState().auth.token}
    }).then(res =>{
        console.log(res);

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch(fetchSocialAccounts(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }

        setLoader(false);
        setShowPopup(false);

    }).catch(error => {
        console.log(error);

        setLoader(false);
        setShowPopup(false);
    })

}


export const saveMedium = (data,setShowPopup,setLoader) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url : `${baseURL}save-medium`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization' : getState().auth.token}
    }).then(res =>{
        console.log(res);

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch(fetchSocialAccounts(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }

        setLoader(false);
        setShowPopup(false);

    }).catch(error => {
        console.log(error);

        setLoader(false);
        setShowPopup(false);
    })

}


export const saveYoutube = (data) => async (dispatch ,getState)=> {

    const config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': getState().auth.token
        },
    };
    const body = JSON.stringify(data);

    try{
        const res = await axios.post(`${baseURL}save-youtube`,body,config);
        console.log(res);

        if(res.data.status === true){
            dispatch(setAlert(res.data.message,"success"));
            dispatch(fetchSocialAccounts(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message,"danger"));
        }
    }catch(err){
        console.log(err);
    }
}

export const saveReddit = (data,history) => async (dispatch ,getState)=> {

    const config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': getState().auth.token
        },
    };

    try{
        const res = await axios.post(`${baseURL}connect-reddit`,data,config);
        console.log(res);

        if(res.data.status === true){
            dispatch(setAlert(res.data.message,"success"));
            dispatch(fetchSocialAccounts(getState().auth.token));

        }else{
            dispatch(setAlert(res.data.message,"danger"));
        }
    }catch(err){
        console.log(err);
    }
}


export const saveVimeo = (data) => async (dispatch ,getState)=> {

    const config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': getState().auth.token
        },
    };
    const body = JSON.stringify(data);


    try{
        const res = await axios.post(`${baseURL}save-vimeo`,body,config);
        console.log(res);

        if(res.data.status === true){
            dispatch(setAlert(res.data.message,"success"));
            dispatch(fetchSocialAccounts(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message,"danger"));
        }
    }catch(err){
        console.log(err);
    }
}


export const saveLinkedIn = (data,history) => async (dispatch ,getState)=> {

    const config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': getState().auth.token
        },
    };

    try{
        const res = await axios.post(`${baseURL}linkedin-callback`,data,config);
        console.log(res);

        if(res.data.status === true){
            dispatch(setAlert(res.data.message,"success"));
            dispatch(fetchSocialAccounts(getState().auth.token));
            // history.push('/add-client');

        }else{
            dispatch(setAlert(res.data.message,"danger"));
            // history.push('/add-client');
        }
    }catch(err){
        console.log(err);
    }
}

export const removesocialMessage = () => dispatch => {
    dispatch({
        type:'REMOVE_SOCIAL_STATUS'
    })
}

export const fetchSocialAccounts = (token) => async (dispatch,getState) => {

    const config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': token
        },
    };

    try{
        const res = await axios.post(`${baseURL}fetch-social-accounts`,{},config);
        console.log(res);
        if(res.data.status === true){
            dispatch({type:'FETCH_SOCIAL_ACCOUNTS',payload:res.data.data})
        }
    }catch(err){
        console.log(err);
    }

}

export const deleteSocial = (data,setShowDelete,setButton) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url : `${baseURL}delete-social`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization' : getState().auth.token}
    }).then(res =>{
        console.log(res);

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch(fetchSocialAccounts(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }

        setButton('Delete');
        setShowDelete(false);

    }).catch(error => {
        console.log(error);

        setButton('Delete');
        setShowDelete(false);
    })

}

export const checkNetworkStatus = (key,setCheckStatus,interval,myWindow) => (dispatch,getState) => {
    axios({
        method: 'POST',
        url : `${baseURL}check-network-status`,
        data: JSON.stringify({key: key}),
        headers: {'Content-Type': 'application/json','Authorization' : getState().auth.token}
    }).then(res =>{
        console.log(res);

        if(res.data.status === true){
            clearInterval(interval);
            setCheckStatus(false);
            dispatch(setAlert(res.data.message,'success'));
            dispatch(fetchSocialAccounts(getState().auth.token));
            myWindow.close();
        }else{
            setCheckStatus(false);
        }

    }).catch(error => {
        setCheckStatus(false);
        window.close();
    })
}
import React from 'react'
import AddClients from './SubComponent/AddClients'
import Navbar from './SubComponent/Navbar'
import AddFrom from "./SubComponent/AddFrom";
import BannerLinebar from "./SubComponent/BannerLinebar";

function EditClient(props) {
    return (
        <>  
            <div className="wrapper-box">
            <Navbar/>
                <BannerLinebar/>
            <AddClients client="Edit" />
            <AddFrom id={props} />
            </div>
        </>
    )
}

export default EditClient;

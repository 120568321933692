import React, {useState} from 'react'
import {useDispatch} from "react-redux";
import {addClientTeam} from "../../store/actions/authAction";
import Alert from "../alert/alert";
import {NavLink} from "react-router-dom";

function TeamAddc() {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [user,setUser] = useState({
        name: null,
        email: null,
        password: null,
        type: 'team_member'

    });

    const onInputChange = (e) =>{
        setUser({...user,[e.target.name]:e.target.value});
    }

    const onFormSubmit = (e) =>{
        e.preventDefault();
        setLoader(true);
        dispatch(addClientTeam(user,setLoader));
    }

    return (
        <>
            <section className="sec-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-4 d-flex align-items-center">
                            <div className="sec-1-heading newline">
                                <ul className="list-inline m-0 d-flex">
                                    <li><NavLink to="#">Account Management</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="myaccount-sec">
              <div className="container">
                  <Alert/>
                <div className="row d-flex justify-content-center">
                  <div className="col-md-12 col-lg-12">
                    <div className="personal-heading">
                      <h5>Add Account</h5>
                    </div>
                  </div>
                </div>
                <div className="row mt-2 d-flex justify-content-center">
                  <div className="col-md-12 col-lg-12">
                    <form className="personal-box" method="post" onSubmit={(e)=>onFormSubmit(e)}>
                      <div className="row">
                          <div className="col-md-6">
                              <div className="form-group">
                                  <small id="emailHelp" className="form-text text-muted">Account Type</small>
                                  <select className="form-control" name="type" required onChange={(e)=>onInputChange(e)}>
                                      <option value="team_member">Team Member</option>
                                  </select>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <small id="emailHelp" className="form-text text-muted">Account Name</small>
                                  <input type="text" className="form-control" id="exampleInputEmail1"
                                         aria-describedby="emailHelp" placeholder="Enter Name" required
                                         name="name" onChange={(e)=>onInputChange(e)}
                                  />
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <small id="emailHelp" className="form-text text-muted">Account Email</small>
                                  <input type="email" className="form-control" id="exampleInputEmail1"
                                         aria-describedby="emailHelp" placeholder="Enter Email" required
                                         name="email" onChange={(e)=>onInputChange(e)}
                                  />
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <small id="emailHelp" className="form-text text-muted">Account Password</small>
                                  <input type="password" className="form-control" id="exampleInputEmail1"
                                         aria-describedby="emailHelp" placeholder="Enter Password" required
                                         name="password" onChange={(e)=>onInputChange(e)}
                                  />
                              </div>
                          </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-12 d-flex justify-content-center">
                          <div className="update">
                            <button type="submit" className="button" disabled={loader}>
                                { loader ? <i className="fa fa-spinner fa-spin mr-2"/> : '' } Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>

        </>
    )
}

export default TeamAddc

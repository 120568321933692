import React, {useState} from "react";
import fb from "../../images/facebook_new.png";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {saveFacebook} from "../../store/actions/socialAccount";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";

const FacebookConnect = ({checkAccount,editClient}) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    /** Facebook */
    const [details, setDetails] = useState({
        name: false,
        userId: false,
        accessToken: false,
        openPopup: false,
        buttonText: "Connect",
        email: false,
        pages: [],
    });

    const [dropValue, setDropValue] = useState("Select An Account");
    const [selection, setSelection] = useState({
        type: false,
        id: false,
        accessToken: false,
        name: false,
    });

    const [groups, setGroups] = useState([]);


    const closeConnectModel = () => {
        setDetails({ ...details, openPopup: false });
    };

    const handleChange = (e) => {
        const selectedValue = e.target.value.split("___CR___");
        setSelection({
            type: selectedValue[2],
            id: selectedValue[3],
            accessToken: selectedValue[1],
            name: selectedValue[0],
        });
        setDropValue(e.target.value);
    };

    const handleClick = async () => {
        setDetails({
            ...details,
            buttonText: "Connecting...",
        });
        const data = {
            user_id: editClient.id !== null ? editClient.id : auth.user.id,
            title: selection.name,
            token: selection.accessToken,
            fb_id: selection.id,
            category: selection.type,
            email: details.email,
        };

        await dispatch(saveFacebook(data));
        setDetails({
            ...details,
            openPopup: false,
        });
    };

    const responseFacebook = async (response) => {
        console.log(response);
        try {
            const res = await axios.get(
                `https://graph.facebook.com/me/accounts?type=page&access_token=${response.accessToken}`
            );
            console.log(res);

            setDetails({
                ...details,
                name: response.name,
                userId: response.userID,
                openPopup: true,
                buttonText: "Connect",
                accessToken: response.accessToken,
                pages: res.data.data,
            });
        } catch (err) {
            console.log(err);
        }

        /** Fetch FB Groups */
        try {
            const res = await axios.get(
                " https://graph.facebook.com/me/groups?access_token=" + response.accessToken
            );
            console.log(res);

            setGroups(res.data.data);
        } catch (err) {
            console.log(err);
        }

    }

    return(
        <>
            <FacebookLogin
                appId="780210132546000" //APP ID NOT CREATED YET
                fields="name,email"
                scope="pages_manage_posts,publish_to_groups,pages_read_user_content"
                callback={(e)=>responseFacebook(e)}
                render={renderProps => (
                    <a className={`${checkAccount === false ? 'hide-button' : '' }`} onClick={renderProps.onClick} href="#">
                        <img className={`${checkAccount === false ? 'client-img-opacity' : '' }`}  src={fb} alt="fb-img" />
                    </a>
                )}
            />

            <div className={`modal ${details.openPopup ? "fade show d-block" : ""}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title text-center">Select an account</h5>
                            <button type="button" className="close" onClick={(e)=>closeConnectModel(e)} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <select className="form-control" id="exampleFormControlSelect1" onChange={handleChange}
                                        value={dropValue}>
                                    <option value="Select an Account" >Select An Account</option>
                                    <optgroup label="Your Facebook Pages">
                                        {details.pages.map((page) => (
                                            <option
                                                key={page.id}
                                                value={
                                                    page.name +
                                                    "___CR___" +
                                                    page.access_token +
                                                    "___CR___page___CR___" +
                                                    page.id
                                                }
                                            >
                                                {page.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                    <optgroup label="Your Facebook Groups">
                                        {groups.map((group) => (
                                            <option
                                                key={group.id}
                                                value={
                                                    group.name +
                                                    "___CR___" +
                                                    details.accessToken +
                                                    "___CR___group___CR___" +
                                                    group.id
                                                }
                                            >
                                                {group.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-center ">
                            <button type="button" onClick={handleClick} disabled={dropValue === "Select An Account" ? true : false}>
                                {details.buttonText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FacebookConnect;
import React, {useEffect, useState} from "react";
import {baseURL} from "../../global/global";
import redditImage from "../../images/reddit_new.png";
import {useDispatch, useSelector} from "react-redux";
import randomstring from "randomstring"
import {checkNetworkStatus} from "../../store/actions/socialAccount";
import Alert from "../alert/alert";

const RedditConnect = ({checkAccount,editClient}) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [checkStatus, setCheckStatus] = useState(true);
    const [randomString, setRandomString] = useState(randomstring.generate());

    const connectReddit = (e) => {
        let url = `${baseURL}connect-reddit?token=${auth.token}&key=${randomString}&user_id=${editClient.id !== null ? editClient.id : auth.user.id}`;

        let myWindow =  window.open(url,'mywindow','width=500,height=600');

        let interval = setInterval(() => {
            if (checkStatus) {
                dispatch(checkNetworkStatus(randomString,setCheckStatus,interval,myWindow));
                console.log("waiting for the next call.");
            }

        }, 5000);


    }




    return (
      <>
          <Alert/>
          <a className={`${checkAccount === false ? 'hide-button' : '' }`} onClick={(e)=>connectReddit(e)} href="#">
              <img className={`${checkAccount === false ? 'client-img-opacity' : '' }`} src={redditImage} alt="reddit-image" />
          </a>
      </>
    )

}


export default RedditConnect;
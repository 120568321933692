import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {updateProjectName, updateRenderStatus} from "../../store/actions/videoAction";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../store/actions/alert";
import {useHistory} from "react-router-dom";

function CreateVideoHead() {

    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector(state => state.auth);

    const[loadSave, setLoadSave] = useState(false);
    const[loadRender, setLoadRender] = useState(false);
    const videoData = useSelector(state => state.videoReducer);
    const [projectName, setProjectName] = useState(videoData.name);

    const [videoStatus, setVideoStatus] = useState(videoData);

    const onInputName = (e) => {
        dispatch(updateProjectName(e.target.value));
    }

    const saveVideo = async (e) => {
        setLoadSave(true);
        //await dispatch(updateRenderStatus('save'));

        axios({
            method: 'POST',
            url : `${baseURL}save-video`,
            data: JSON.stringify({
            tiles: videoStatus.tiles,
            audio: videoStatus.audio,
            logo: videoStatus.logo,
            watermark: videoStatus.watermark,
            thumbnail: videoStatus.thumbnail,
            name: videoStatus.name,
            id: videoStatus.id,
            type: "save"
        }),
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res =>{

            if(res.data.status === true){
                dispatch(setAlert(res.data.message,'success'));
                history.push('/contents')
            }else{
                dispatch(setAlert(res.data.message,'danger'));
            }
            setLoadSave(false);

        }).catch(error=>{
            setLoadSave(false);
            console.log(error);
        })
    }

    const renderVideo = async (e) => {
        setLoadRender(true);
        // await setVideoStatus({...videoStatus,type: 'render'});

        axios({
            method: 'POST',
            url : `${baseURL}save-video`,
            data: JSON.stringify({
                tiles: videoStatus.tiles,
                audio: videoStatus.audio,
                logo: videoStatus.logo,
                watermark: videoStatus.watermark,
                thumbnail: videoStatus.thumbnail,
                name: videoStatus.name,
                id: videoStatus.id,
                type: "render"
            }),
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res =>{

            if(res.data.status === true){
                dispatch(setAlert(res.data.message,'success'));
                history.push('/contents')
            }else{
                dispatch(setAlert(res.data.message,'danger'));
            }
            setLoadRender(false);

        }).catch(error=>{
            setLoadRender(false);
            console.log(error);
        })
    }

    useEffect(()=>{
        setVideoStatus(videoData);
    },[videoData]);

    useEffect(()=>{
        setProjectName(videoData.name);
    },[videoData.name])

    return (
        <>
            <section className="sec-1">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-4 col-md-4 d-flex align-items-center">
                        <h5 className="m-0">Video Creator</h5>
                    </div>
                    {/* <div className="col-lg-4 mt-lg-0 mt-4">
                    <div className="form-group m-0 mt-1 inputbackgroundcolor ">
                        <input type="text" defaultValue={projectName} onChange={(e)=>onInputName(e)} className="form-control" placeholder="Enter Project Name"/>
                        </div>
                    </div> */}
                    <div className="col-lg-8  col-md-8  d-flex justify-content-lg-end d-flex justify-content-sm-center  mt-lg-0 mt-sm-4">
                 
                      
                        <div className="save-next d-flex  ">
                        <div className="form-group m-0 mt-1 inputbackgroundcolor ">
                        <input type="text" value={projectName} onChange={(e)=>onInputName(e)} className="form-control" placeholder="Enter Project Name"/>
                        </div>
                        <ul className="list-inline m-0 d-flex">
                            <li tabIndex="0" className="button" style={{cursor: "pointer"}}>
                                <a  onClick={(e)=>saveVideo(e)}>
                                    {loadSave === true ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Save
                                </a>
                            </li>
                            <li tabIndex="0" className="button" style={{cursor: "pointer"}}>
                                <a  onClick={(e)=>renderVideo(e)}>
                                    {loadRender === true ? <i className="fa fa-spinner fa-spin mr-2"/> : '' } Render
                                </a>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
             </section>

        </>
    )
}

export default CreateVideoHead

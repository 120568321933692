import React, {useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import {baseURL} from "../../global/global";
import {useDispatch, useSelector} from "react-redux";
import HoverVideoPlayer from "react-hover-video-player";
import { updateImageType} from "../../store/actions/videoAction";
import ReactPlayer from "react-player";


const ViralVideo = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const textStateData = useSelector(state => state.videoReducer);

    let selectedIndex = textStateData.tiles.findIndex(({is_selected}) => is_selected === "true");

    const [nextStatus, setNextStatus] = useState(false);
    const [youtubeData, setYoutubeData] = useState({data: []})
    const [searchData, setSearchData] = useState({
        keyword: "",
        page: 1,
        token: "",
        license: false
    });
    const [loader, setLoader] = useState(false);
    const [countErr, setCountErr] = useState(false);
    const [ytPopup, setYtPopup] = useState(false);
    const [ytUrl, setYtUrl] = useState('');

    const searchYoutubeSocial = async () => {
        setLoader(true);
        let options = {
            headers: {
                'Authorization': auth.token
            }
        }
        let data = {
            keyword: searchData.keyword,
            no_of_videos: 10,
            next_page_token: searchData.token,
            license: searchData.license
        }

        await axios.post(`${baseURL}search-youtube`, data, options).then(res => {
            if (res.data.status === true) {
                setCountErr(false);
                setSearchData({...searchData, token: res.data.next_page_token});

                setYoutubeData({...youtubeData, data: youtubeData.data.concat(res.data.data)});
            }else{
                setCountErr(true);
            }
            setLoader(false);
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        searchYoutubeSocial();
    }

    const onInputChange = (e) => {
        setSearchData({...searchData, keyword: e.target.value})

        if(e.target.name === "keyword"){
            setYoutubeData({data:[]})
        }
    }

    const selectImage = (url,type,video_url) => {
        dispatch(updateImageType(selectedIndex,url,video_url));
    }

    const onCheckLicence= (e) => {
        if(searchData.license === true){
            setSearchData({...searchData, license: false});
        }else{
            setSearchData({...searchData, license: true});
        }
    }

    const playYoutube = async (url) => {
        await setYtUrl(url);
        setYtPopup(true);
    }

    useEffect(()=>{
        return () => {
            setYoutubeData({...youtubeData, data: []});
            console.log('viral >>> unmount');
        }
    },[])

    return (
        <>
            <div className="library-box">
                <div className="row mt-3 mb-3">
                    <form method="post" onSubmit={(e) => onFormSubmit(e)} className="col-md-12 ml-3">
                        <div className="input-group">
                            <input
                                type="text"
                                id="keyword"
                                className="form-control"
                                placeholder="Viral Video Search"
                                name="keyword"
                                onChange={(e) => onInputChange(e)}
                                required
                            />
                            <span className="input-group-btn">
                                <button disabled={loader} className="btn btn-default" type="submit">
                                    <i className="fa fa-search" aria-hidden="true"/>
                                </button>
                            </span>
                        </div>
                        <div className="switch-box d-flex justify-content-center" style={{marginTop: "1rem"}}>
                            <div className="form-check form-switch">
                                <input className="form-check-input" name="send" type="checkbox" value="1" checked={searchData.license} onChange={(e)=>onCheckLicence(e)} />
                            </div>
                            <label style={{fontSize: ".875em" , marginTop:"1px"}}>Creative Common License </label>
                        </div>

                    </form>
                </div>


                <div id="scrollableDiv"
                     style={{overflow: "auto", height: "205px"}}
                >
                    <InfiniteScroll
                        dataLength={youtubeData.data.length} //This is important field to render the next data
                        next={(e) => searchYoutubeSocial()}
                        hasMore={true}
                        loader={
                            countErr ?
                                <h6 style={{width: "100%"}} className="m-0 text-center">we don't have more results</h6>:
                                loader ? <h6 style={{width: "100%"}} className="m-0 text-center"><i style={{fontSize: "40px", color: "#16d489"}} className="fa fa-spinner fa-spin mr-2"/></h6>: ''
                        }
                        scrollableTarget="scrollableDiv"
                    >
                        {
                            youtubeData.data.length > 0 ?
                                youtubeData.data.map((item, index) => {
                                    return (
                                        <div key={index} className="col-md-4 p-2"
                                             style={{overflow: 'hidden', cursor: "pointer", position: "relative"}}>
                                            <img style={{cursor: "pointer", zIndex: "9999"}}
                                                 className="imagesizes" src={item.thumbnail} width="100%" onClick={(e) => selectImage(item.thumbnail, 'video', 'https://www.youtube.com/watch?v='+item.id)} />
                                                 <span
                                                     style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "#16d489"}}
                                                     onClick={(e)=>playYoutube('https://www.youtube.com/watch?v='+item.id)}><i className="fa fa-play"></i></span>
                                        </div>
                                    )
                                })
                                : ''

                        }
                    </InfiniteScroll>
                </div>

            </div>

            <div className={`modal ${ytPopup ? "fade show d-block" : ""}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document" style={{maxWidth: "755px"}}>
                    <div className="modal-content" style={{padding: "15px 20px"}}>
                        <div className="modal-header d-flex justify-content-center">
                            <button type="button" className="close" onClick={(e)=>setYtPopup(false)} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ReactPlayer controls={true} playing={ytPopup} url={ytUrl} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default ViralVideo;
import React from 'react'

function bannerLinebar() {
    return (
        <>
          <section className="newbanner-line">
              <div className="row">
                   <div className="col-md-6 p-0">
                       <div className="bannerline">
                           </div> 
                   </div>
                   <div className="col-md-6 p-0">
                   <div className="bannerline-second">
                           </div>
                   </div>
              </div>
          </section>  
        </>
    )
}

export default bannerLinebar

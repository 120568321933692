import React, {useEffect, useState} from 'react'
import youtube from '../../images/youtube.png';
import twitter from '../../images/twitter.png';
import trush from '../../images/trush.png';
import {useDispatch, useSelector} from "react-redux";
import TimezoneSelect from "react-timezone-select";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import SweetAlert from "react-bootstrap-sweetalert";
import {deleteScheduler, fetchScheduleContent, updateSchedulerTime} from "../../store/actions/schedularAction";
import {Link} from "react-router-dom";
 import redditImage from "../../images/reddit_new.png";
import vimeoImage from "../../images/vimeo_new.png";
import facebookImage from "../../images/facebook_new.png";
import telegramImage from "../../images/telegram_new.png";
import twitterImage from "../../images/twitter_new.png";
import youtubeImage from "../../images/youtube_new.png";
import linkedInImage from "../../images/linkedin_new.png";
import mediumImage from "../../images/medium-logo.png";
import wordpressImage from "../../images/wordpress-logo.png"
import defaultImg from "../../images/default_img.png";
import defaulttxt from "../../images/default_txt.png";
import ReactTooltip from "react-tooltip";
import TimeKeeper from "react-timekeeper";

function ScheduleTable() {

  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const scheduleData = useSelector(state => state.scheduler);

  const [scheduleCount, setScheduleCount] = useState(scheduleData.schedule.length);

  const [loader, setLoader] = useState(false);
  const [schedules, setSchedules] = useState(scheduleData.schedule);
  const [showPopup,setShowPopup] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [inputCheck, setInputCheck] = useState(false);
  const [button, setButton] = useState('Delete');
  const [deleteID, setDeleteID] = useState({
    id: null
  })

  useEffect(()=>{
    setSchedules(scheduleData.schedule);

    return () => {
      setSchedules('');
    }

  },[scheduleData.schedule]);

  console.log(scheduleData.schedule);

  const [editSchedule, setEditSchedule] = useState({
    id: null,
    hours: null,
    minutes: null,
    timezone: null,
    schedule_date: null,
    re_purpose: 0
  });

  const [selectedTimezone,setSelectedTimezone] = useState({
    value: null,
    label: null
  });

  const [showTime ,setShowTime] = useState(false);
  const [time,setTime] = useState('12:00');

  const onEditTime = (e,schedule) => {
    e.preventDefault();
    setShowPopup(true);

    setEditSchedule({
      ...editSchedule,
      id: schedule.id,
      hours: schedule.hours,
      minutes: schedule.minutes,
      schedule_date: schedule.schedule_date,
      timezone: schedule.timezone,
      re_purpose: schedule.re_purpose,
      content_stream_id: null,
      social_accounts: null
    });

    setTime(`${schedule.hours}:${schedule.minutes < 9 ? '0'+schedule.minutes : schedule.minutes}`);
    setSelectedTimezone({...selectedTimezone,value: schedule.timezone, label: schedule.timezone})

    if(schedule.re_purpose === '1'){
      setInputCheck(true);
    }else{
      setInputCheck(false);
    }
  }

  const onInputChange = (e) => {
    setEditSchedule({...editSchedule,[e.target.name]: e.target.value});
  }

  const onTimeKeepChange = (e) => {
    let tmpTime = e.split(":");

    let hours = tmpTime[0] == '0' ? '24' : tmpTime[0]

    setEditSchedule({...editSchedule,hours: hours, minutes: tmpTime[1]});
  }

  const onCheckInput = (e) => {
    if(editSchedule.re_purpose === 0){
      setEditSchedule({...editSchedule,re_purpose: 1});
      setInputCheck(true);
    }else{
      setEditSchedule({...editSchedule,re_purpose: 0});
      setInputCheck(false);
    }
  }

  function convert(str) {
    let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const handleDateSelect = (e) =>{
    setEditSchedule({...editSchedule, schedule_date: convert(e)});
  }

  const handleDateChange = (e) =>{
  }

  const onFormSubmit = (e) => {
    e.preventDefault(e);
    setLoader(true);

    dispatch(updateSchedulerTime(editSchedule,setShowPopup,setLoader));
  }


  const onConfirm = () =>{
    setButton('Please wait...');

    dispatch(deleteScheduler(deleteID,setShowDelete,setButton));
  }

  const [q, setQ] = useState('');

  const getQ = (e) => {
    setQ(e.target.value)
  };

  const search = (users) => {
    return users.filter(
        user => user.content_stream.title.toLowerCase().indexOf(q.toLowerCase()) > -1
    );
  };

  const onButtonClick = (e,id) =>{
    e.preventDefault(e);
    setShowDelete(true);

    setDeleteID({...deleteID,id: id});
  }

  useEffect(()=>{
    setScheduleCount(scheduleData.schedule.length);

  },[scheduleData.schedule]);

  useEffect(()=>{
    setEditSchedule({...editSchedule,timezone: selectedTimezone.value});
  },[selectedTimezone]);

  useEffect(()=>{
    dispatch(fetchScheduleContent(auth.token));
  },[])

    return (
        <>
          <section className="sec-1">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-5 d-flex align-items-center">
                  <div className="sec-1-heading newline">
                    <ul className="list-inline m-0 d-flex">
                      <li className="ml-5"><a href="#">{scheduleCount} Schedule</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6  col-md-7 d-flex justify-content-end">
                  <div className="search-add d-flex align-items-center ">
                    <div className="search-container mr-lg-3">
                      <form>
                        <input type="text" placeholder="Search.." name="search"
                               onChange={(e)=>getQ(e)}/>
                        <button type="submit"><i className="fa fa-search" /></button>
                      </form>
                    </div>
                    <div className="add d-flex align-items-center">
                      <Link to="scheduler" className="button"><span><i className="fas fa-plus-circle mr-2" /></span>  Schedule</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
         <section className="schedule-sec">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="schedule-table table-hover table-responsive-lg">
                    <table className="table table-striped mt-3">
                      <thead className="theadbgcolor text-white">
                        <tr>
                          <th scope="col">CLIENT</th>
                          <th scope="col">STREAM</th>
                          <th scope="col">NETWORK</th>
                          <th scope="col">CONTENT POSTED</th>
                          <th scope="col">SCHEDULE</th>
                          <th scope="col">TIMEZONE</th>
                          <th scope="col">FREQUENCY</th>
                          <th scope="col">REPURPOSE</th>
                          <th scope="col">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        search(schedules).length > 0 ?
                           search(schedules).map((schedule,index)=>{
                             return(
                                 <tr className="table-light" key={index}>
                                   <th scope="row">
                                     <div className="d-flex align-items-center">
                                       <div className="user-name  ">
                                         <img src={schedule.client.image} alt className="img-fluid" />
                                         <h6  title={schedule.client.name}  className="mt-2 m-0">{schedule.client.name}</h6>
                                       </div>
                                     </div>
                                   </th>
                                   <td>
                                     <div className="align-items-center">
                                       <div className="youtube-name ">
                                         {
                                           schedule.content_stream.type === "image" ?
                                               schedule.content_stream.streams.length > 0 ?
                                               <img src={schedule.content_stream.streams[0].thumbnails} alt className="img-fluid" style={{width: "66px"}} />
                                               : <img src={defaultImg} alt className="img-fluid" style={{width: "66px"}} />

                                           : ''
                                         }
                                         {
                                           schedule.content_stream.type === "video" ?
                                               schedule.content_stream.streams.length > 0 ?
                                                   <img src={schedule.content_stream.streams[0].thumbnails} alt className="img-fluid" style={{width: "66px"}} />
                                                   : <img src={youtube} alt className="img-fluid" style={{width: "66px"}} />

                                               : ''
                                         }
                                         {
                                           schedule.content_stream.type === "text" ? <img src={defaulttxt} alt className="img-fluid" style={{width: "66px"}} /> : ''
                                         }
                                         <h6 title={schedule.content_stream.title} className="mt-2 m-0">{schedule.content_stream.title}</h6>
                                       </div>
                                     </div>
                                   </td>
                                   <td>
                                     <div className="d-flex align-items-center ">
                                       <div className="schedule-icon">
                                         <ul className="list-inline m-0 d-flex ">
                                           {(() => {
                                             if (schedule.accounts.includes('reddit')) {
                                               return (
                                                   <li><a className="mr-1">
                                                     <img src={redditImage} alt="fb-img" className="img-fluid" />
                                                   </a></li>
                                               )
                                             }
                                           })()}
                                           {(() => {
                                             if (schedule.accounts.includes('youtube')) {
                                               return (
                                                   <li><a className="mr-1">
                                                     <img src={youtubeImage} alt="fb-img" className="img-fluid" />
                                                   </a></li>
                                               )
                                             }
                                           })()}
                                           {(() => {
                                             if (schedule.accounts.includes('vimeo')) {
                                               return (
                                                   <li><a className="mr-1">
                                                     <img src={vimeoImage} alt="fb-img" className="img-fluid" />
                                                   </a></li>
                                               )
                                             }
                                           })()}
                                           {(() => {
                                             if (schedule.accounts.includes('facebook')) {
                                               return (
                                                   <li><a className="mr-1">
                                                     <img src={facebookImage} alt="fb-img" className="img-fluid" />
                                                   </a></li>
                                               )
                                             }
                                           })()}
                                           {(() => {
                                             if (schedule.accounts.includes('telegram')) {
                                               return (
                                                   <li><a className="mr-1">
                                                     <img src={telegramImage} alt="fb-img" className="img-fluid" />
                                                   </a></li>
                                               )
                                             }
                                           })()}
                                           {(() => {
                                             if (schedule.accounts.includes('linkedin')) {
                                               return (
                                                   <li><a className="mr-1">
                                                     <img src={linkedInImage} alt="fb-img" className="img-fluid" />
                                                   </a></li>
                                               )
                                             }
                                           })()}
                                           {(() => {
                                             if (schedule.accounts.includes('twitter')) {
                                               return (
                                                   <li><a className="mr-1">
                                                     <img src={twitterImage} alt="fb-img" className="img-fluid" />
                                                   </a></li>
                                               )
                                             }
                                           })()}
                                           {(() => {
                                             if (schedule.accounts.includes('medium')) {
                                               return (
                                                   <li><a className="mr-1">
                                                     <img src={mediumImage} alt="fb-img" className="img-fluid" />
                                                   </a></li>
                                               )
                                             }
                                           })()}
                                           {(() => {
                                             if (schedule.accounts.includes('wordpress')) {
                                               return (
                                                   <li><a className="mr-1">
                                                     <img src={wordpressImage} alt="fb-img" className="img-fluid" />
                                                   </a></li>
                                               )
                                             }
                                           })()}
                                         </ul>
                                       </div>
                                     </div>
                                   </td>
                                   <td>
                                     {schedule.posted_count} /{JSON.parse(schedule.streams).length}
                                   </td>
                                   <td>{schedule.schedule_date} {schedule.hours >= 9 ? schedule.hours : '0'+schedule.hours }:{schedule.minutes >= 9 ? schedule.minutes : '0'+schedule.minutes }    <a onClick={(e)=>onEditTime(e,schedule)} href="#"><i className="fas fa-edit"  data-for="mainTooltip" data-iscapture="true" data-tip="Reschedule my Post"></i></a>
                                   <ReactTooltip id="mainTooltip" place="right" multiline={true}/>
                                   </td>
                                   <td>{schedule.timezone}</td>
                                   <td style={{textTransform: "capitalize"}}>{schedule.type}</td>
                                   <td>{schedule.re_purpose === '1' ? 'Yes' : 'No'}</td>
                                   <td>
                                     <div className="schedule-tresh">
                                       <Link className="custom-eye" to={`schedule-detail/${schedule.id}`}><i className="fas fa-eye"></i></Link>
                                       <a onClick={(e)=>onButtonClick(e,schedule.id)} href="#"><img src={trush} alt /></a>
                                     </div>
                                   </td>
                                 </tr>
                             )
                           })
                        :   <tr align="center">
                              <td colSpan="9">You don't have any schedule to publish content. click 'Schedule' to schedule one</td>
                            </tr>
                      }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className={`modal ${showPopup ? "fade show d-block" : ""}`} tabIndex="-1" role="dialog" style={{background: "rgba(0,0,0,0.5)"}}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header d-flex justify-content-center">
                  <h5 className="modal-title text-center">Select Date & Time</h5>
                  <button type="button" className="close" onClick={(e)=>setShowPopup(false)} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body" style={{padding: "0px 30px 30px 30px"}}>
                  <form onSubmit={(e)=>onFormSubmit(e)} className="row" style={{marginTop: "1rem"}}>
                    <div className="col-md-12">
                        <label>Date</label><br/>
                        <DatePicker
                            selected={new Date(editSchedule.schedule_date)}
                            dateFormat="MM-dd-yyyy"
                            onSelect={(date) => handleDateSelect(date)} //when day is clicked
                            onChange={handleDateChange} //only when value has changed
                            minDate={new Date()}
                            placeholderText="Select a date"
                        />
                    </div>
                    {/*<div className="col-md-6">*/}
                    {/*  <label>Hours</label>*/}
                    {/*  <select className="form-control" name="hours" onChange={(e)=>onInputChange(e)}>*/}
                    {/*    <option selected={editSchedule.hours == "1"} value="01">01</option>*/}
                    {/*    <option selected={editSchedule.hours == "2"} value="02">02</option>*/}
                    {/*    <option selected={editSchedule.hours == "3"} value="03">03</option>*/}
                    {/*    <option selected={editSchedule.hours == "4"} value="04">04</option>*/}
                    {/*    <option selected={editSchedule.hours == "5"} value="05">05</option>*/}
                    {/*    <option selected={editSchedule.hours == "6"} value="06">06</option>*/}
                    {/*    <option selected={editSchedule.hours == "7"} value="07">07</option>*/}
                    {/*    <option selected={editSchedule.hours == "8"} value="08">08</option>*/}
                    {/*    <option selected={editSchedule.hours == "9"} value="09">09</option>*/}
                    {/*    <option selected={editSchedule.hours == "10"} value="10">10</option>*/}
                    {/*    <option selected={editSchedule.hours == "11"} value="11">11</option>*/}
                    {/*    <option selected={editSchedule.hours == "12"} value="12">12</option>*/}
                    {/*    <option selected={editSchedule.hours == "13"} value="13">13</option>*/}
                    {/*    <option selected={editSchedule.hours == "14"} value="14">14</option>*/}
                    {/*    <option selected={editSchedule.hours == "15"} value="15">15</option>*/}
                    {/*    <option selected={editSchedule.hours == "16"} value="16">16</option>*/}
                    {/*    <option selected={editSchedule.hours == "17"} value="17">17</option>*/}
                    {/*    <option selected={editSchedule.hours == "18"} value="18">18</option>*/}
                    {/*    <option selected={editSchedule.hours == "19"} value="19">19</option>*/}
                    {/*    <option selected={editSchedule.hours == "20"} value="20">20</option>*/}
                    {/*    <option selected={editSchedule.hours == "21"} value="21">21</option>*/}
                    {/*    <option selected={editSchedule.hours == "22"} value="22">22</option>*/}
                    {/*    <option selected={editSchedule.hours == "23"} value="23">23</option>*/}
                    {/*    <option selected={editSchedule.hours == "24"} value="24">24</option>*/}
                    {/*  </select>*/}
                    {/*</div>*/}
                    {/*<div className="col-md-6">*/}
                    {/*  <label>Minutes</label>*/}
                    {/*  <select className="form-control" name="minutes" onChange={(e)=>onInputChange(e)}>*/}
                    {/*    <option selected={editSchedule.minutes == "0"} value="00">00</option>*/}
                    {/*    <option selected={editSchedule.minutes == "5"} value="05">05</option>*/}
                    {/*    <option selected={editSchedule.minutes == "10"} value="10">10</option>*/}
                    {/*    <option selected={editSchedule.minutes == "15"} value="15">15</option>*/}
                    {/*    <option selected={editSchedule.minutes == "20"} value="20">20</option>*/}
                    {/*    <option selected={editSchedule.minutes == "25"} value="25">25</option>*/}
                    {/*    <option selected={editSchedule.minutes == "30"} value="30">30</option>*/}
                    {/*    <option selected={editSchedule.minutes == "35"} value="35">35</option>*/}
                    {/*    <option selected={editSchedule.minutes == "40"} value="40">40</option>*/}
                    {/*    <option selected={editSchedule.minutes == "45"} value="45">45</option>*/}
                    {/*    <option selected={editSchedule.minutes == "50"} value="50">50</option>*/}
                    {/*    <option selected={editSchedule.minutes == "55"} value="55">55</option>*/}
                    {/*  </select>*/}
                    {/*</div>*/}
                    <div className="col-md-6">
                      <label>Pick a Time</label>
                      {showTime && (
                          <TimeKeeper
                              time={time}
                              onChange={(newTime) => onTimeKeepChange(newTime.formatted24)}
                              hour24Mode
                              forceCoarseMinutes
                              doneButton={(newTime) => (
                                  <div
                                      style={{
                                        textAlign: 'center',
                                        padding: '10px 0',
                                        backgroundColor: "#16d489",
                                        color: "#ffffff",
                                        cursor: "pointer"
                                      }}
                                      onClick={() => setShowTime(false)}
                                  >
                                    Close
                                  </div>
                              )}
                          />
                      )}
                      {!showTime && <button className="btn-submit" onClick={() => setShowTime(true)}><i className="fa fa-clock-o" style={{fontSize: "20px"}}></i></button>}
                    </div>
                    <div className="col-md-6">
                      <div className="select-wrapper">
                        <label>Timezone</label>
                        <TimezoneSelect
                            value={selectedTimezone}
                            onChange={setSelectedTimezone}
                        />
                      </div>
                    </div>
                    <div className="col-md-12" style={{marginTop: "1rem"}}>
                      <div className="alert alert-primary" role="alert">
                        Hit "repurpose" to have AgencyReel repost the content without having to reschedule again. AgencyReel will select your best content from that particular content stream to post each time without scheduling again.
                      </div>
                    </div>
                    <div className="col-md-12">
                      <input type="checkbox" name="re_purpose" value={editSchedule.re_purpose}
                             onChange={(e)=>onCheckInput(e)}
                             checked={inputCheck}
                      />
                      <label style={{margin: "0px 10px"}}>
                        Repurpose
                        <span
                            style={{fontSize: "12px", color: "#3e99f3"}}
                            className="fas fa-question-circle ml-1"
                            data-for="mainTooltip"
                            data-iscapture="true"
                            data-tip="Checking 'Repurpose' will continue repurposing the content in a Content Stream even when it runs out of new content. If you scheduled daily posting and the content stream runs out of new content after 4 days then 'Repurpose' will reuse the existing content and repeat publish them."></span>
                      </label>
                      <ReactTooltip
                          id="mainTooltip"
                          place="right"
                          multiline={true}
                      />
                    </div>

                    <div className="col-md-12" style={{marginTop: "1.5rem"}}>
                      <button style={{margin: "auto"}} type="submit" className="btn-submit" disabled={loader}>
                        {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : '' } Reschedule
                      </button>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>

          <SweetAlert
              warning
              showCancel
              confirmBtnText={button}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="light"
              title="Are you sure?"
              onConfirm={(e)=>onConfirm(e)}
              onCancel={(e)=>setShowDelete(false)}
              focusCancelBtn
              show={showDelete}
          >
            Do you want to delete this Schedule
          </SweetAlert>
   
        </>
    )
}

export default ScheduleTable

import React from 'react'
import GDPRContent from './SubComponent/GDPRContent'
import GDPRemil from './SubComponent/GDPRemil'
import Navbar from './SubComponent/Navbar'
import {Helmet} from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";

function GDPR() {
    return (
        <>
            <Helmet>
                <title>AgencyReel | Privacy </title>
            </Helmet>
           <div className="wrapper-box">
            <Navbar/>
               <BannerLinebar/>
            <GDPRemil/>
            <GDPRContent/>
            </div>
        </>
    )
}

export default GDPR;

const initialState = {
    images: [],
    videos: []
}

export default function (state = initialState, action){

    switch (action.type) {
        case 'ADD_IMAGES_CONTENT':
            return {
                ...state,
                images: action.payload
            }

        case 'ADD_VIDEOS_CONTENT':
            return {
                ...state,
                videos: action.payload
            }

        default:
        {
            return state;
        }
    }

}
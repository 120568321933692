import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

function ScheduleHead() {

    const scheduler = useSelector(state => state.scheduler);

    const [scheduleCount, setScheduleCount] = useState(scheduler.schedule.length);

    useEffect(()=>{
        setScheduleCount(scheduler.schedule.length);

    },[scheduler.schedule]);

    return (
        <>
           <section className="sec-1">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-5 d-flex align-items-center">
                  <div className="sec-1-heading newline">
                    <ul className="list-inline m-0 d-flex">
                      <li><a href="#">Schedule Content</a></li>
                      <li className="ml-5"><a href="#">{scheduleCount} Schedule</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6  col-md-7 d-flex justify-content-lg-end justify-content-sm-start">
                  <div className="search-add d-flex align-items-center ">
                    <div className="add d-flex align-items-center">
                      <Link to="schedule-content" className="button"><span></span>View Schedule</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
 
        </>
    )
}

export default ScheduleHead

import React, {useState} from "react";
import queryString from "query-string";
import {useDispatch} from "react-redux";
import {saveLinkedIn} from "../../store/actions/socialAccount";
import {useHistory} from "react-router-dom";
import Alert from "../alert/alert";

const SocialConnectCode = ({location}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [appCode, setAppCode]= useState({
        code: queryString.parse(location.search).code
    })

    if(appCode.code !== ''){
        // window.close();

        dispatch(saveLinkedIn(appCode,history));
    }


    return(
        <>
            <Alert/>
        </>
    )

}

export default SocialConnectCode;